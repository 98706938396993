// 

// imports
import { select } from "d3-selection";

export default function() {
  function exports(selection, getModalId) {
    selection.each(function(_data) {
      const modal = select(this)
          .selectAll("div.modal")
          .data(_data);

      modal.exit().remove();
      const modal_e = modal
          .enter()
          .append("div")
          .attr("tabindex", "-1")
          .attr("role", "dialog")
          .attr("aria-hidden", "true")
          .attr("data-backdrop", "static")
          .attr("data-keyboard", false)
          .classed("modal fade", true);

      const modal_content_e = modal_e
          .append("div")
          .classed("modal-dialog modal-lg", true)
          .attr("role", "document")
          .append("div")
          .classed("modal-content", true);

      const modal_header_e = modal_content_e
          .append("div")
          .classed("modal-header py-2 bg-light", true);
      modal_header_e
          .append("div")
          .classed("col", true)
          .append("h5")
          .classed("modal-title", true);

      const form_group_enter = modal_header_e
          .append("form")
          .classed("grouping-selector", true)
          .append("div")
          .classed("form-group row mb-0", true);

      form_group_enter.append("div").classed("grouping-label", true);

      form_group_enter.append("div").classed("col-auto grouping-select", true);

      const modal_body_e = modal_content_e
          .append("div")
          .classed("modal-body p-1", true);

      const container_body_e = modal_body_e
          .append("div")
          .classed("container-fluid", true);

      const card_body = container_body_e
          .append("div")
          .classed("card border-0", true)
          .append("div")
          .classed("card-body p-2", true);

      card_body
          .append("div")
          .classed("alert-message mt-2", true)
          .append("div")
          .classed("alert alert-info mb-1", true)
          .attr("role", "alert")
          .text(function(d) {
              const [min_biobs, max_biobs] = d.biobs_range;
              let message;
              if (max_biobs === Infinity) {
                  message = ["Select a cart containing ", min_biobs, " or more ", d.biob_name , "(s)"];
              } else {
                  message = ["Select a cart containing between ", min_biobs, " and ", max_biobs, " ", d.biob_name, "(s)"];
              }
              return message.join("") + ".";
          });

      //Cart list
      const list_group = card_body.append("div");

      const cart_list = list_group
          .selectAll("div")
          .data(function(d) {
            let genome_carts = d.carts;
            if(genome_carts instanceof Array) {
              genome_carts.forEach(function(item) {
                item.disabled = ( item.content.length >= d.biobs_range[0] && item.content.length <= d.biobs_range[1] ) ? false : true;
              })
              return genome_carts
            } else
              return {};
          })
          .enter()
          .append("div")
          .classed("form-check",true);

      cart_list
          .append("input")
          .classed("form-check-input",true)
          .attr("type", "radio")
          .attr("id", function (d) {
            return d.name;
          })
          .attr("name", "cart_list")
          .attr("value", function (d) {
            return d.harmonized_name;
          })
          .property("disabled", function (d) {
               return d.disabled
           })
          .style("margin-right", "7px");
      cart_list
          .append("label")
          .classed("form-check-label",true)
          .attr("for", function (d) {
            return d.name;
          })
          .text(function (d) {
            return d.harmonized_name + " (" + d.content.length + ")";
          });

      const modal_footer_e = modal_content_e
          .append("div")
          .style("justify-content", "normal")
          .style("display", "grid")
          .classed("modal-footer py-1", true);

      const flex_div = modal_footer_e.append("div").classed("d-flex", true);

      flex_div
          .append("div")
          .classed("mr-auto p-2", true)
          .append("button")
          .attr("type", "button")
          .classed("btn btn-dark", true)
          .attr("data-dismiss", "modal")
          .attr("aria-label", "Close")
          .on("click", function(d) {
            d.eventHandlers.cancel();
          })
          .text("Cancel");

      const save_btn = flex_div
          .append("div")
          .classed("p-2", true)
          .append("button")
          .attr("type", "button")
          .attr("data-dismiss", "modal")
          .attr("aria-label", "Close")
          .classed("btn btn-primary", true)
          .on("click", function(d) {
              const radios =  document.getElementsByName("cart_list");
              for (let radio of radios) {
                  if (radio.checked) {
                      d.eventHandlers.reset();
                      d.eventHandlers.setCart(d.biob_type, radio.value);
                  }
              }
              d.eventHandlers.save();
          })
          .text("Load");


      // MODAL UPDATE
      const modal_u = modal_e.merge(modal);

      // On

      const modal_header = modal_u
          .select(".modal-header")
          .attr("id", (d) => getModalId(d) + "-header");
      modal_header
          .select("h5")
          .text("Genome Carts");

      // Grouping label:
      const grouping_label = modal_header
          .select(".grouping-label")
          .selectAll("label")
          .data(
              d => (d.biobs_grouping_btn_modal ? [d.biobs_grouping_btn_modal] : [])
          );

      grouping_label.exit().remove();

      const modal_content_u = modal_u
          .attr("aria-labelledby", getModalTitleId)
          .attr("id", (d) => getModalId(d))
          .select(".modal-title")
          .attr("id", (d) => getModalId(d) + "-title");

      // update save button
      modal_u
          .select(".save-selection")
          .property("disabled", function(d) {
            return !d.valid_selection;
          });

      const modal_body_u = modal_u.select(".modal-body .container-fluid");

      modal_body_u.select(".add-remove-btn").attr("id", getAddRemoveBtnId);
      // spinner id
      modal_u
          .select(".spinner-container")
          .select("div")
          .attr("id", function(d) {
            return getModalId(d) + "-spinner-container";
          });

      // update buttons
      modal_u
          .select(".add-genome")
          .property(
              "disabled",
              d =>
                  d.not_in_selection_biobs.data.options.filter(it => it.selected)
                      .length === 0
          )
          .on("click", d => {
            d.eventHandlers.add(
                d.not_in_selection_biobs.data.options
                    .filter(opt => opt.selected)
                    .map(opt => opt.value)
            );
          });


      const modal_footer_u = modal_u.select(".modal-footer");

      modal_footer_u
          .select(".clear-selection")
          .select("button")
          .property("disabled", function(d) {
            return d.in_selection_biobs.data.options.length === 0;
          });

      modal_footer_u
          .select("button.save-selection")
          .attr("id", d => getModalId(d) + "_save_btn")
          .select(".num-genome-saved")
          .text(function(d) {
            return d.in_selection_biobs.total_biobs;
          });
    });
  }

  function getModalTitleId(d) {
    return d.id + "-title";
  }
  function getAddRemoveBtnId(d) {
    return d.id + "-add-remove-btn";
  }

  return exports;
}

