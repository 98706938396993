// 
//import
import {multipleNewSelector} from "./multiple-selector";
import {singleNewSelector} from "./single-selector";
import {taxo_rank} from "../../reducer/taxonomic-rank";
import {getRankValue, getStringifyOrganismById} from "../../selector";
import {getOrganismSuggestionEngine} from "../../selector/suggestion-engine";

// Code start here

const biob_type = "organism";

/**
 * Construct a single or multiple biob selector
 * @example
 * import * as micLib from "microscope";
 * 
 * const multipleOrganismSelector = micLib.organismSelector(true, []);
 * multipleOrganismSelector(
 *    biob_list_id,
 *    "Genomes",
 *    [3, 500],
 *    6,
 *    400,
 *    document.getElementById("genoclust-multiple-biob-selection")
 * );
 * 
 * @export
 * @param {boolean} multiple
 * @param {number[]} [preselected_biob_ids=[]]
 * @param {string} [html_class=""]
 * @param {boolean} [hide_rapid_selector=false]
 * @returns {function} single or multiple selector function/object
 */
export function organismSelector(
  multiple,
  preselected_biob_ids = [],
  html_class = "",
  hide_rapid_selector = false
) {
  return multiple
    ? multipleNewSelector(
        biob_type,
        getStringifyOrganismById,
        getOrganismSuggestionEngine,
        getRankValue,
        taxo_rank,
        preselected_biob_ids,
        hide_rapid_selector
      )
    : singleNewSelector(
        biob_type,
        getOrganismSuggestionEngine,
        html_class
      );
}
