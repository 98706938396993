// 
import {FILTER_ADD} from "../action/filter";

const initialState = {};

export default function filter(
  state = initialState,
  action
) {
  switch (action.type) {
    case FILTER_ADD:
      if (action.filter_subtype) {
        const id = action.filter_id;
        return {
          ...state,
          [id]: {
            type: action.filter_type,
            subtype: action.filter_subtype,
            matching_type: action.matching_type,
            value: action.value,
            id,
            name: action.filter_name
          }
        };
      } else {
        const id = action.filter_id;
        return {
          ...state,
          [id]: {
            type: action.filter_type,
            value: action.value,
            id: id,
            matching_type: action.matching_type,
            name: action.filter_name
          }
        };
      }

    default:
      return state;
  }
}
