//
//import
import {of} from "rxjs";
import {catchError, distinctUntilChanged, filter, mergeMap,} from "rxjs/operators";
import store, {store$} from "../../store";
// Action
import {fetchNjSpeciesTree, fetchNjSpeciesTreeByOcid,} from "../../action/nj-species-tree";
import {getMicFetchSpeciesTree, getNjSpeciesTreeCache,} from "../../selector";
import {removeCacheData} from "../../action/cache";

import {getNjSpeciesDataById} from "../../d3-data/nj-species-tree";

import speciesTreeResults from "../../d3-component/species-tree-results";
//d3
import "d3-transition";
import {select} from "d3-selection";

let unsubscribe = null;
const species_tree_results_component = speciesTreeResults();


/**
 * updateSpecieTree
 *
 * @export
 * @param {string} id
 * @param {string} title
 * @param {number[]} oids
 */
export function updateSpecieTree(id, title, oids) {
  const param = {
    id,
    title,
    param: oids,
  };
  store.dispatch(fetchNjSpeciesTree(param));
}

export const updateSpeciesTreeByOcid = function(
  id,
  title,
  ocid
) {
  const param = {
    id,
    title,
    param: [ocid].map(ocid => parseInt(ocid)),
  };
  store.dispatch(fetchNjSpeciesTreeByOcid(param));
};

export function speciesTree(
  species_tree_id,
  title,
  html_container
) {
  // Attach Biob list update listener.
  const species_tree_container = select("#genoclust-results-species-tree");

  // To subscribe only once. A better solution might exist :)
  if (!unsubscribe) {
    unsubscribe = getDistinctSpeciesTreeById$(species_tree_id).subscribe(
      function(state) {
        const width = species_tree_container.node().clientWidth;
        const species_tree_cache = getNjSpeciesTreeCache(state);
        const species_tree_datas = Object.keys(species_tree_cache)
          .map(key => {
            const cache = species_tree_cache[key];
            const species_tree_data = {
              selected: key === species_tree_id,
              data: getNjSpeciesDataById(state, key),
            };
            species_tree_data.eventHandlers = {
              removeSpeciesTree: function(speciesTreeId) {
                store.dispatch(
                  removeCacheData([speciesTreeId.toString()], "nj_species_tree")
                );
              },
            };
            return species_tree_data;
          })
          .sort(
            (
              a,
              b
            ) => {
              if (a.data.id === "species_tree") {
                return -1;
              }
              if (b.data.id === "species_tree") {
                return 1;
              }
              return parseInt(a.data.id) - parseInt(b.data.id);
            }
          );
        species_tree_container
          .datum(species_tree_datas)
          .call(species_tree_results_component, width, "genoclust-tabs");

        species_tree_results_component.dispatcher.on(
          "show-cluster-tree",
          function(data, oid) {
            const micgc_id = data.id;
            //create a new species_tree.
            if (data.count >= 3) {
              speciesTree(micgc_id.toString(), data.name, html_container);
              updateSpeciesTreeByOcid(micgc_id, data.name, micgc_id);
            }
          }
        );
      }
    );
  }

  isFetchingTree(false).subscribe(mic_fetch_sp_tree => {
    species_tree_container.select("div.sp-tree-fetching").remove();
  });

  isFetchingTree(true).subscribe(() => {
    let alert_div = species_tree_container.select("div.sp-tree-fetching");
    if (alert_div.empty()) {
      alert_div = species_tree_container
        .insert("div", ":first-child")
        .classed("row justify-content-md-center", true)
        .append("div")
        .classed("alert alert-secondary sp-tree-fetching", true);

      alert_div
        .append("i")
        .classed("fa fa-spinner fa-spin fa-3x fa-fw align-middle", true);
      alert_div.append("span");
    }
    const alert_span = alert_div
      .select("span")
      .text("Is computing species tree...");
  });

  function getDistinctSpeciesTreeById$(species_tree_id) {
    return store$.pipe(
      distinctUntilChanged(),
      distinctUntilChanged((prev_state, state) => {
        const cache = getNjSpeciesTreeCache(state);
        const prev_cache = getNjSpeciesTreeCache(prev_state);
        return cache === prev_cache;
      }),
      catchError(error => of(`No species tree: ${error}`))
    );
  }

  function isFetchingTree(value) {
    return store$.pipe(
      mergeMap(state => {
        const treeMicFetchStates = getMicFetchSpeciesTree(state);
        return of(treeMicFetchStates).pipe(
          distinctUntilChanged(),
          mergeMap(function(fetchState) {
            return of(Object.values(fetchState));
          }),
          filter(fetchStates => {
            // return true if at least on is fetching
            // return false if all are not fetching.
            const oneIsFetching = fetchStates.reduce((acc, curr) => {
              if (curr.isFetching || acc) {
                acc = true;
                return acc;
              } else {
                return acc;
              }
            }, false);
            return value === oneIsFetching;
          })
        );
      })
    );
  }
}
