import {event, select, window} from "d3-selection";
import "d3-transition";
import {nest} from "d3-collection";

export default function() {
  function exports(selection) {
    selection.each(function(_data) {
      const self = this;
      const getNbItem = function() {
        var height = window(self).innerHeight;
        if (height > 1800) return 25;
        if (height > 1300) return 18;
        if (height >= 1020) return 12;
        if (height >= 900) return 9;
        return 7;
      };
      const container = select(self);

      const form_group = container.selectAll("div.selector").data(_data);

      // ENTER form

      const form_group_enter = form_group
        .enter()
        .append("div")
        .classed("selector form-group", true);

      form_group_enter.append("select");

      // EXIT form
      form_group.exit().remove();

      // UPDATE form
      const form_group_update = form_group_enter.merge(form_group);

      const select_u = form_group_update
        .select("select")
        .attr("class", function(d) {
          if (d.select_class) {
            return d.select_class;
          } else if (d.multiple || !d.size) {
            return "custom-select form-control mr-sm-2";
          } else {
            return "form-control mr-sm-2";
          }
        });

      const optgroups = select_u
        .property("multiple", d => (d.multiple === true ? "multiple" : null))
        .attr("size", d => (d.multiple === true ? getNbItem() : d.size))
        .attr("id", d => d.id)
        .on("change", function(d) {
          // Put all the option data to false.
          d.options.forEach(opt => (opt.selected = false));
          // Get all the html option in a d3 selector.
          const options = select(this).selectAll("option");
          // The data associated
          const selected_data = options
            .filter(function(d) {
              // don't use arrow function since it
              // needs the "this"
              return this.selected;
            })
            .data();
          // Make a set from the selected options.
          const selected_set = new Set(selected_data.map(d => d.value));
          // set the option data to true when selected.
          const new_options = d.options.map(opt =>
            selected_set.has(opt.value)
              ? { ...opt, selected: true }
              : {
                  ...opt,
                  selected: false
                }
          );
          d.options = new_options;
          d.eventHandlers.selectOption(new_options);
        })
        .selectAll("optgroup")
        .data(function(d) {
          const optgroups_data = nest()
            .key(function(d) {
              return d.group;
            })
            .sortKeys(function(a, b) {
              var unset = "Unset";
              if (a === unset && b !== unset) return 1;
              if (a !== unset && b === unset) return -1;
              return a.localeCompare(b);
            })
            // Sort by name (see #6591).
            .sortValues(function (a, b) {
                console.log(a, b);
                return a.name.localeCompare(b.name);
            })
            .entries(
              d.options.map(function(opt, i) {
                return opt;
              })
            );

          return optgroups_data.map(opt => ({
            ...opt,
            eventHandlers: d.eventHandlers
          }));
        });

      // ENTER optgroups
      const optgroups_enter = optgroups.enter().append("optgroup");

      // EXIT optgroups
      optgroups.exit().remove();

      // UPDATE optgroups
      const optgroups_update = optgroups_enter.merge(optgroups);

      optgroups_update
        .on("dblclick", function(d) {
          // Make a set from the selected options.
          const biobSelectedSet = new Set(d.values.map(biob => biob.value));
          const selectHtml = select(this.parentNode);
          // Get all the html option in a d3 selector.
          const optionsHtml = selectHtml.selectAll("option").each(function(d) {
            const isSelected = biobSelectedSet.has(this.value);
            d.selected = isSelected;
            this.selected = isSelected;
          });
          // Get the updated data
          const selectData = selectHtml.data()[0];
          d.eventHandlers.selectOption(selectData.options);
        })
        .attr("label", function(d) {
          return d.key === "null" ? "" : d.key + " [" + d.values.length + "]";
        });

      const options = optgroups_update.selectAll("option").data(function(d) {
        return d.values;
      });

      const options_e = options.enter().append("option");

      options.exit().remove();

      const options_up = options_e
        .merge(options)
        .attr("value", function(d) {
          return d.value;
        })
        .on("dblclick", d => event.stopPropagation())
        .on("click", d => event.stopPropagation())
        .attr("title", d => d.name)
        .property("selected", d => (d.selected ? true : false))
        .text(d => d.name);

      // The label

      const label = form_group_update.selectAll("label").data(function(d) {
        if (d.label) {
          return [
            {
              label: d.label,
              id: d.id
            }
          ];
        } else {
          return [];
        }
      });

      const label_e = label
        .enter()
        .insert("label", ":first-child")
        .attr("for", function(d) {
          return d.id;
        })
        .classed("mx-sm-3", true);

      label_e.exit().remove();

      const label_u = label_e.merge(label).text(function(d) {
        return d.label;
      });
    });
  }
  return exports;
}
