// 
// import
import {catchError, mergeMap, withLatestFrom,} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {filterNotInCache, groupBy} from "./cache";
import {cacheData} from "../action/cache";
// Epics
import {cacheEpic, fetchData, isFetchTypeEpic} from "./mic-fetch";
import {micgcName} from "../reducer/reducer-declaration";
import {failureAction} from "../action/mic-fetch";
import {paramEpicMap} from "./index";
// Code start here

/**
 *
 *
 * @export
 * @param {ActionsObservable<MicgcFetchAction>} action$
 * @param {Observable<MicState>} state$
 * @returns {*}
 */
export function fetchMicgcEpic(
  action$,
  state$
) {
  const paramFuncName = paramEpicMap.get(micgcName)
  return action$.pipe(
    isFetchTypeEpic(micgcName),
    mergeMap(action => {
      const all_param = action.param;
      return of(action).pipe(
        mergeMap(action => {
          return of(action).pipe(
            withLatestFrom(state$),
            filterNotInCache(paramFuncName.getCacheFunc, paramFuncName.hasAllFunc, action.id),
            mergeMap(param =>
              of(param).pipe(
                fetchData(paramFuncName.requestType),
                mergeMap(raw_data =>
                  of(raw_data.map(paramFuncName.toObjectFunc))
                ),
                groupBy(micgcName),
                // Don't check because can have missing micgc for an oid (contaminating...)
                //checkResponse(param),
                mergeMap(orga =>
                  of(cacheData(action.id, all_param, orga, micgcName))
                )
              )
            )
          );
        }),
        catchError(error => of(failureAction(micgcName, error)))
      );
    })
  );
}

/**
 *
 *
 * @export
 * @param {ActionsObservable<CacheAction<MicRequestValueParam, String, any>>} action$
 * @param {Observable<MicState>} state$
 * @returns {*}
 */
export function cacheMicgcEpic(
  action$,
  state$
) {
  return cacheEpic(
    action$,
    state$,
    micgcName
  );
}
