import { dispatch } from "d3-dispatch";
import { select } from "d3-selection";
import "d3-transition";

export default function() {
  exports.dispatcher = dispatch("statechange");
  function exports(selection) {
    selection.each(function(_data) {
      var container = select(this);

      var btn_group = container.selectAll("div.dropdown").data(function(d) {
        return d;
      });

      // EXIT form
      btn_group.exit().remove();

      // ENTER form
      var btn_group_enter = btn_group
        .enter()
        .append("div")
        .classed("dropdown", true);

      btn_group_enter
        .append("button")
        .attr("id", function(d) {
          return d.id;
        })
        .classed("btn btn-dark dropdown-toggle", true)
        .attr("type", "button")
        .attr("data-toggle", "dropdown")
        .attr("data-placement", "bottom")
        .attr("data-flip", "false")
        .attr("aria-haspopup", "true")
        .attr("aria-expanded", "false");

      btn_group_enter
        .append("div")
        .attr("aria-labelledby", function(d) {
          return d.id;
        })
        .classed("dropdown-menu", "true");

      // UPDATE
      var btn_group_update = btn_group_enter.merge(btn_group);

      btn_group_update.select("button").html(function(d) {
        return d.label;
      });

      var dropdown_item = btn_group_update
        .select("div.dropdown-menu")
        .selectAll("a.dropdown-item")
        .data(function(d) {
          return d.options;
        });

      // EXIT
      dropdown_item.exit().remove();

      // ENTER
      var dropdown_item_enter = dropdown_item
        .enter()
        .append("a")
        .attr("href", "#")
        .on("click", function(d) {
          d.click_cb();
        })
        .classed("dropdown-item", true);

      // UPDATE
      var dropdown_item_update = dropdown_item_enter
        .merge(dropdown_item)
        .text(function(d) {
          return d.label;
        });
    });
  }
  return exports;
}
