//
//import
import {notExistBiobListException, notExistListBiobException,} from "../utils/exception";

import {getFetchObj} from "./mic-fetch";

const in_selection_status_key = "in_selection";

// Code start here

/******************************
 * STATE IS FetchBiobLists TYPE
 *******************************/

/**
 *
 *
 * @export
 * @param {FetchBiobLists} state
 * @returns
 */
export function getBiobLists(state) {
  return state.biob_lists;
}

/**
 *
 *
 * @export
 * @param {FetchBiobLists} state
 * @returns
 */
export function getMicFetchBiobList(state) {
  return state.mic_fetch_state;
}

export function getMicFetchById(state, id) {
  const mic_fetchs = getMicFetchBiobList(state);
  try {
    return getFetchObj(mic_fetchs, id);
  } catch (error) {
    console.log("No Fetch object", error);
  }
}


/**
 * 
 *
 * @export
 * @param {FetchBiobLists} state
 * @param {string} id Identfier of the biob list
 * @return {boolean}
 */
export function isBiobListFetching(state, id) {
  const mic_fetch = getMicFetchById(state, id);
  if (mic_fetch) {
    return mic_fetch.isFetching;
  } else {
    return false;
  }
}

export function getBiobListsHistory(state) {
  return state.biob_lists_history;
}

/**************************
 * STATE IS BiobLists TYPE
 **************************/
/**
 * Getter for a biob list.
 * @ignore
 * @export
 * @param {BiobLists} state BiobLists state
 * @param {string} id biob list identifier
 * @returns {BiobList}
 */
export function getBiobListById(state, id) {
  // Get the list
  if (state.hasOwnProperty(id)) {
    return state[id];
  } else {
    throw new notExistBiobListException(id);
  }
}

/**
 *
 * @ignore
 * @export
 * @param {BiobLists} state
 * @param {string} biob_list_id
 * @returns
 */
export function hasBiobListById(state, biob_list_id) {
  try {
    getBiobListById(state, biob_list_id);
  } catch (error) {
    return false;
  }
  return true;
}


export function getBiobsById(
  state,
  biob_list_id
) {
  return listBiobsToArray(getListBiobs(getBiobListById(state, biob_list_id)));
}

/**
 *
 *
 * @export
 * @param {BiobLists} state
 * @param {string} biob_list_id
 * @returns {ListBiob[]}
 */
export function getInSelectionBiobs(
  state,
  biob_list_id
) {
  return getBiobsById(state, biob_list_id).filter(
    (biob) => biob[in_selection_status_key]
  );
}
export function getBiobByBiobId(
  state,
  biob_list_id,
  biob_id
) {
  return getBiobById(
    getListBiobs(getBiobListById(state, biob_list_id)),
    biob_id
  );
}

export function getInSelectionBiobListIds(
  state,
  biob_list_id
) {
  return getInSelectionBiobs(state, biob_list_id).reduce(function(
    oids,
    biob
  ) {
    return [...oids, biob.id];
  },
  []);
}

export function getNotInSelectionBiobs(
  state,
  biob_list_id
) {
  return getBiobsById(state, biob_list_id).filter(
    (biob) => !biob[in_selection_status_key]
  );
}

export function getNotInSelectionBiobListIds(
  state,
  biob_list_id
) {
  return getNotInSelectionBiobs(state, biob_list_id).reduce(function(
    oids,
    biob
  ) {
    return [...oids, biob.id];
  },
  []);
}

export function getInSelectionFilterIds(
  state,
  biob_list_id
) {
  return getBiobStatusValueByType(
    getBiobListById(state, biob_list_id),
    in_selection_status_key,
    true
  ).filters;
}

export function getNotInSelectionFilterIds(
  state,
  biob_list_id
) {
  return getBiobStatusValueByType(
    getBiobListById(state, biob_list_id),
    in_selection_status_key,
    false
  ).filters;
  // getBiobStatus(getBiobListById(state, biob_list_id))[
  //   in_selection_status_key
  // ].false.filters;
}

export function getBiobListTypeById(
  state,
  id
) {
  return getBiobListType(getBiobListById(state, id));
}

export function getInSelectionGroupingById(
  state,
  id
) {
  return getGrouping(state, id, in_selection_status_key, true);
}

export function getNotInSelectionGroupingById(
  state,
  id
) {
  return getGrouping(state, id, in_selection_status_key, false);
}

export function getIsSelectedAttrById(
  state,
  id_list,
  id_biob
) {
  const biob = getBiobByBiobId(state, id_list, id_biob);
  return isSelectedAttr(biob);
}

export function isSelectedAttr(biob) {
  return biob.selected_attr;
}

export function getGrouping(
  state,
  id,
  status,
  bool
) {
  try {
    return getBiobStatus(getBiobListById(state, id))[status][bool.toString()]
      .grouping;
  } catch (error) {
    console.log(error);
    throw new notExistBiobListException(id);
  }
  // if (state.hasOwnProperty(id)) {
  //   return state[id].biob_status[status][bool.toString()].grouping;
  // } else {
  //   throw new notExistBiobListException(id);
  // }
}

export function getGroupingByIdByStatus(
  state,
  biob,
  biob_list_id,
  status,
  bool
) {
  return getGrouping(state, biob_list_id, status, bool);
}

export function getBiobListUpToDateById(
  state,
  biob_list_id
) {
  return getBiobListById(state, biob_list_id).up_to_date;
}

export function getBiobName(
  state,
  biob_list_id
) {
  try {
    return getBiobListById(state, biob_list_id).biob_name;
  } catch (error) {
    console.log(error);
  }
}

export function getBiobListRange(
  state,
  biob_list_id
) {
  try {
    return getBiobListById(state, biob_list_id).selected_biobs_range;
  } catch (error) {
    console.log(error);
  }
}

export function getRangeStatusById(state, biob_list_id) {
  try {
    return getBiobListById(state, biob_list_id).range_status;
  } catch (error) {
    console.log(error);
  }
}

export function getFilterCriteriaById(
  state,
  biob_list_id,
  biob_status,
  biob_status_value
) {
  return getBiobStatus(getBiobListById(state, biob_list_id))[biob_status][
    biob_status_value
  ].filter_criteria;
}

/************************
 * STATE IS BiobList TYPE
 *************************/
/**
 *
 *
 * @export
 * @param {BiobList} state
 * @returns {ListBiobs}
 */
export function getListBiobs(state) {
  return state.biobs;
}
export function getBiobStatus(state) {
  return state.biob_status;
}

export function getBiobStatusByType(state, type) {
  return getBiobStatus(state)[type];
}

export function getBiobStatusValueByType(
  state,
  type,
  bool
) {
  return getBiobStatusByType(state, type)[bool.toString()];
}

export function getBiobListType(state) {
  return state.type;
}
export function getGroupingByBiob(
  biob_list,
  biob,
  status_type
) {
  const status_value = biob[status_type];
  return getBiobStatus(biob_list)[status_type][status_value.toString()]
    .grouping;
}

/*************************
 * STATE IS ListBiobs TYPE
 *************************/

/**
 *
 *
 * @export
 * @param {ListBiobs} list_biobs
 * @returns {ListBiob[]}
 */
export function listBiobsToArray(list_biobs) {
  return Object.keys(list_biobs).map(k => list_biobs[k]);
}
export function getInSelectionBiobsByListBiobs(
  list_biobs
) {
  return listBiobsToArray(list_biobs).filter(
    biob => biob[in_selection_status_key]
  );
}

export function getNotInSelectionBiobsByListBiobs(
  list_biobs
) {
  return listBiobsToArray(list_biobs).filter(
    biob => !biob[in_selection_status_key]
  );
}
export function getBiobById(biobs, biob_id) {
  if (biobs.hasOwnProperty(biob_id)) {
    return biobs[biob_id];
  } else {
    throw new notExistListBiobException(biob_id, biob_id);
  }
}
