import "d3-transition";
import {format} from "d3-format";
import {hierarchy} from "d3-hierarchy";
import {event, select} from "d3-selection";


import {scaleLinear} from "d3-scale";
import Phylogram from "../../Data/Hierarchy/Phylogram";
import PhyloNode from "./Node/CircleCrossSymbol";
import NodeLabel from "./Node/SpeciesClusterLabel";
import SelectionUtils from "../Utils";
//import {event} from "d3-event";

// const transition_obj = transition();
// console.log(transition_obj);
export default function Tree() {
  /*********************
   * Private variables *
   *********************/
  var margin = {
    left: 10,
    right: 0,
    top: 0
  };
  var duration = 500;
  var f = format(".2e");
  var fLong = format(".5e");
  var phylo_node_component = PhyloNode();
  var node_label_component = NodeLabel();
  var tree_layout = Phylogram();
  var selection_utils = SelectionUtils();

  /*******************************
   * Function that draw the tree *
   *******************************/
  function exports(
    _selection,
    width,
    height,
    user_phylo_node_component,
    user_node_label_component,
    user_tree_layout
  ) {
    _selection.each(function(data) {
      if (user_phylo_node_component) {
        phylo_node_component = user_phylo_node_component;
      }
      if (user_node_label_component) {
        node_label_component = user_node_label_component;
      }

      if (user_tree_layout) {
        tree_layout = user_tree_layout;
      }

      var self = this;
      var i = 0;
      var defaultCircleRadius = 4;
      var lineHeight = 19;
      var root = hierarchy(data || {});
      root.x0 = height / 2;
      root.y0 = 0;
      draw(root);

      function draw(source) {
        var leaves = root.leaves();
        var tree_height = leaves.length * lineHeight + 200;
        var ratioMaxDepth = 4;

        //console.log(transition_obj);

        var svg = select(self)
          .attr("width", width)
          .attr("height", tree_height);
        var maxLabelWidth = node_label_component.getWidth(leaves);
        var tree_width = width - margin.left - maxLabelWidth - 20;

        tree_layout.size([tree_height - 100, tree_width]);
        tree_layout(root);
        var nodes = data ? root.descendants() : [];
        var maxDepth = nodes.reduce(function(acc, curr) {
          return curr.depth > acc ? curr.depth : acc;
        }, 0);
        var translateToSourcePos = d =>
          "translate(" + source.y0 + "," + source.x0 + ")";

        var translateToCurrentSourcePos = d =>
          "translate(" + source.y + "," + source.x + ")";

        var scaleLineData = [maxDepth / ratioMaxDepth];
        var x = scaleLinear()
          .domain([0, maxDepth])
          .range([0, tree_width]);

        addYposition(root);
        var background = selection_utils
          .getOrCreateSelection(svg, "rect", "background")
          .attr("width", width)
          .attr("height", tree_height)
          .style("fill", "none")
          .style("pointer-events", "all");
        // .call(
        //   zoom()
        //     .scaleExtent([1 / 3, 4])
        //     .on("zoom", zoomed)
        // )
        // .on("wheel.zoom", null);

        var phylotree_grp = selection_utils.getOrCreateSelection(
          svg,
          "g",
          "phylotree"
        );
        var container = selection_utils
          .getOrCreateSelection(phylotree_grp, "g", "tree")
          .attr("transform", "translate(" + margin.left + ",40)");

        if (nodes.length < 1) {
          nodes = [];
        }

        if (nodes.length <= 1 || tree_layout.name !== "phylogram") {
          scaleLineData = [];
        }

        var nodeSelection = container
          .selectAll("g.node")
          //.data(nodes);
          .data(nodes, d => d.id || (d.id = ++i));

        var nodeEnter = nodeSelection
          .enter()
          .append("g")
          .classed("node", true)
          .on("mouseover", function(d) {
            var gnode = select(this);
            phylo_node_component.toggleHighlight(gnode);
            node_label_component.toggleHighlight(gnode);
            container
              .selectAll("g.link")
              .select("path")
              .filter(dl => d.id === dl.id)
              .style("stroke-width", "4px");
          })
          .on("mouseout", function(d) {
            var gnode = select(this);
            phylo_node_component.toggleNormal(gnode);
            node_label_component.toggleNormal(gnode);
            container
              .selectAll("g.link")
              .select("path")
              .filter(dl => d.id === dl.id)
              .style("stroke-width", null);
          })
          .attr("transform", translateToSourcePos);

        var nodeUpdate = nodeSelection.merge(nodeEnter);

        nodeUpdate.call(node_label_component, "node-label");

        nodeUpdate
          .call(phylo_node_component, "node-symbol")
          .on("click", function(d) {
            collapse_or_expand(d);
            draw(d);
          });
        //var bar = selection();

        var foo = nodeUpdate.attr("class", function(d) {
          return "node" + (d.children ? " node--internal" : " node--leaf");
        });
        foo
          .transition()
          .duration(duration)
          .attr("transform", d => "translate(" + d.y + "," + d.x + ")");

        var nodeExit = nodeSelection.exit();

        nodeExit
          .transition()
          .duration(duration)
          .attr("transform", translateToCurrentSourcePos)
          .remove();

        var linkSelection = container.selectAll("g.link")
        // .data(nodes.slice(1));
        .data(nodes.slice(1), d => d.id || (d.id = ++i));

        var linkEnter = linkSelection
          .enter()
          .insert("g", "g.node")
          .classed("link", true);

        linkEnter
          .append("path")
          .style("fill", "none")
          .style("stroke", "black")
          .on("mouseover", function(d) {
            select(this).style("stroke-width", 4);
          })
          .on("mouseout", function(d) {
            select(this).style("stroke-width", "");
          })
          .attr("d", function(d) {
            var o = { x: source.x0, y: source.y0 };
            return diagonal(o, o);
          });

        linkEnter
          .append("text")
          .classed("branch-length", true)
          .attr("transform", translateToSourcePos);

        linkEnter.append("title").classed("branch-length-title", true);

        const linkUpdate = linkSelection.merge(linkEnter);

        linkUpdate
          .select("path")
          .transition()
          .duration(duration)
          .attr("d", d => diagonal(d, d.parent));

        linkUpdate
          .select("text.branch-length")
          .transition()
          .duration(duration)
          .attr("transform", function(d) {
            var xText = d.parent.y + ((d.y - d.parent.y) / 2 - 30);
            return "translate(" + xText + "," + (d.x - 5) + ")";
          })
          .text(function(d) {
            if (d.y - d.parent.y <= 50) {
              // if (x(d.data.length) < 6 ) {
              return "";
            } else {
              return f(d.data.length);
            }
          });

        linkUpdate.select("title").text(d => fLong(d.data.length));

        const linkExit = linkSelection.exit();
        
        linkExit
          .select("path")
          .transition()
          .duration(duration)
          .attr("d", function(d) {
            var o = { x: source.x, y: source.y };
            return diagonal(o, o);
          });

        linkExit
          .select("text")
          .transition()
          .duration(duration)
          .attr("transform", translateToCurrentSourcePos);

        linkExit
          .transition()
          .duration(duration)
          .remove();

        nodes.forEach(function(d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });

        const branchScaleLine = phylotree_grp
          .selectAll("g.scale-line")
          .data(scaleLineData);

        const branchScaleLineEnter = branchScaleLine
          .enter()
          .insert("g", ":first-child")
          .attr("transform", "translate(" + margin.left + ",20)")
          .classed("scale-line", true);

        branchScaleLineEnter.append("text");

        branchScaleLineEnter
          .append("line")
          .style("stroke", "black")
          .classed("scale", true);

        branchScaleLineEnter.append("line").classed("left", true);

        branchScaleLineEnter.append("line").classed("right", true);

        var branchScaleLineUpdate = branchScaleLine.merge(branchScaleLineEnter);

        branchScaleLineUpdate
          .select("line.scale")
          .attr("x1", 0)
          .attr("y1", 0)
          .attr("x2", scaleWidth)
          .attr("y2", 0);

        branchScaleLineUpdate
          .select("line.left")
          .attr("x1", 0)
          .attr("y1", -5)
          .attr("x2", 0)
          .attr("y2", 5);

        branchScaleLineUpdate
          .select("line.right")
          .attr("x1", scaleWidth)
          .attr("y1", -5)
          .attr("x2", scaleWidth)
          .attr("y2", 5);

        branchScaleLineUpdate
          .select("text")
          .attr(
            "transform",
            d => "translate(" + (scaleWidth(d) / 2 - 20) + ", -3)"
          )
          .text(formatScaleDepth);

        branchScaleLine.exit().remove();

        // Functions
        function zoomed() {
          phylotree_grp.attr("transform", event.transform);
        }
        // function scaleDepth (d) {
        //     return d/ratioMaxDepth;
        // };
        function formatScaleDepth(d) {
          return f(d);
        }
        function scaleWidth(d) {
          return x(d);
        }
        function formatScaleWidth(d) {
          return f(scaleWidth(d));
        }
      }
    });
  }

  /********************
   * Private function *
   ********************/
  function collapse(d) {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(collapse);
      d.children = null;
    }
  }
  function addTreeHeight(node) {
    if (node.children == undefined) {
      node.left = 0;
      node.right = 0;
      return 1;
    } else {
      node.children[0].isRight = true;
      node.right = addTreeHeight(node.children[0]);
      node.children[1].isRight = false;
      node.left = addTreeHeight(node.children[1]);
      return node.left + node.right + 1;
    }
  }
  // necessary for transitions
  function addYposition(node) {
    if (node.children == undefined) {
      return node.x;
    } else {
      var tot = node.children.reduce(
        (prev, cur) => prev + addYposition(cur),
        0
      );
      node.x = tot / node.children.length;
      return node.x;
    }
  }
  // Toggle children on click.
  function diagonal(d, p) {
    return "M" + d.y + " " + d.x + " H" + p.y + " V" + p.x;
  }
  function collapse_or_expand(d) {
    if (d.data.children && d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
  }

  function constant(x) {
    return function() {
      return x;
    };
  }

  /********************
   * Public functions *
   ********************/

  exports.margin = function(_) {
    return arguments.length ? ((margin = _), exports) : margin;
  };

  return exports;
}
