// 
//import
import {applyMiddleware, compose, createStore} from "redux";
import {createEpicMiddleware} from "redux-observable";
import {default as rootReducer} from "../reducer";
import rootEpic from "../epic";

import {Observable} from "rxjs";

// code start here

// #8056: Use Redux Dev Tools if we are in dev mode (and if it is installed).
// Technically, we choose a function to compose middelwares: if in dev mode or if the extension is not installed,
// we use the standard compose function, else we use the __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ function.
const composeEnhancers = process.env.NODE_ENV === "production" ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(epicMiddleware))
);
epicMiddleware.run(rootEpic);


/**
 * Function that transform a store to an observable store.
 *
 * @param {Store} storeObserv
 * @returns {Observable<MicState>}
 */
function toObservable$(storeObserv) {
  return new Observable((observer) => {
    // emit the current state as first value:
    observer.next(storeObserv.getState());

    // let's return the function that will be called
    // when the Observable is unsubscribed
    return storeObserv.subscribe(() => {
      // emit on every new state changes
      const state = storeObserv.getState();
      observer.next(state);
    });
  });
}

export const store$ = toObservable$(store);
export default store;
