//
//import
import {failureAction, fetchAction, successAction} from "./mic-fetch";
import {name, name_by_ocid} from "../reducer/nj-species-tree";
// code start here

// Actions
export function fetchNjSpeciesTree(
  param
) {
  return fetchAction(name, param);
}

export function fetchNjSpeciesTreeSuccess(
  param,
  data
) {
  return successAction(name, param, data);
}

export function fetchNjSpeciesTreeFailure(
  error
) {
  return failureAction(name, error);
}

export function fetchNjSpeciesTreeByOcid(
  param
) {
  return fetchAction(name_by_ocid, param);
}

export function fetchNjSpeciesTreeByOcidSuccess(
  param,
  data
) {
  return successAction(name_by_ocid, param, data);
}
