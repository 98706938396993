// 
import {Observable} from "rxjs";
import {cacheEpic, fetchEpic} from "./mic-fetch";
import {
    speciesMetadataName,
    strainMetadataName,
    favoriteGenomesName,
    refseqSequenceName,
    pkgdbSequenceName,
    refseqOrganismName,
    pkgdbOrganismName,
    pkgdbMyOrganismName,
    publicGenomeName,
    micgcByOcidName,
    genomeCartsName,
    sequenceCartsName
} from "../reducer/reducer-declaration";


export function fetchSpeciesMetadataEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        speciesMetadataName
    );
}

export function cacheSpeciesMetadataEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        speciesMetadataName
    );
}

export function fetchStrainMetadataEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        strainMetadataName
    );
}

export function cacheStrainMetadataEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        strainMetadataName,
    );
}
export function fetchPkgdbMyOrganismEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        pkgdbMyOrganismName,
    );
}

export function cachePkgdbMyOrganismEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        pkgdbMyOrganismName
    );
}

export function fetchPkgdbOrganismEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        pkgdbOrganismName,
    );
}

export function cachePkgdbOrganismEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        pkgdbOrganismName
    );
}

export function fetchRefseqOrganismEpic(action$, state$) {
    return fetchEpic(
        action$,
        state$,
        refseqOrganismName,
    );
}

export function cacheRefseqOrganism(action$, state$) {
    return cacheEpic(
        action$,
        state$,
        refseqOrganismName
    );
}

export function fetchFavoriteGenomesEpic(action$, state$) {
    return fetchEpic(
        action$,
        state$,
        favoriteGenomesName
    );
}

export function cacheFavoriteGenomes(action$, state$) {
    return cacheEpic(
        action$,
        state$,
        favoriteGenomesName
    );
}


export const fetchPkgdbSequenceEpic = function(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        pkgdbSequenceName
    );
};

export const cachePkgdbSequenceEpic = function(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        pkgdbSequenceName
    );
};

export function fetchRefseqSequenceEpic(action$, state$) {
    return fetchEpic(
        action$,
        state$,
        refseqSequenceName
    );
}

export function cacheRefseqSequence(action$, state$) {
    return cacheEpic(
        action$,
        state$,
        refseqSequenceName
    );
}

export function fetchPublicGenomeEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        publicGenomeName
    )
}

export function cachePublicGenomeEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        publicGenomeName
    );
}

export function fetchMicgcByOcidEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        micgcByOcidName
    );
}

export function cacheMicgcByOcidEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        micgcByOcidName
    );
}

export function fetchGenomeCartsEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        genomeCartsName
    );
}

export function cacheGenomeCartsEpic(
    action$,
    state$
) {
    return cacheEpic(
        action$,
        state$,
        genomeCartsName
    );
}

export function fetchSequenceCartsEpic(
    action$,
    state$
) {
    return fetchEpic(
        action$,
        state$,
        sequenceCartsName
    );
}

export function cacheSequenceCartsEpic(
    action$,
    state$
)
{
    return cacheEpic(
        action$,
        state$,
        sequenceCartsName
    );
}