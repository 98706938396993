//

// import
import {catchError, mergeMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
// Epics
import {fetchData, isFetchTypeEpic} from "./mic-fetch";
import {failureAction, successAction} from "../action/mic-fetch";
import {paramEpicMap} from "./index";
import {distanceName} from "../reducer/reducer-declaration";

// Code start here

export const fetchDistanceEpic = (
  action$,
  state$
) => {
  const paramFuncName = paramEpicMap.get(distanceName)
  return action$.pipe(
    isFetchTypeEpic(distanceName),
    mergeMap(action => {
      const all_param = action.param;
      return of(all_param).pipe(
        mergeMap(param =>
          of(param).pipe(
            fetchData(paramFuncName.requestType),
            mergeMap(raw_data => of(raw_data.map(paramFuncName.toObjectFunc))),
            mergeMap(dist => of(successAction(distanceName, param, dist)))
          )
        )
      );
    }),
    catchError(error => of(failureAction(distanceName, error)))
  );
}
