// 


// import
import store, {store$} from "../../store";
import biobListMultipleSelector from "../../d3-component/biob-list-multiple-selector";
// Selector
import {
  getBiobListByIdFactory,
  getBiobLists,
  getFilterIdsMemoizedSelectorFactory,
  getFiltredBiobsById,
} from "../../selector";

import {getMultipleSelectorData} from "../../d3-data/biob-list";

import * as fromBiobList from "../../selector/biob-list";
// d3
import {select} from "d3-selection";
// import { dispatch } from "d3-dispatch";
// Import reselect.
// Note that selectors here refers to redux selectors,
// not to biob lists selectors.
import {createSelector} from "reselect";
import {getBiobList$, isFetching$, isfinishFetching$, updateAlertMessage,} from "./";

import {createBiobList, removeBiobFilters, setSelectedBiobRange,} from "../../action/biob-list";

/**
 *
 *
 * @public
 * @param {BiobListType} list_type
 * @param {*} toBiobString
 * @param {*} biobsToSuggestionEngine
 * @param {*} getGroup
 * @param {?(MicRank[])} ranks
 * @param {number[]} preselected_biob_ids
 * @param {boolean} hide_rapid_selector
 * @return {MultipleSelector(biob_list_id: string, title: string, select_biobs_range: [number, number]): void} Function that construct the selector
 */
export function multipleNewSelector(
  list_type,
  toBiobString,
  biobsToSuggestionEngine,
  getGroup,
  ranks,
  preselected_biob_ids,
  hide_rapid_selector
) {

  /**
   * ## multipleSelector
   * Function/Object that construct a selector
   * @export
   * @param {string} biob_list_id
   * @param {string} title
   * @param {[number, number]} selected_biobs_range
   * @param {number} width
   * @param {number} height
   * @param {*} html_container
   */
  function multipleSelector(
    biob_list_id,
    title,
    selected_biobs_range,
    width,
    height,
    html_container
  ) {
    const status_type = "in_selection";
    // Construct closure for the draw function
    const component = biobListMultipleSelector(hide_rapid_selector);
    const root_selection = select(html_container).select("#" + biob_list_id);
    const selector_container = root_selection.empty()
      ? select(html_container)
          .append("div")
          .classed("mt-3 col-" + width, true)
          .attr("id", biob_list_id)
      : root_selection.classed("mt-3 col-" + width, true);

    /*********************/
    /* MEMOIZED SELECTOR */
    /*********************/
    // Create memoized selectors in order to reduce
    // heavy computation (cf. reselect lib).
    // Need to define the memoized selector here since they must be defined
    // per selector.
    const getBiobListMemoizedSelector = getBiobListByIdFactory(
      biob_list_id
    );
    const getListBiobsMemoizedSelector = createSelector(
      [getBiobListMemoizedSelector],
      (biob_list) => fromBiobList.getListBiobs(biob_list)
    );

    /* NOT IN SELECTION */
    // get the filter ids
    const getNotInSelectionFilterIdsMemoizedSelector = getFilterIdsMemoizedSelectorFactory(
      biob_list_id,
      status_type,
      false,
      getBiobListMemoizedSelector
    );

    const getNotInSelectionBiobsMemoizedSelector = createSelector(
      [getListBiobsMemoizedSelector],
      list_biobs => fromBiobList.getNotInSelectionBiobsByListBiobs(list_biobs)
    );
    // Get the suggestion engine not in selection with not filtered
    const getNotInSelectionSuggestionEngine = createSelector(
      [getListBiobsMemoizedSelector],
      (list_biobs) =>
        biobsToSuggestionEngine(
          store.getState(),
          fromBiobList.getNotInSelectionBiobsByListBiobs(list_biobs),
          false,
          biob_list_id,
          true
        )
    );

    // get the biobs after the filters.
    const getNotInSelectionFilteredBiobsWithFilter = createSelector(
      [
        getNotInSelectionSuggestionEngine,
        getNotInSelectionBiobsMemoizedSelector,
        getNotInSelectionFilterIdsMemoizedSelector,
      ],
      (suggestion_engine, biobs, filter_ids) =>
        getFiltredBiobsById(
          store.getState(),
          suggestion_engine,
          biob_list_id,
          biobs,
          filter_ids
        )
    );

    const getNotInSelectionFilteredBiobs = createSelector(
      [
        getNotInSelectionFilteredBiobsWithFilter,
        getNotInSelectionFilterIdsMemoizedSelector,
        getNotInSelectionBiobsMemoizedSelector,
      ],
      (filtered_biobs_join_with_filter, filter_ids, biobs) => {
        return filtered_biobs_join_with_filter[
          filtered_biobs_join_with_filter.length - 1
        ].biobs;
      }
    );

    const getNotInSelectionFilteredSuggestionEngine = createSelector(
      [
        getNotInSelectionFilteredBiobs,
        getNotInSelectionSuggestionEngine,
        getNotInSelectionFilterIdsMemoizedSelector,
      ],
      (biobs, suggestion_engine, filter_ids) => {
        return filter_ids.length === 0
          ? suggestion_engine
          : biobsToSuggestionEngine(
              store.getState(),
              biobs,
              false,
              biob_list_id,
              true
            );
      }
    );

    // IN SELECTION
    // get the filter ids
    const getInSelectionFilterIdsMemoizedSelector = getFilterIdsMemoizedSelectorFactory(
      biob_list_id,
      status_type,
      true,
      getBiobListMemoizedSelector
    );

    const getInSelectionBiobsMemoizedSelector = createSelector(
      [getListBiobsMemoizedSelector],
      list_biobs => fromBiobList.getInSelectionBiobsByListBiobs(list_biobs)
    );
    const getInSelectionSuggestionEngine = createSelector(
      [getListBiobsMemoizedSelector],
      (list_biobs) => {
        return biobsToSuggestionEngine(
          store.getState(),
          fromBiobList.getInSelectionBiobsByListBiobs(list_biobs),
          true,
          biob_list_id,
          true
        );
      }
    );
    // get the biobs after the filters.
    const getInSelectionFilteredBiobsWithFilter = createSelector(
      [
        getInSelectionSuggestionEngine,
        getInSelectionBiobsMemoizedSelector,
        getInSelectionFilterIdsMemoizedSelector,
      ],
      (suggestion_engine, biobs, filter_ids) =>
        getFiltredBiobsById(
          store.getState(),
          suggestion_engine,
          biob_list_id,
          biobs,
          filter_ids
        )
    );
    const getInSelectionFilteredBiobs = createSelector(
      [getInSelectionFilteredBiobsWithFilter],
      filtered_biobs_join_with_filter => {
        return filtered_biobs_join_with_filter[
          filtered_biobs_join_with_filter.length - 1
        ].biobs;
      }
    );
    // Get the suggestion engine in selection with not filtered
    const getInSelectionFilteredSuggestionEngine = createSelector(
      [
        getInSelectionFilteredBiobs,
        getInSelectionSuggestionEngine,
        getInSelectionFilterIdsMemoizedSelector,
      ],
      (biobs, suggestion_engine, filter_ids) => {
        return filter_ids.length === 0
          ? suggestion_engine
          : biobsToSuggestionEngine(
              store.getState(),
              biobs,
              true,
              biob_list_id,
              true
            );
      }
    );

    /**
     * Function that draw  the multiple selector depending on the current state
     *
     * @param {MicState} state
     * @returns
     */
    function draw(state) {
      const not_in_selection_suggestion = getNotInSelectionFilteredSuggestionEngine(
        state
      );
      // const not_in_selection_suggestion = getNotInSelectionSuggestionEngine(
      //   state
      // );
      const not_in_selection_biob_with_filter = getNotInSelectionFilteredBiobsWithFilter(
        state
      );
      const not_in_selection_filtered_biobs = getNotInSelectionFilteredBiobs(
        state
      );

      // In Selection
      const in_selection_suggestion = getInSelectionFilteredSuggestionEngine(
        state
      );
      const in_selection_biob_with_filter = getInSelectionFilteredBiobsWithFilter(
        state
      );
      const in_selection_filtered_biobs = getInSelectionFilteredBiobs(state);

      // // check if there is empty filter.
      // const emptyFilters = not_in_selection_biob_with_filter.filter(
      //   filter => filter.size <= 0
      // );

      if (removeEmptyFilters(in_selection_biob_with_filter, true)) {
        return;
      }
      if (removeEmptyFilters(not_in_selection_biob_with_filter, false)) {
        return;
      }

      // Construct the data to display
      const data = getMultipleSelectorData(
        state,
        biob_list_id,
        status_type,
        title,
        toBiobString,
        not_in_selection_suggestion,
        not_in_selection_biob_with_filter,
        not_in_selection_filtered_biobs,
        in_selection_suggestion,
        in_selection_biob_with_filter,
        in_selection_filtered_biobs,
        ranks,
        getGroup
      );

      selector_container.datum([data]).call(component, width, height);

      function removeEmptyFilters(biobsWithFilter, statusKey) {
        // check if there is empty filter.
        const emptyFilters = biobsWithFilter.filter(
          filter => filter.filter_id !== "none" && filter.size <= 0
        );
        if (emptyFilters.length > 0) {
          store.dispatch(
            removeBiobFilters(
              biob_list_id,
              status_type,
              statusKey,
              emptyFilters.map(filter => filter.filter_id)
            )
          );
          return true;
        } else {
          return false;
        }
      }
    }

    // function exported
    /** @param {function} getBiobList$ Observable to new biobList */
    multipleSelector.getBiobList$ = getBiobList$;
    multipleSelector.isFetching$ = isFetching$;
    multipleSelector.isFinishFetching$ = isfinishFetching$;
    multipleSelector.setRange = function(range) {
      store.dispatch(setSelectedBiobRange(biob_list_id, range));
    };
    getBiobList$(biob_list_id).subscribe(function(state) {
      const biobLists = getBiobLists(state);
      console.log("STATE CHANGE : ", state);
      draw(state);
    });

    // display status message
    isFetching$(store$, biob_list_id).subscribe(function(data) {
      select(html_container)
        .select("#" + biob_list_id)
        .style("display", "none");
      updateAlertMessage(data, html_container);
    });

    isfinishFetching$(biob_list_id).subscribe(function(data) {
      select(html_container)
        .select("#" + biob_list_id)
        .style("display", null);
      select(html_container)
        .select("div.biob-list-fetching")
        .remove();
    });

    // Create the biob list in the store
    store.dispatch(
      createBiobList(
        list_type,
        biob_list_id,
        selected_biobs_range,
        true,
        preselected_biob_ids
      )
    );
  }
  return multipleSelector;
}
