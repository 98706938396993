//
import {notExistGenomeCartException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

// code start here
export function getGenomeCartsCache(state) {
  return state.cache;
}

export function getGenomeCartsList(state) {
  return state.carts_list;
}

export function getBiobFromGenomeCartById(state, cartName) {
  const item = state.carts_list.find(item => item.name === cartName);
  return item.content;
}

export function getListGenomeCarts(state) {
  const cache = getGenomeCartsCache(state);
  return Object.values(cache).reduce(function(
    genomeCartsList,
    genomeCarts
  ) {
    const oid_str = genomeCarts.O_id.toString();
    genomeCartsList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return genomeCartsList;
  },
  {});
}

export function getGenomeCartsById(state, id) {
  const cache = getGenomeCartsCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    // throw new notExistGenomeCartException(id);
  }
}

export function getStringifyGenomeCartsById(
  state,
  id,
  org_string
) {
  return stringifyGenomeCarts(state, id) + "; " + org_string;
}

export function stringifyGenomeCarts(genomeCarts, org_string) {
  const group = getGenomeCartsById(genomeCarts, org_string);
  return Object.values(group)
      .sort((a, b) => a.depth - b.depth)
      .map((meta) => meta.name_txt)
      .join("; ");
}

export function genomeCartsHasAll(state, id) {
  const fetchState = state.mic_fetch_state;
  return fromMicFetch.hasAll(fetchState, id);
}
