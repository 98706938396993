// 


// import
import {CACHE_TYPE} from "../action/cache";

export const initialState = {};

export const MY_GENOMES_CART_NAME = "My_Genomes";
export const RECENTLY_USED_CART_NAME = "Recently_Used";

/**
 * Fonction utilisée dans la définition d'une fonction de tri. Permet de trier les paniers afin qu'ils soient dans un ordre définit.
 * Premièrement les paniers créés par l'utlisateurs triés dans un ordre alphabétique.
 * Deuxièmement les paniers magiques, My_Genomes et Recently_Used dans cet ordre.
 *
 * @param {string} a Premier nom de panier à comparer
 * @param {string} b Deuxième nom de panier à comparer
 * @returns {integer} Valeur de sortie pour le tri, -1 pour a avant b, 1 pour b avant a. 0 signifie que l'ordre importe peu.
 */
export function genome_carts_name_sort(a, b) {
    if (a === b)
        return 0;
    switch(a) {
        case RECENTLY_USED_CART_NAME:
            // Always last
            return 1;
        case MY_GENOMES_CART_NAME:
            // Last except for RECENTLY_USED_CART_NAME
            if (b === RECENTLY_USED_CART_NAME) {
                return -1;
            }
            else {
                return +1;
            }
        default:
            // RECENTLY_USED_CART_NAME and MY_GENOMES_CART_NAME are after; else alphabetical order
            if ((b === MY_GENOMES_CART_NAME) || (b === RECENTLY_USED_CART_NAME)) {
                return -1;
            }
            else {
                return a.localeCompare(b);
            }
    }
}

export default function cache(
    state = initialState,
    action
) {
    switch (action.type) {
        case CACHE_TYPE:
            if (Object.keys(action.data_to_cache).length > 0) {
                let genome_carts_prop = [];
                for (let O_id in action.data_to_cache) {
                    for (let gene_cart_name of action.data_to_cache[O_id]) {
                        let index = genome_carts_prop.findIndex((item) => item.name === gene_cart_name)
                        if (index === -1) {
                            genome_carts_prop.push({
                                name: gene_cart_name,
                                harmonized_name: gene_cart_name,
                                content: [O_id],
                            })
                        } else {
                            genome_carts_prop[index].content.push(O_id);
                        }
                    }
                }
                //reorders the array
                genome_carts_prop.sort((a, b) => genome_carts_name_sort(a.name, b.name));
                return genome_carts_prop;
            } else {
                return state;
            }

        default:
            return state;
    }
}
