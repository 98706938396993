export default function Utils() {
  return {
    getOrCreateSelection: function(container, type, my_class) {
      var selection = container.select(type + "." + my_class);
      if (selection.empty()) {
        selection = container.append(type).classed(my_class, true);
      }
      return selection;
    }
  };
}
