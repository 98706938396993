//


// import
import {combineReducers} from "redux";
import {createNamedWrapperReducer} from "./utils";
import micFetch, * as fromMicFetch from "./mic-fetch";
import oidCache, * as fromOidCache from "./cache";

export const name = "nj_species_tree";
export const name_by_ocid = "nj_species_tree_by_ocid";

const initialState = {
  type: name,
  cache: fromOidCache.initialState,
  mic_fetch_state: fromMicFetch.initialState
};

const micFetchReducer = createNamedWrapperReducer(
  micFetch,
  name,
  initialState.mic_fetch_state
);

const oidCacheReducer = createNamedWrapperReducer(
  oidCache,
  name,
  initialState.cache
);

function setType(state = initialState.type) {
  return state;
}

const njSpeciesTree = combineReducers({
  type: setType,
  cache: oidCacheReducer,
  mic_fetch_state: micFetchReducer
});
export default njSpeciesTree;
