import {select} from "d3-selection";

export default function() {
  function exports(selection) {
    selection.each(function(_data) {
      const root_container = select(this);

      const simple_select = root_container
        .selectAll("div.simple-selector")
        .data(_data);

      // Exit
      simple_select.exit().remove();

      // Enter
      const simple_select_e = simple_select
        .enter()
        .append("div")
        .classed("simple-selector", true);

      simple_select_e.append("select").classed("custom-select", true);

      // Update
      const simple_select_u = simple_select.merge(simple_select_e);

      simple_select_u
        .select("select")
        .attr("id", d => d.id)
        .on("change", function(d) {
          const selected_options = select(this)
            .selectAll("option")
            .filter(function() {
              return this.selected;
            })
            .data()[0];
          d.eventHandlers.selectOption(selected_options.value);
        });

      // Options
      var options = simple_select_u
        .select("select")
        .selectAll("option")
        .data(function(d) {
          return d.options;
        });

      // Exit options
      options.exit().remove();

      // Enter options
      const options_e = options.enter().append("option");

      // Update options
      const options_u = options.merge(options_e);

      options_u
        .attr("value", d => d.value)
        .property("selected", d => (d.selected ? true : false))
        .text(d => d.name);
    });
  }
  return exports;
}
