//

export const toOrganism = (rawObj) => {
    return {
        O_id: parseInt(rawObj.O_id),
        O_name: rawObj.O_name,
        O_taxon: parseInt(rawObj.O_taxon),
        O_gram: rawObj.O_gram,
        O_strain: rawObj.O_strain ? rawObj.O_strain : "",
        O_access: rawObj.O_access,
    };
}

export const toFavoriteGenomes = (rawObj) => {
    return {
        O_id: parseInt(rawObj.O_id)
    };
}

export const toPublicGenome = (rawObj) => {
    return {
        GA_id: parseInt(rawObj.GA_id),
        GA_assembly_id: rawObj.GA_assembly_id,
        name: rawObj.name
    };
}

export const toSequence = (rawObj) => {
    const seqVersion = rawObj.R_version ? rawObj.R_version : "";
    return {
        S_id: parseInt(rawObj.S_id),
        O_id: parseInt(rawObj.O_id),
        R_type: rawObj.R_type,
        R_name: rawObj.R_name,
        R_version: seqVersion,
        S_label_tag: rawObj.S_label_tag,
        S_length: parseInt(rawObj.S_length),
        S_status: rawObj.S_status,
    };
}

export const toTaxonomy = (rawObj) => {
    return {
        O_id: parseInt(rawObj.O_id),
        tax_id: parseInt(rawObj.tax_id),
        depth: parseInt(rawObj.depth),
        name_txt: rawObj.name_txt,
        rank: rawObj.rank,
    };
}

export const toMicgc = (rawObj) => {
    return {
        ODP_id: parseInt(rawObj.ODP_id),
        OCP_id: parseInt(rawObj.OCP_id),
        OC_id: parseInt(rawObj.OC_id),
        O_id: parseInt(rawObj.O_id),
    };
}

export const toDistance = (rawObj) => {
    return {
        O_id_1: parseInt(rawObj.O_id_1),
        O_id_2: parseInt(rawObj.O_id_2),
        distance: parseFloat(rawObj.distance)
    };
}

export const toMetadata = (rawObj) => {
    const metValue = rawObj.MV_value ? rawObj.MV_value : rawObj.TM_value
    return {
        O_id: parseInt(rawObj.O_id),
        metadata_key: `${rawObj.MK_harmonized_name}#${metValue}`,
        metadata_id: rawObj.MK_id,
        metadata_name: rawObj.MK_name,
        metadata_harmonized_name: rawObj.MK_harmonized_name,
        metadata_value: metValue,
    };
}

export function toGenomeCart(raw_gc) {
    return {
        O_id: parseInt(raw_gc.O_id),
        cart_name: raw_gc.cart_name,
    };
}

export function toSequenceCart(raw_sc) {
    return {
        S_id: parseInt(raw_sc.S_id),
        cart_name: raw_sc.cart_name,
        O_id: parseInt(raw_sc.O_id)
    };
}