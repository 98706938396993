// 
// import
import "jquery";
import {Observable, of, throwError} from "rxjs";
import {catchError, filter, mergeMap, withLatestFrom} from "rxjs/operators";
import {ajax} from "rxjs/ajax";
import {failureAction, FETCH_TYPE, successAction} from "../action/mic-fetch";
import {filterNotInCache, groupBy} from "./cache";
import {CACHE_TYPE, cacheData} from "../action/cache";
import {paramEpicMap} from "./index";


// Code start here
export function fetchEpic(
    action$,
    state$,
    name,
) {
    const paramFuncName = paramEpicMap.get(name)
    return action$.pipe(
        isFetchTypeEpic(name),
        mergeMap(action => {
            const all_param = action.param;
            return of(action).pipe(
                withLatestFrom(state$),
                filterNotInCache(
                    paramFuncName.getCacheFunc,
                    paramFuncName.hasAllFunc,
                    action.id
                ),
                mergeMap(param =>
                    of(param).pipe(
                        fetchData(paramFuncName.requestType),
                        mergeMap(raw_data =>
                            of(raw_data.map(paramFuncName.toObjectFunc))
                        ),
                        groupBy(name),
                        checkResponse(param),
                        mergeMap(orga =>
                            of(cacheData(action.id, all_param, orga, name))
                        )
                    )
                ),
                catchError(error => of(failureAction(name, error)))
            );
        })
    );
}

export function cacheEpic(
    action$,
    state$,
    name
) {
    const paramFuncName = paramEpicMap.get(name)
    return action$.pipe(
        ofTypeAndName(CACHE_TYPE, name),
        withLatestFrom(state$),
        mergeMap(function ([action, state]) {
            // get the data
            const param = action.param;
            if (param === "list") {
                const cache = paramFuncName.getCacheFunc(state);
                return of(successAction(name, param, cache));
            } else {
                const cache = param.reduce((accum, curr) => {
                    try {
                        const obj = paramFuncName.getByIdFunc(state, curr.toString());
                        return {...accum, ...{[curr]: obj}};
                    } catch (error) {
                        console.log(error);
                        return accum;
                    }
                }, {});
                return of(successAction(name, param, cache));
            }
        })
    );
}


export const isFetchTypeEpic = (name) => (
  source
) =>
  source.pipe(
    filter((action) => action.type === FETCH_TYPE),
    filter((action) => action.name === name)
  );

/**
 *
 *
 * @export
 * @param {string} type
 * @param {string} name
 * @returns
 */
export function ofTypeAndName(type, name) {
  return function(
    source
  ) {
    return source.ofType(type).pipe(
      filter(action => {
        return action.name === name;
      })
    );
  };
}

/**
 * Genric epic function that make the ajax request
 *
 * @export
 * @param {string} request_type the type for the ajax request => (cf. tojson.sql.inc.php)
 * @return {Observable<Array<Object>>} json data
 */
export function fetchData(request_type) {
  return function(
    source
  ) {
    return source.pipe(
      mergeMap(function(param) {
        if (param !== null) {
          const data_request = {
            type: request_type,
            value: param
          };

          const ajax_param = {
            url: "../export/tojson.php",
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            body: $.param(data_request)
          };
          return ajax_query(ajax_param);
        } else {
          return of([]);
        }
      })
    );
  };
}

function ajax_query(ajax_param) {
  return ajax(ajax_param).pipe(
    mergeMap(
      (response, index) => {
        const json_data = response.response;

        if (json_data !== null) {
          return of(json_data);
        } else {
          return of([]);
        }
      }
    ),
    catchError(error => {
      return of(error).pipe(throwError(error));
    })
  );
}

export function checkResponse(param) {
  return function(source) {
    return source.pipe(
      mergeMap(function(cache) {
        if (typeof param == "string") {
          return of(cache);
        } else {
          if (param === null) {
            return of({});
          } else {
            if (param.length === Object.keys(cache).length) {
              return of(cache);
            } else {
              const set_param = new Set(param);
              const set_response = new Set(
                Object.keys(cache).map(oid => parseInt(oid))
              );
              const diff = new Set(
                [...set_param].filter(x => !set_response.has(x))
              );
              throw new RangeError("Missing data: " + [...diff].join(", "));
            }
          }
        }
      })
    );
  };
}
