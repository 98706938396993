// 
import oidCache, * as fromOidCache from "./cache";
import genomeCartListCache, * as fromGenomeCartListCache from "./genome-carts-name";
import micFetch, * as fromMicFetch from "./mic-fetch";
import {createNamedWrapperReducer} from "./utils";

export function genomeCartsInit(name) {

  const initialState = {
    type: name,
    cache: fromOidCache.initialState,
    carts_list: fromGenomeCartListCache.initialState,
    mic_fetch_state: fromMicFetch.initialState
  };

  const micFetchReducer = createNamedWrapperReducer(
    micFetch,
    name,
    initialState.mic_fetch_state
  );

  const oidCacheReducer = createNamedWrapperReducer(
    oidCache,
    name,
    initialState.cache
  );

  const cartsListCacheReducer = createNamedWrapperReducer(
    genomeCartListCache,
    name,
    initialState.carts_list
  );

  function setType(state = initialState.type) {
    return state;
  }

  return {
    type: setType,
    cache: oidCacheReducer,
    carts_list: cartsListCacheReducer,
    mic_fetch_state: micFetchReducer
  }
}
