// 


import {FAILURE_FETCH_TYPE, FETCH_TYPE, SUCCESS_FETCH_TYPE,BIOB_LIST_FAILURE_FETCH_TYPE, BIOB_LIST_FETCH_TYPE, BIOB_LIST_SUCCESS_FETCH_TYPE} from "../action/mic-fetch";

export const initialState = {};

const empty_fetching = () => ({
  hasAll: false,
  isFetching: false,
  completed: false,
  error: null
});

/**
 *
 * Reducer that handle ajax request state (fetching, success, failure)
 *
 * @export
 * @param {FetchState} [state=initialState]
 * @param {MicFetchAction} action
 * @returns {FetchState}
 */
export default function micFetch(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_TYPE:
    case BIOB_LIST_FETCH_TYPE:
      return fetching(state, action);
    case SUCCESS_FETCH_TYPE:
    case BIOB_LIST_SUCCESS_FETCH_TYPE:
      return success(state, action);
    case FAILURE_FETCH_TYPE:
    case BIOB_LIST_FAILURE_FETCH_TYPE:
      return failure(state, action);
    default:
      return state;
  }
}


/**
 *
 * Case reducer that change state when ajax request start fetching
 * @param {FetchState} state
 * @param {FetchAction<string, MicRequestValueParam>} action
 * @returns {FetchState}
 */
function fetching(
  state,
  action
) {
  return {
    ...state,
    [action.id]: {
      ...state[action.id],
      isFetching: true,
      completed: false,
      error: null,
      hasAll:
        state.hasOwnProperty(action.id) &&
        state[action.id].hasOwnProperty("hasAll")
          ? state[action.id].hasAll
          : false
    }
  };
}


/**
 * Change state when ajax request succeed
 *
 * @param {FetchState} state
 * @param {SuccessAction<string, MicRequestValueParam, any>} action
 * @returns {FetchState}
 */


function success(
  state,
  action
) {
  return {
    ...state,
    [action.id]: {
      ...state[action.id],
      hasAll: hasRequestList(state[action.id].hasAll, action.param),
      isFetching: false,
      completed: true,
      error: null
    }
  };
}


/**
 * Change state when ajax request failed
 *
 * @param {FetchState} state
 * @param {FailureAction<string, any>} action
 * @returns {FetchState}
 */
function failure(
  state,
  action
) {
  return {
    ...state,
    [action.id]: {
      ...state[action.id],
      isFetching: false,
      completed: false,
      error: action.error
    }
  };
}

/**
 * Test if the current state has all the the server's
 * data
 *
 * @param {boolean} hasAll
 * @param {MicRequestValueParam} oids
 * @returns {boolean}
 */
function hasRequestList(hasAll, oids) {
  return oids === "list" || hasAll
}
