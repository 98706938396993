// 


/**
 * Wrapper (like a decorator) that make a reducer to be executed 
 * only if the action.name match. This is an important function for reducer reuse.
 *
 * @export
 * @param {*} reducerFunction
 * @param {string} reducerName The name that must match 
 * @param {*} initialState
 * @return {function} reducer wrapped with this functionality
 */
export function createNamedWrapperReducer(
  reducerFunction,
  reducerName,
  initialState
) {
  return function(state = initialState, action) {
    const { name } = action;
    const isInitializationCall = state === undefined;
    if (name !== reducerName && !isInitializationCall) return state;
    return reducerFunction(state, action);
  };
}
