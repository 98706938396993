//
//import
import {notExistMicgcException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

// code start here
export function getMicgcCache(state) {
  return state.cache;
}

export function getListMicgc(state) {
  const cache = getMicgcCache(state);
  return Object.values(cache).reduce(function(
    micgcList,
    micgc
  ) {
    const oid_str = micgc.O_id.toString();
    micgcList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return micgcList;
  },
  {});
}

export function getMicgcById(state, id) {
  const cache = getMicgcCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistMicgcException(id);
  }
}

export function getStringifyMicgcById(
  state,
  id,
  org_string
) {
  return stringify(getMicgcById(state, id), org_string);
}

export function stringify(micgc, org_string) {
  return "MICGC" + micgc.OC_id + " - " + org_string;
}

export function getOcidsfromOids(state, oids) {
  return [
    ...oids.reduce(function(ocids, oid) {
      try {
        const micgc = getMicgcById(state, oid);
        ocids.add(micgc.OC_id.toString());
        return ocids;
      } catch (error) {
        console.log(error);
      } finally {
        return ocids;
      }
    }, new Set())
  ];
}
export function micgcHasAll(state, id) {
  const fetchState = state.mic_fetch_state;
  return fromMicFetch.hasAll(fetchState, id);
}
