//
// import
import {filter, map, mergeMap, startWith, take, withLatestFrom, zip,} from "rxjs/operators";
import {Observable, of} from "rxjs";
// Epics
import {ofTypeAndName} from "./mic-fetch";
import {getMicgcByOcidById, getOcidsfromOids} from "../selector";
// Actions
import {CACHE_TYPE, cacheData} from "../action/cache";
import {FETCH_TYPE, fetchAction, SUCCESS_FETCH_TYPE} from "../action/mic-fetch";
import {
    fetchNjSpeciesTree,
    fetchNjSpeciesTreeByOcidSuccess,
    fetchNjSpeciesTreeSuccess,
} from "../action/nj-species-tree";
// Name
import {
    pkgdbTaxoName,
    pkgdbOrganismName,
    distanceName,
    micgcByOcidName,
    micgcName
} from "../reducer/reducer-declaration";
import {name as njSpeciesTreeName, name_by_ocid as njSpeciesTreeNameByOcid,} from "../reducer/nj-species-tree";
// Code start here
/**
 *
 *
 * @export
 * @param {ActionsObservable<NjSpeciesTreeAction>} action$
 * @param {Observable<MicState>} state$
 * @returns
 */
export function fetchNjSpeciesTreeEpic(
  action$,
  state$
) {
  return action$.pipe(
    ofTypeAndName(FETCH_TYPE, njSpeciesTreeName),
    mergeMap(function(action) {
      const initial_param = action.param;
      return action$.pipe(
        zip(
          action$.pipe(
            takeOneFromAction(SUCCESS_FETCH_TYPE, pkgdbOrganismName, initial_param.param)
          ),
          action$.pipe(
            takeOneFromAction(SUCCESS_FETCH_TYPE, pkgdbTaxoName, initial_param.param)
          ),
          action$.pipe(
            takeOneFromAction(SUCCESS_FETCH_TYPE, micgcName, initial_param.param)
          ),
          action$.pipe(
            takeOneFromAction(SUCCESS_FETCH_TYPE, distanceName, initial_param.param)
          )
        ),
        withLatestFrom(state$),
        mergeMap(([results, state]) => {
          const distance = results[4].data;
          const initial_param = action.param.param;
          const species_id = action.param.id;
          const title = action.param.title;
          const ocids = getOcidsfromOids(state, initial_param).map(ocid =>
            parseInt(ocid)
          );
          return action$.pipe(
            zip(
              action$.pipe(takeOneFromAction(SUCCESS_FETCH_TYPE, micgcByOcidName, ocids))
            ),
            withLatestFrom(state$),
            mergeMap(function([results, state]) {
              const matrix = constructMatrix(initial_param.length, distance);
              const data = {
                [species_id]: {
                  id: species_id,
                  oids: initial_param,
                  ocids,
                  distance,
                  title,
                  matrix,
                  matrix_nj_tree: constructNJMatrix(matrix),
                },
              };
              const cache_action = cacheData(
                species_id,
                initial_param,
                data,
                njSpeciesTreeName
              );
              return of(action).pipe(map(action => cache_action));
            }),
            startWith(fetchAction(micgcByOcidName,ocids))
          );
        }),
        startWith(
          fetchAction(pkgdbOrganismName,initial_param.param),
          fetchAction(pkgdbTaxoName,initial_param.param),
          fetchAction(micgcName,initial_param.param),
          fetchAction(distanceName,initial_param.param)
        )
      );
    })
  );
}

/**
 *
 *
 * @export
 * @param {ActionsObservable<CacheAction<MicRequestValueParam, String, any>>} action$
 * @param {ActionsObservable<MicState>} state$
 * @returns {*}
 */
export function cacheNjSpeciesTreeEpic(
  action$,
  state$
) {
  return action$.pipe(
    ofTypeAndName(CACHE_TYPE, njSpeciesTreeName),
    mergeMap(function(action) {
      return of(
        fetchNjSpeciesTreeSuccess(action.param, action.data_to_cache)
      );
    })
  );
}

/**
 *
 *
 * @export
 * @param {ActionsObservable<NjSpeciesTreeAction>} action$
 * @param {ActionsObservable<MicState>} state$
 * @returns
 */
export function fetchNjSpeciesTreeByOcidEpic(
  action$,
  state$
) {
  return action$.pipe(
    mergeMap(function(action, index) {
      return of(action);
    }),
    ofTypeAndName(FETCH_TYPE, njSpeciesTreeNameByOcid),
    mergeMap(function(action, index) {
      const ocids = action.param.param;
      const species_id = action.param.id;
      const title = action.param.title;

      return action$.pipe(
        zip(action$.pipe(takeOneFromAction(SUCCESS_FETCH_TYPE, micgcByOcidName, ocids))),
        withLatestFrom(state$),
        mergeMap(function([results, state]) {
          const oids = ocids.reduce((acc, ocid) => {
            const micgc_by_ocid = getMicgcByOcidById(state, ocid);
            return [...acc, ...Object.keys(micgc_by_ocid)];
          }, []);
          const param = {
            id: species_id,
            title,
            param: oids,
          };
          return of(fetchNjSpeciesTree(param));
        }),
        startWith(fetchAction(micgcByOcidName,ocids))
      );
    })
  );
}


/**
 *
 *
 * @export
 * @param {ActionsObservable<CacheAction<MicRequestValueParam, String, any>>} action$
 * @param {ActionsObservable<MicState>} state$
 * @returns {*}
 */
export function cacheNjSpeciesTreeByOcidEpic(
  action$,
  state$
) {
  return action$.pipe(
    ofTypeAndName(CACHE_TYPE, njSpeciesTreeNameByOcid),
    mergeMap(function(action) {
      return of(
        fetchNjSpeciesTreeByOcidSuccess(
          action.param,
          action.data_to_cache
        )
      );
    })
  );
}

/**
 *
 *
 * @param {*} type
 * @param {*} name
 * @param {*} param
 * @returns
 */
function takeOneFromAction(type, name, param) {
  return function(source) {
    return source.pipe(
      ofTypeAndName(type, name),
      filter(action => action.param === param),
      take(1)
    );
  };
}


/**
 *
 *
 * @param {number} size
 * @param {PkgdbDistance[]} raw_distances
 * @returns {Array<Array<PkgdbDistance>>}
 */
function constructMatrix(
  size,
  raw_distances
) {
  const distances = raw_distances
    .map(dist =>
      dist.O_id_1 <= dist.O_id_2
        ? { ...dist }
        : {
            ...dist,
            O_id_1: dist.O_id_2,
            O_id_2: dist.O_id_1,
          }
    )
    .sort((a, b) => {
      const diff = a.O_id_1 - b.O_id_1;
      return diff !== 0 ? diff : a.O_id_2 - b.O_id_2;
    });
  const matrix = [];
  for (let i = 0; i < size; i++) {
    matrix.push([]);
    for (let j = 0; j < size; j++) {
      if (i <= j) {
        const index_distances = i * size + j - ((i + 1) * i) / 2;
        matrix[i].push({
          ...distances[index_distances],
        });
      } else {
        matrix[i].push(matrix[j][i]);
      }
    }
  }
  return addOutgroupToMatrix(matrix, size);
}


/**
 *
 *
 * @param {Array<Array<PkgdbDistance>>} matrix
 * @param {number} size
 * @returns {Array<Array<PkgdbDistance>>}
 * TODO: Use the constant for outgroup
 */
function addOutgroupToMatrix(
  matrix,
  size
) {
  matrix.map(function(row) {
    row.push({
      distance: 1,
      O_id_1: -1,
      O_id_2: -1,
    });
    return row;
  });
  let outgroupRow = Array(...Array(size + 1)).map(function(el, i) {
    return i < size
      ? { distance: 1, O_id_1: -1, O_id_2: -1 }
      : { distance: 0, O_id_1: -1, O_id_2: -1 };
  });
  matrix.push(outgroupRow);
  return matrix;
}


/**
 *
 *
 * @param {Array<Array<PkgdbDistance>>} matrix
 * @returns {Array<Array<number>>}
 */
function constructNJMatrix(
  matrix
) {
    return matrix.map((row) => (
      row.map((cell) => cell.distance)
    ))
}
