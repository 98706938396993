//
//import
import {notExistSequenceException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";
import {getMicFetch} from "./fetch-cache";

// Code start here
export function getSequenceCache(state) {
  return state.cache;
}

export function getListSequence(state) {
  const cache = getSequenceCache(state);
  return Object.values(cache).reduce(function(
    sequenceList,
    seq
  ) {
    const sid_str = seq.S_id.toString();
    sequenceList[sid_str] = {
      id: sid_str,
      selected_attr: false,
      in_selection: false,
      is_filtered: false,
      oid: seq.O_id.toString()
    };
    return sequenceList;
  },
  {});
}

export function getSequenceById(
  state,
  id
) {
  const cache = getSequenceCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistSequenceException(id);
  }
}
export function getOidFromSid(state, id) {
  return getSequenceById(state, id).O_id.toString();
}
export function getStringifySequenceById(
  state,
  id,
  org_string
) {
  const seq = getSequenceById(state, id);
  return stringify(seq, org_string);
}

export function stringify(seq, org_string) {
  const seqVersion = seq.R_version !== "" ? "." + seq.R_version : "";
  return [
    org_string,
    seq.R_type.toString(),
    seq.R_name.toString() + seqVersion
  ].join(" ");

  //   org_string + " - " + seq.R_name.toString() + " - " + seq.R_type.toString()
  // );
}

export function sequenceHasAll(state, id) {
  return fromMicFetch.hasAll(getMicFetch(state), id);
}
