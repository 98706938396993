import * as fromRootSelector from "../selector";
import * as fromBiobList from "../selector/biob-list";
import {nest} from "d3-collection";
import {
    addBiobFilters,
    addBiobsInSelection,
    addGenomeCartInSelection,
    addSequenceCartInSelection,
    changeBiobFilterCriteria,
    pushBiobListsStateToHistory,
    removeBiobFilters,
    removeBiobsInSelection,
    setBiobGrouping,
    setBiobsInSelection,
    setCancelStateToHistory,
    undoBiobLists,
} from "../action/biob-list";
import {addFilter} from "../action/filter";
import store from "../store";

// single selector

/**
 * Return the data that will be used in the d3-component/biob-list-single-selector.js
 *  to draw a single selector
 * The data contains the eventHandler for the different event that are
 * fired by this selector interface.
 *
 * @export
 * @param {*} suggestion_engine The suggestion engine generated by the selectors in selector/suggestion-engine
 * @return {{id: string, suggestion_engine: Object, placeholder: string, eventHandlers: {selectSuggestion: * }}} single selector d3 data
 */
export function getSingleSelectorDataFactory(suggestion_engine) {
  return function(state, biob_list_id) {
    const biob_name = fromRootSelector.getBiobName(state, biob_list_id);
    const biobs = fromRootSelector.getBiobsById(state, biob_list_id);
    return {
      id: biob_list_id,
      biob_name: fromRootSelector.getBiobName(state, biob_list_id),
      suggestion_engine,
      isFetching: fromRootSelector.isBiobListFetching(store.getState(), biob_list_id),
      placeholder: "Find a " + biob_name + " among " + biobs.length,
      eventHandlers: {
        selectSuggestion: function(oidSelected) {
          store.dispatch(setBiobsInSelection([oidSelected], biob_list_id));
        },
      },
    };
  };
}

/**
 * Return the data that will be used in the d3-component/biob-list-multiple-selector.js
 *  to draw a multiple selector.
 * The data contains the eventHandler for the different event that are
 * fired by this selector interface.
 *
 * @export
 * @param {*} state Application state
 * @param {string} biob_list_id identifier of the selector
 * @param {string} status_type Value that indicate the name of the property (key) in the state objet that hold the in-selection/not-in-selection state
 * @param {string} title Title of the selector
 * @param {function(state: *, biob_id: string): string} toBiobString Function that return the string value of a biob.
 * @param {Object} not_in_selection_suggestion_engine Suugestion engine for the biobs that are not in the selection
 * @param {Filter[]} not_in_selection_biobs_with_filter List filters for the biobs that are not in the selection.
 * @param {ListBiob[]} not_in_selection_filtered_biobs List filtered biobs not in selection
 * @param {Object} in_selection_suggestion_engine Suggestion engine for the biobs that are selected
 * @param {Filter[]} in_selection_biobs_with_filter List filters for the biobs that are in the selection.
 * @param {ListBiob[]} in_selection_filtered_biobs List filtered biobs in selection
 * @param {string[]} ranks Available ranks
 * @param {function(state: *, biob_id: string, group: string): string} getGroup
 * @returns
 */
export function getMultipleSelectorData(
  state,
  biob_list_id,
  status_type,
  title,
  toBiobString,
  not_in_selection_suggestion_engine,
  not_in_selection_biobs_with_filter,
  not_in_selection_filtered_biobs,
  in_selection_suggestion_engine,
  in_selection_biobs_with_filter,
  in_selection_filtered_biobs,
  ranks,
  getGroup
) {
  const [min_biobs, max_biobs] = fromRootSelector.getBiobListRange(
    state,
    biob_list_id
  );
  const grouping = fromBiobList.getGrouping(
    fromRootSelector.getBiobLists(state),
    biob_list_id,
    status_type,
    true
  );
  const biob_list_type = fromRootSelector.getBiobListTypeById(
    state,
    biob_list_id
  );
  const in_selection_biobs = getBiobSelectData(
    state,
    biob_list_id,
    "Selection",
    biob_list_type,
    status_type,
    true,
    max_biobs,
    ranks,
    grouping,
    in_selection_filtered_biobs,
    in_selection_suggestion_engine,
    in_selection_biobs_with_filter,
    toBiobString,
    getGroup
  );
  const not_in_selection_biobs = getBiobSelectData(
    state,
    biob_list_id,
    "Search",
    biob_list_type,
    status_type,
    false,
    max_biobs,
    ranks,
    grouping,
    not_in_selection_filtered_biobs,
    not_in_selection_suggestion_engine,
    not_in_selection_biobs_with_filter,
    toBiobString,
    getGroup
  );

  const status = fromRootSelector.getRangeStatusById(state, biob_list_id);
  const error_msg_data = status
    ? [{ id: "range_alert", msg: status }]
    : [];
  const changeGrouping = function(rank) {
    store.dispatch(setBiobGrouping(rank, biob_list_id, status_type, "true"));
  };
  let biob_type = fromRootSelector.getBiobListTypeById(state, biob_list_id);
  return {
    biob_type: biob_type,
    biob_name: fromRootSelector.getBiobName(state, biob_list_id),
    id: biob_list_id,
    title,
    error_msg_data,
    carts: biob_type === "organism" ? fromRootSelector.getGenomeCartsList(state) : fromRootSelector.getSequenceCartsList(state),
    biobs_range: [min_biobs, max_biobs],
    biobs_grouping_btn_modal: ranks
      ? {
          id: biob_list_id + "-grouping-biobs-modal",
          options: ranks.map(rank => {
            const selected = rank === grouping ? true : false;
            return { value: rank, name: rank, selected };
          }),
          eventHandlers: {
            selectOption: changeGrouping,
          },
        }
      : null,
    biobs_grouping_btn_selector:
      ranks && in_selection_filtered_biobs.length > 0
        ? {
            id: biob_list_id + "-grouping-biobs-selector",
            options: ranks.map(rank => {
              const selected = rank === grouping ? true : false;
              return { value: rank, name: rank, selected };
            }),
            eventHandlers: {
              selectOption: changeGrouping,
            },
          }
        : null,
    in_selection_biobs,
    not_in_selection_biobs,
    in_selection_accordion: getBiobListAccordionData(
      state,
      biob_list_id,
      toBiobString,
      status_type,
      getGroup
    ),
    valid_selection:
      in_selection_biobs.total_biobs <= max_biobs &&
      in_selection_biobs.total_biobs >= min_biobs &&
      error_msg_data.length > 0 &&
      error_msg_data[0].msg.correct_selection
        ? true
        : false,
    eventHandlers: {
      editSelector: function() {
        console.log("!!! EDIT !!!");
      },
      add: function(oidsSelected) {
        store.dispatch(
          addBiobsInSelection(
            oidsSelected.map(oid => parseInt(oid)),
            biob_list_id
          )
        );
      },
      remove: function(oidsSelected) {
        store.dispatch(
          removeBiobsInSelection(
            oidsSelected.map(oid => parseInt(oid)),
            biob_list_id
          )
        );
      },
      setCart: function (biob_type, cartName) {
        if (biob_type === "organism")
          store.dispatch(
            addGenomeCartInSelection(
              fromRootSelector.getBiobFromGenomeCartById(state, cartName),
              biob_list_id
            )
          );
        else
          store.dispatch(
            addSequenceCartInSelection(
              fromRootSelector.getBiobFromSequenceCartById(state, cartName),
              biob_list_id
            )
          );
      },
      save: function() {
        const state = store.getState();
        const filterIdsNotInSelection = fromRootSelector.getNotInSelectionFilterIds(
          state,
          biob_list_id
        );
        const filterIdsInSelection = fromRootSelector.getInSelectionFilterIds(
          state,
          biob_list_id
        );
        store.dispatch(
          removeBiobFilters(
            biob_list_id,
            status_type,
            "false",
            filterIdsNotInSelection
          )
        );
        store.dispatch(
          removeBiobFilters(
            biob_list_id,
            status_type,
            "true",
            filterIdsInSelection
          )
        );
        store.dispatch(
          pushBiobListsStateToHistory(
            fromRootSelector.getBiobListById(state, biob_list_id),
            biob_list_id
          )
        );
        // This is the new cancelState
        store.dispatch(
          setCancelStateToHistory(
            fromRootSelector.getBiobListById(state, biob_list_id),
            biob_list_id
          )
        );
      },
      cancel: function() {
        const state = store.getState();
        const history = fromRootSelector.getBiobListsHistory(state)[
          biob_list_id
        ];

        store.dispatch(
          undoBiobLists(history.cancelState, biob_list_id)
        );
      },
      reset: function() {
        const state = store.getState();
        const history = fromRootSelector.getBiobListsHistory(state)[
          biob_list_id
        ];
        // Set initial biob list as current state and add it to history
        store.dispatch(
          undoBiobLists(history.initialState, biob_list_id)
        );
        store.dispatch(
          pushBiobListsStateToHistory(
            history.initialState,
            biob_list_id
          )
        );
      },
    },
  };
}

/**
 * Generate the d3 data for a the biobs
 * that are in the selection or not in the selection
 *
 * @param {*} state
 * @param {*} biob_list_id
 * @param {*} title
 * @param {*} biob_list_type
 * @param {*} status_type
 * @param {*} status_type_key
 * @param {*} max_biobs
 * @param {*} ranks
 * @param {*} grouping
 * @param {*} biobs
 * @param {*} suggestion_engine
 * @param {*} biobs_with_filter
 * @param {*} toBiobString
 * @param {*} getGroup
 * @returns
 */
function getBiobSelectData(
  state,
  biob_list_id,
  title,
  biob_list_type,
  status_type,
  status_type_key,
  max_biobs,
  ranks,
  grouping,
  biobs,
  suggestion_engine,
  biobs_with_filter,
  toBiobString,
  getGroup
) {
  const biob_select_id =
    biob_list_id + "_" + status_type + "_" + status_type_key.toString();

  const biob_status_value = status_type_key ? "true" : "false";

  // Get the list of filter ids (can be an empty array if none)
  const filter_ids = fromBiobList.getBiobStatusValueByType(
    fromRootSelector.getBiobListById(state, biob_list_id),
    status_type,
    status_type_key
  ).filters;

  // Get the filters
  const filters = filter_ids.map((filter_id, i) => {
    const filter = fromRootSelector.getFilterDataById(
      state,
      filter_id,
      biob_select_id,
      i,
      biobs_with_filter[i + 1].size
    );
    return {
      ...filter,
      eventHandlers: {
        removeFilter: function() {
          store.dispatch(
            removeBiobFilters(biob_list_id, status_type, status_type_key, [
              filter_id,
            ])
          );
        },
      },
    };
  });
  // Construct the options array.

  // if not in selection and no filter, we don't display anything
  const biobs_to_option = status_type_key
    ? biobs
    : filter_ids.length === 0
    ? []
    : biobs;

  const options = biobs_to_option.map(function(biob) {
    let group;
    try {
      group = getGroup(
        state,
        biob.oid,
        fromRootSelector.getGroupingByIdByStatus(
          state,
          biob,
          biob_list_id,
          status_type,
          true
        )
      );
    } catch (error) {
      console.log(error);
      group = "Unset";
    }
    let name = "";
    try {
      name = toBiobString(state, biob.id);
    } catch (error) {}

    return {
      name,
      value: biob.id,
      group,
      selected: biob.selected_attr,
    };
  });
  // The buttons
  const button = status_type_key
    ? {
        label: "Remove genomes from selection",
        value: "remove",
        type: "danger",
        id: biob_select_id + "-remove-from-cart-btn",
        icon_class: "fa-minus",
        data: options,
      }
    : {
        label: "Add genomes to selection",
        value: "add",
        type: "success",
        id: biob_select_id + "-add-to-cart-btn",
        icon_class: "fa-plus",
        data: options,
      };
  const biob_name = fromRootSelector.getBiobName(
    state,
    biob_list_id
  );
  const displayed_biobs = options.length;
  const total_biobs = biobs.length;
  const placeholder = status_type_key
    ? "Filter among " + displayed_biobs + " " + biob_name + "(s)."
    : filters.length >= 1
    ? "Search among " + displayed_biobs + " " + biob_name + "(s)."
    : "Search among " + total_biobs + " " + biob_name + "(s).";
  return {
    title,
    id: biob_select_id,
    type: status_type + "_" + status_type_key.toString(), //params[status_type].type,
    biob_type: fromRootSelector.getBiobListTypeById(state, biob_list_id),
    biob_name: fromRootSelector.getBiobName(state, biob_list_id),
    max_biobs,
    total_biobs,
    displayed_biobs,
    grouping,
    filter_criteria: getFilterCriteria(
      biob_list_id,
      fromRootSelector.getFilterCriteriaById(
        state,
        biob_list_id,
        status_type,
        biob_status_value //params[status_type].status_value
      ),
      biob_select_id,
      suggestion_engine,
      status_type,
      status_type_key
    ),
    placeholder,
    data: {
      id: biob_select_id + "_select",
      multiple: true,
      size: null,
      button,
      options,
    },
    suggestion_engine: suggestion_engine,
    filters,
    eventHandlers: {
      selectSuggestion: function(suggestionDataEnhanced) {
        const filter_id = addFilterToStore(suggestionDataEnhanced);
        store.dispatch(
          addBiobFilters(biob_list_id, status_type, status_type_key, [
            filter_id,
          ])
        );
      },
      addKeywordFilter: function(filterData) {
        const { query, type, subtype, matching_type, filter_name } = filterData;
        const filter_criteria_type = fromRootSelector.getFilterCriteriaById(
          store.getState(),
          biob_list_id,
          status_type,
          status_type_key
        );
        const filter_id = addUserDefinedFilterToStore(
          query,
          type,
          subtype,
          matching_type,
          filter_name
        );
        store.dispatch(
          addBiobFilters(biob_list_id, status_type, status_type_key, [
            filter_id,
          ])
        );
      },
    },
  };
}

/**
 *
 *
 * @param {*} value
 * @param {*} type
 * @param {*} subtype
 * @param {*} matching_type
 * @param {*} filter_name
 * @returns
 */
function addUserDefinedFilterToStore(
  value,
  type,
  subtype,
  matching_type,
  filter_name
) {
  const filter_id = [
    type,
    subtype ? subtype : "null",
    matching_type,
    value.split(" ").join("_"),
  ].join("-");
  store.dispatch(
    addFilter(filter_id, type, subtype, matching_type, value, value)
  );
  return filter_id;
}

/**
 *
 *
 * @param {*} suggestionDataEnhanced
 * @returns
 */
function addFilterToStore(suggestionDataEnhanced) {
  const {
    filter_type: type,
    clustering_name: subtype,
    cluster_id: value,
    matching_type,
    filter_name: filterName,
  } = suggestionDataEnhanced;
  const filter_id = [
    type,
    subtype ? subtype : "null",
    matching_type,
    value,
  ].join("-");

  store.dispatch(
    addFilter(filter_id, type, subtype, matching_type, value, filterName)
  );
  return filter_id;
}

/**
 *
 *
 * @param {*} state
 * @param {*} biob_list_id
 * @param {*} toBiobString
 * @param {*} status_type
 * @param {*} getGroup
 * @returns
 */
function getBiobListAccordionData(
  state,
  biob_list_id,
  toBiobString,
  status_type,
  getGroup
) {
  const biobs = fromRootSelector.getInSelectionBiobs(state, biob_list_id);
  const selected_biobs_options = biobs.map(function(biob) {
    let group;
    try {
      group = getGroup(
        state,
        biob.oid,
        fromRootSelector.getGroupingByIdByStatus(
          state,
          biob,
          biob_list_id,
          status_type,
          true
        )
      );
    } catch (error) {
      console.log(error);
      group = "Unset";
    }
    let name = "";
    try {
      name = toBiobString(state, biob.id);
    } catch (error) {}
    return {
      name,
      value: biob.id,
      group,
      selected: biob.selected_attr,
    };
  });

  if (selected_biobs_options) {
    var res = nest()
      .key(function(d) {
        return d.group;
      })
      .sortKeys(function(a, b) {
        var unset = "Unset";
        if (a === unset && b !== unset) return 1;
        if (a !== unset && b === unset) return -1;
        return a.localeCompare(b);
      })
      .entries(selected_biobs_options);
    return res;
  } else {
    return [];
  }
}

function getFilterCriteria(
  biobListId,
  filter_criteria,
  id,
  suggestion_engine,
  status_type,
  status_type_key
) {
  const options = Object.keys(suggestion_engine).map(function(key) {
    const selected = key === filter_criteria ? true : false;

    const no_underscore_key = [...key.split("_")].join("\n");

    return {
      selected,
      value: key,
      name: no_underscore_key,
    };
  });

  return {
    id: id + "_filter_criteria",
    multiple: false,
    options,
    size: null,
    eventHandlers: {
      selectOption: filterCriteria =>
        store.dispatch(
          changeBiobFilterCriteria(
            biobListId,
            status_type,
            status_type_key,
            filterCriteria
          )
        ),
    },
  };
}
