//
// import
import {mergeMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {set_taxo_rank} from "../reducer/taxonomic-rank";
import {
    speciesMetadataName,
    strainMetadataName,
    refseqTaxoName,
    pkgdbTaxoName,
    favoriteGenomesName,
    refseqSequenceName,
    pkgdbSequenceName,
    refseqOrganismName,
    pkgdbOrganismName,
    pkgdbMyOrganismName,
    publicGenomeName,
    micgcByOcidName,
    micgcName,
    genomeCartsName,
    sequenceCartsName
} from "../reducer/reducer-declaration";

// Code start here
// epic
export const filterNotInCache = function(
  extractCache,
  hasAll,
  id
) {
  return (
    source
  ) => {
    return source.pipe(
      mergeMap(function([{ param }, state]) {
        if (typeof param === "string" && param === "list") {
          const hasAllBool = hasAll(state, id);
          if (!hasAllBool) {
            return of(param);
          } else {
            return of(null);
          }
        } else {
          const cache = extractCache(state);
          const filter_param = param.filter(oid => !cache.hasOwnProperty(oid));
          if (filter_param.length > 0) {
            return of(filter_param);
          } else {
            return of(null);
          }
        }
      })
    );
  };
};

export function groupBy(name) {
    switch (name) {
        case strainMetadataName:
        case speciesMetadataName:
            return groupMetaByOid()
        case genomeCartsName:
            return getGenomeCartsById()
        case sequenceCartsName:
            return getSequenceCartsById()
        case pkgdbMyOrganismName:
        case pkgdbOrganismName:
        case refseqOrganismName:
        case favoriteGenomesName:
        case micgcName:
            return groupByOid()
        case pkgdbSequenceName:
        case refseqSequenceName:
            return groupBySid()
        case pkgdbTaxoName:
        case refseqTaxoName:
            return groupTaxoByOid()
        case micgcByOcidName:
            return groupByOcid()
        case publicGenomeName:
            return groupByGaid()
    }
}

export function groupByOid() {
  return function(
    source
  ) {
    return source.pipe(
      mergeMap(function(data) {
        const groupedData = data.reduce((acc, curr) => {
          const oid = curr.O_id
          acc[oid.toString()] = curr
          return acc;
        }, {});
        return of(groupedData);
      })
    );
  };
}

export function groupBySid() {
  return function(
    source
  ) {
    return source.pipe(
      mergeMap(function(data) {
        return of(
          data.reduce((acc, curr) => {
            const sid = curr.S_id
            acc[sid.toString()] = curr
            return acc
          }, {})
        );
      })
    );
  };
}

export function groupByOcid() {
  return function(
    source
  ) {
    return source.pipe(
      mergeMap(function(data) {
        return of(
          data.reduce((acc, curr) => {
            const ocid = curr.OC_id.toString()
            const oid = curr.O_id.toString()
            if (acc.hasOwnProperty(ocid)) {
              acc[ocid][oid] = curr
            } else {
              acc[ocid] = {[oid]: curr}
            }
            return acc
          }, {})
        );
      })
    );
  };
}

export function groupByGaid() {
  return function(
    source
  ) {
    return source.pipe(
      mergeMap(function(data) {
        return of(
          data.reduce((acc, curr) => {
            const id = curr.GA_id
            acc[id.toString()] = curr
            return acc
          }, {})
        )
      })
    )
  }
}

const groupTaxoByOid = () => {
  return function (
    source
  ) {
    return source.pipe(
      mergeMap(function(taxo){
        return of(
          taxo.reduce((acc, curr) => {
            if (set_taxo_rank.has(curr.rank)) {
              if (acc.hasOwnProperty(curr.O_id)) {
                acc[curr.O_id][curr.rank] = curr
              } else {
                acc[curr.O_id] = {}
                acc[curr.O_id][curr.rank] = curr
              }
            }
            return acc
          }, {})
        )
      })
    )
  }
}

const groupMetaByOid = () => {
  return function (
    source
  ) {
    return source.pipe(
      mergeMap(function(meta){
        return of(
          meta.reduce((acc, curr) => {
            if (acc.hasOwnProperty(curr.O_id)) {
              if (acc[curr.O_id][curr.metadata_harmonized_name] === undefined) {
                  acc[curr.O_id][curr.metadata_harmonized_name] = []
              }
              acc[curr.O_id][curr.metadata_harmonized_name].push(curr);
            } else {
              acc[curr.O_id] = {};
              acc[curr.O_id][curr.metadata_harmonized_name] = []
              acc[curr.O_id][curr.metadata_harmonized_name].push(curr);
            }
            return acc
          }, {})
        );
      })
    );
  };
}

const getGenomeCartsById = () => {
    return function (
      source
    ) {
        return source.pipe(
            mergeMap(function (flat_genome_carts) {
                return of(
                    flat_genome_carts.reduce(function (genome_carts_by_oid, flat_gc) {
                        const {O_id, cart_name} = flat_gc;
                        if (genome_carts_by_oid.hasOwnProperty(O_id)) {
                            genome_carts_by_oid[O_id].push(cart_name);
                        } else {
                            genome_carts_by_oid[O_id] = [cart_name];
                        }
                        return genome_carts_by_oid;
                    }, {})
                );
            })
        );
    };
}

const getSequenceCartsById = () => {
    return function (
      source
    ) {
        return source.pipe(
            mergeMap(function (flat_sequence_carts) {
                return of(
                    flat_sequence_carts.reduce(function (sequence_carts_by_sid, flat_sc) {
                        const {S_id, cart_name} = flat_sc;
                        if (sequence_carts_by_sid.hasOwnProperty(S_id)) {
                            sequence_carts_by_sid[S_id].push(cart_name);
                        } else {
                            sequence_carts_by_sid[S_id] = [cart_name];
                        }
                        return sequence_carts_by_sid;
                    }, {})
                );
            })
        );
    };
}