//

// D3 import
import { cluster } from "d3-hierarchy";
import { dispatch } from "d3-dispatch";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { select } from "d3-selection";
import "d3-transition";
import {
  Phylogram,
  Phylotree,
  CircleCrossSymbol,
  SpeciesClusterLabel,
  SelectButton
} from "../utils/bio-viz";
import alertBox from "./alert-box.js";
import matrixFunction from "./matrix-table.js";
import DropdownButton from "./dropdown-button.js";

const species_tree_component = Phylotree();
const phylo_node_component = CircleCrossSymbol();
const node_label_component = SpeciesClusterLabel();

// tree layouts
const phylogram_layout = Phylogram();
const cladogram_layout = cluster().separation(function() {
  return 1;
});
let tree_layout = phylogram_layout;

export default function() {
  const select_buttons = SelectButton();
  const dropdown_button_component = DropdownButton();
  const alert_box_component = alertBox();
  const matrix_component = matrixFunction();

  /**********
   * Public *
   **********/
  exports.dispatcher = dispatch("show-cluster-tree");

  function exports(selection, width, rank, limit_nb_genomes) {
    var colors = scaleOrdinal(schemeCategory10); //schemeCategory20b

    selection.each(function(_data, i) {
      var container = select(this);

      // JOIN DATA
      var result = container.selectAll("div.result").data(_data);

      // ENTER
      var result_enter = result
        .enter()
        .append("div")
        .classed("result", true);

      result_enter
        .append("div")
        .style("margin-top", "15px")
        .classed("alert-container", true);

      var divSpeciesTree = result_enter
        .append("div")
        .classed("species-tree", true);

      var tree_controls = divSpeciesTree
        .append("div")
        .classed("tree-controls container-fluid", true)
        .append("form")
        .classed("form-inline", true);

      // UPDATE
      var result_update = result.merge(result_enter);

      // EXIT
      result.exit().remove();

      /*********************************
       * SELECT COLOR PARAMETER WIDGET
       ********************************/
      result_update
        .select("form.form-inline")
        .datum(function(d) {
          var tree_layout_button = {
            id: null,
            value: "tree-layout_select",
            label: "Layout ",
            select_class:
              "custom-select form-control mb-2 mr-sm-2 mb-sm-0 tree-layout_select",
            selected_option_index: tree_layout.name === "phylogram" ? 0 : 1,
            options: [
              {
                name: "Phylogram",
                value: "phylogram",
                group: "Tree",
                selected: true
              },
              {
                name: "Cladogram",
                value: "cladogram",
                group: "Tree",
                selected: false
              }
            ],
            eventHandlers: {
              selectOption: function() {
                updateSpeciesTree(
                  result_update,
                  width,
                  //rank,
                  colors,
                  //tree_layout,
                  limit_nb_genomes
                );
              }
            }
          };

          if (!d.grouping_options || d.grouping_options.length === 0) {
            return [];
          } else {
            return [
              tree_layout_button,
              {
                id: null,
                value: "select-color-button",
                label: "Color by ",
                select_class:
                  "custom-select form-control mb-2 mr-sm-2 mb-sm-0 select-color-button",
                selected_option_index: d.grouping_options.findIndex(function(
                  grp
                ) {
                  return grp === rank;
                }),
                options: d.grouping_options.map(function(grp) {
                  var type = grp.type;
                  var select_type = function() {
                    switch (type) {
                      case "MICGC":
                        return "Genome Cluster";
                        break;
                      case "MICOID":
                        return "Microscope Organism Id";
                        break;
                      default:
                        return "NCBI Taxonomy";
                    }
                  };
                  return {
                    name: type.charAt(0).toUpperCase() + type.slice(1),
                    value: type,
                    level: grp.level,
                    group: select_type()
                  };
                }),
                eventHandlers: {
                  selectOption: function() {
                    updateSpeciesTree(
                      result_update,
                      width,
                      //rank,
                      colors,
                      //tree_layout,
                      limit_nb_genomes
                    );
                  }
                }
              }
            ];
          }
        })
        .call(select_buttons);

      /**********
       * BUTTON *
       **********/
      result_update
        .select("form.form-inline")
        .datum(function(d) {
          if (d.oids.length >= 3) {
            return [
              {
                id: "export-btn",
                // label  : "Export",
                label: '<i class="fa fa-download"></i>',
                options: [
                  {
                    label: "Matrix (.tsv)",
                    id: "matrix",
                    click_cb: function() {
                      d.eventHandlers.exportTsvMatrix(d);
                    }
                  },
                  {
                    label: "Pairwise distances (.tsv)",
                    id: "distance",
                    click_cb: function() {
                      d.eventHandlers.exportTsvDistance(d);
                    }
                  },
                  {
                    label: "Tree (.svg)",
                    id: "tree",
                    click_cb: function() {
                      const svg_node = result_update.select("svg").node();
                      d.eventHandlers.exportSvgTree(svg_node);
                    }
                  },
                  {
                    label: "Tree (.nwk)",
                    id: "newick_tree",
                    click_cb: function() {
                      d.eventHandlers.exportNewickTree(d);
                    }
                  },
                  {
                    label: "Tree taxid only (.nwk)",
                    id: "newick_taxid_tree",
                    click_cb: function() {
                      d.eventHandlers.exportNewickTree(d, true);
                    }
                  }
                ]
              }
            ];
          } else {
            return [];
          }
        })
        .call(dropdown_button_component);

      // ALERT BOX
      var alert_box = result_update
        .select("div.alert-container")
        .datum(function(d) {
          return d.alert_box || [];
        })
        .call(alert_box_component);

      /****************
       * Species Tree *
       ****************/

      updateSpeciesTree(
        result_update.select("div.species-tree"),
        width,
        //rank,
        colors,
        //tree_layout,
        limit_nb_genomes
      );
    });
  }

  function updateSpeciesTree(
    container,
    width,
    //rank,
    colors,
    //tree_layout,
    limit_nb_genomes
  ) {
    //tree_layout = getTreeLayout(select_tree_layout.get(0));
    var rank;
    //var layout_type;
    var species_tree = container
      .selectAll("svg.species-tree")
      .data(function(d) {
        if (d.species_tree && d.alert_box.length < 1) {
          // Need to rewrite this part, this is very dirty.
          // Need a centralized select button data model.
          // In this case, I use the html to store the data.
          const layout_type = $(container.node())
            .find(".tree-layout_select option")
            .filter(function() {
              return this.selected;
            })
            .get(0).value;

          tree_layout =
            layout_type === "cladogram" ? cladogram_layout : phylogram_layout;
          rank = $(container.node())
            .find(".select-color-button option")
            .filter(function() {
              return this.selected;
            })
            .get(0).value;
          return [d.species_tree];
        } else {
          return [];
        }
      });

    var sp_tree_enter = species_tree
      .enter()
      .append("svg")
      .classed("species-tree", true)
      .style("font-size", "11px")
      .style("font-family", "monospace");

    species_tree.exit().remove();

    var sp_tree_update = sp_tree_enter.merge(species_tree);

    node_label_component
      .color(colors)
      .rank(rank)
      .nb_genomes_max(limit_nb_genomes);

    sp_tree_update.call(
      species_tree_component,
      width,
      900,
      phylo_node_component,
      node_label_component,
      tree_layout
    );

    // Attach event handler
    node_label_component.dispatcher.on("node_label_click", function(d, oid) {
      var index_of_species_cluster = d.data.taxon.grouping.findIndex(function(
        grp
      ) {
        return grp.type === "MICGC";
      });
      var group = d.data.taxon.grouping[index_of_species_cluster];
      if (group.count > 1 && group.count <= limit_nb_genomes) {
        exports.dispatcher.call("show-cluster-tree", this, group, oid);
      } else {
        return;
      }
    });
  }

  function getTreeLayout(select_html) {
    var tree_layout_selection = select(select_html)
      .selectAll("optgroup")
      .selectAll("option")
      .filter(function(d) {
        return this.selected;
      });

    var layout_type = tree_layout_selection.node().value;
    switch (layout_type) {
      case "phylogram":
        return phylogram_layout;
        break;

      case "cladogram":
        return cladogram_layout;
        break;
    }
    return phylogram_layout;
  }

  return exports;
}
