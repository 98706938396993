import * as micLib from "microscope";
import { select, selectAll } from "d3-selection";

document.addEventListener("DOMContentLoaded", function(event) {
  const singleHtmlContainer = select("#div3  > .card-body").node();
  const biobListId = "my-single-selector-genome";
  const singleOrganismSelector = micLib.organismSelector(false);
  singleOrganismSelector(biobListId, 12, 500, singleHtmlContainer);
  singleOrganismSelector.onBiobListChange(function(state) {
    const [org, ...rest] = micLib.getInSelectionBiobs(state, biobListId);
    if (org) {
      const { id: oid } = org;
      const newPathname = "../mage/viewer.php";
      const href = newPathname + "?O_id=" + oid;
      window.location.assign(href);
    }
  });
});
