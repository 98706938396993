// 
import {combineReducers} from "redux";
import {reducerInit} from "./reducer-init";
import {taxonomyInit} from "./taxonomy";
import {metadataInit} from "./metadata";
import {distanceInit} from "./distance";
import {genomeCartsInit} from "./genome-carts";
import {sequenceCartsInit} from "./sequence-carts";

export const speciesMetadataName = "species_metadata";
export const species_metadata = combineReducers(metadataInit(speciesMetadataName));

export const strainMetadataName = "strain_metadata";
export const strain_metadata = combineReducers(metadataInit(strainMetadataName));

export const refseqOrganismName = "refseq_organism";
export const refseq_organism = combineReducers(reducerInit(refseqOrganismName));

export const pkgdbMyOrganismName = "pkgdb_my_organism";
export const pkgdb_my_organism = combineReducers(reducerInit(pkgdbMyOrganismName));

export const pkgdbOrganismName = "pkgdb_organism";
export const pkgdb_organism = combineReducers(reducerInit(pkgdbOrganismName));

export const pkgdbSequenceName = "pkgdb_sequence";
export const pkgdb_sequence = combineReducers(reducerInit(pkgdbSequenceName));

export const refseqSequenceName = "refseq_sequence";
export const refseq_sequence = combineReducers(reducerInit(refseqSequenceName));

export const favoriteGenomesName = "favorite_genomes";
export const favorite_genomes = combineReducers(reducerInit(favoriteGenomesName))

export const pkgdbTaxoName = "pkgdb_taxonomy";
export const pkgdb_taxonomy = combineReducers(taxonomyInit(pkgdbTaxoName));

export const refseqTaxoName = "refseq_taxonomy";
export const refseq_taxonomy = combineReducers(taxonomyInit(refseqTaxoName));

export const micgcName ="micgc"
export const micgc = combineReducers(reducerInit(micgcName))

export const micgcByOcidName = "micgc_by_ocid";
export const micgc_by_ocid = combineReducers(reducerInit(micgcByOcidName));

export const distanceName = "distance";
export const distance = combineReducers(distanceInit(distanceName));

export const publicGenomeName = "public_genome";
export const public_genome = combineReducers(reducerInit(publicGenomeName))

export const genomeCartsName = "genome_carts";
export const genome_carts = combineReducers(genomeCartsInit(genomeCartsName))

export const sequenceCartsName = "sequence_carts";
export const sequence_carts = combineReducers(sequenceCartsInit(sequenceCartsName))