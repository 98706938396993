// 
// type

// Code start here
export const FETCH_TYPE = "FETCH";
export const SUCCESS_FETCH_TYPE = "SUCCESS";
export const FAILURE_FETCH_TYPE = "FAILURE";
export const BIOB_LIST_FETCH_TYPE= "BIOB_LIST_FETCH";
export const BIOB_LIST_SUCCESS_FETCH_TYPE= "BIOB_LIST_SUCCESS";
export const BIOB_LIST_FAILURE_FETCH_TYPE= "BIOB_LIST_FAILURE";

export function fetchAction(
  name,
  param
) {
  return {
    type: FETCH_TYPE,
    id: name,
    name,
    param
  };
}

export function successAction(
  name,
  param,
  data
) {
  return {
    type: SUCCESS_FETCH_TYPE,
    id: name,
    name,
    param,
    data
  };
}

export function failureAction(
  name,
  error
) {
  return {
    type: FAILURE_FETCH_TYPE,
    id: name,
    name,
    error
  };
}

export function fetchBiobListAction(
  id,
  name,
  param
) {
  return {
    type: BIOB_LIST_FETCH_TYPE,
    id,
    name,
    param
  };
}

export function successBiobListAction(
  id,
  name,
  param,
  data
) {
  return {
    type: BIOB_LIST_SUCCESS_FETCH_TYPE,
    id,
    name,
    param,
    data
  };
}

export function failureBiobListAction(
  id,
  name,
  error
) {
  return {
    type: BIOB_LIST_FAILURE_FETCH_TYPE,
    id,
    name,
    error
  };
}
