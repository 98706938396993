//

//import
import {getCache, getMicFetch} from "./fetch-cache";
import {hasAll} from "./mic-fetch";
import {notExistPublicGenomeException} from "../utils/exception";

// Code start here
/**
 * Get the public genome cached data
 *
 * @export
 * @param {PublicGenomeState} state
 * @returns {PublicGenomeCache}
 */
export function getPublicGenomeCache(
  state
) {
  return getCache(state);
}
/**
 * Has the public genome cache all the data
 *
 * @export
 * @param {PublicGenomeState} state
 * @param {string} id Identifier of the public genome biob list
 * @returns {boolean}
 */
export function hasAllPublicGenome(
  state,
  id
) {
  return hasAll(getMicFetch(state), id);
}

/**
 * Get a public genome object by id
 *
 * @export
 * @param {PublicGenomeState} state
 * @param {string} id
 * @returns
 */
export function getPublicGenomeById(state, id) {
  const cache = getPublicGenomeCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistPublicGenomeException(id);
  }
}

export function getListPublicGenome(state) {
  const cache = getPublicGenomeCache(state);

  return Object.values(cache).reduce(function(
    publicGenomeList,
    pg
  ) {
    const id_str = pg.GA_id.toString();
    publicGenomeList[id_str] = {
      id: id_str,
      oid: id_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return publicGenomeList;
  },
  {});
}
export function getStringifyPublicGenomeById(
  state,
  id
) {
  const pg = getPublicGenomeById(state, id);
  return pg.name;
}
