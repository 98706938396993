import "d3-transition";
import {select} from "d3-selection";

export default function(selection) {
  //var sp_tree = species_tree();

  function exports(selection) {
    selection.each(function(d, i) {
      var container = select(this);

      var alert_frame = container.selectAll("div.cadre_alert").data(d);
      // Exit
      alert_frame.exit().remove();

      // Enter
      var alert_frame_enter = alert_frame
        .enter()
        .append("div")
        .classed("cadre_alert", true);

      alert_frame_enter.append("text").classed("text_alert", true);

      // Update
      var alert_frame_update = alert_frame
        .merge(alert_frame_enter)
        .select("text.text_alert")
        .text(function(d) {
          return d;
        });

      // END CREATE ALERT BOX
    });
  }

  return exports;
}
