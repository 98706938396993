//

// Import
import { select, selectAll, event } from "d3-selection";

// custom d3 component
import { SimpleSelect } from "../utils/bio-viz";

export default function() {
  const filter_criteria_component = SimpleSelect();

  function exports(selection, getModalId) {
    selection.each(function(_data) {
      const root = select(this);
      const advanced_filter = root.selectAll("div.advanced-filter").data(_data);

      // EXIT
      advanced_filter.exit().remove;
      // ENTER
      const advanced_filter_e = advanced_filter
        .enter()
        .append("div")
        .classed("search-form advanced-filter", true);

      const search_form_e = advanced_filter_e
        .append("form")
        .classed("form-inline search", true)
        .style("width", "100%");

      var criteria_select_e = search_form_e
        .append("div")
        .classed("col-sm-auto px-0 filter-criteria", true);

      search_form_e
        .append("div")
        .classed("col pl-1 pr-0 mr-auto", true)
        .append("input")
        .style("width", "100%")
        .attr("type", "search")
        .attr("autocomplete", "off")
        .classed("form-control", true);

      const row_filters = advanced_filter_e
        .append("div")
        .classed("row justify-content-between mt-1", true);

      row_filters
        .append("div")
        .classed("filters mt-1 col", true)
        .append("div")
        .classed("filters-badge", true);

      row_filters.append("div").classed("grouping-selector col-auto", true);

      // UPDATE
      const advanced_filter_u = advanced_filter.merge(advanced_filter_e);

      // The filters
      const filter_criteria_u = advanced_filter_u
        .select("div.filter-criteria")
        .datum(function(d) {
          if (d.filter_criteria) {
            return [d.filter_criteria];
          } else {
            return [];
          }
        })
        .call(filter_criteria_component);

      // The input search
      const input_search_selection_u = advanced_filter_u
        .select(".search input")
        .attr("id", getSearchInputId)
        .each(function(d) {
          const $input = $(this);
          const closure_data = d;
          const option_nodes = filter_criteria_u.selectAll("option").nodes();
          const selected_option_node = option_nodes.filter(elem => {
            return elem.selected;
          });
          const selected_value = select(selected_option_node[0]).data()[0]
            .value;
          if (d.suggestion_engine.hasOwnProperty(selected_value)) {
            const suggestion_engine = d.suggestion_engine[selected_value];
            $input.typeahead("destroy");
            $input.typeahead(
              {
                hint: false,
                highlight: true,
                minLength: 0
              },
              suggestion_engine
            );
            $input.off("typeahead:select");
            $input.bind("typeahead:select", function(ev, suggestion) {
              const new_suggestion = { ...suggestion };
              new_suggestion.filter_type = selected_value;
              new_suggestion.matching_type = "exact";
              closure_data.eventHandlers.selectSuggestion(new_suggestion);
              $input.typeahead("val", "");
            });
            // When the suggestion are render, attach event handler to
            // the custom search.
            $input.off("typeahead:render");
            $input.bind("typeahead:render", (ev, suggestion, flag, name) => {
              const query = ev.target.value;
              const tt_menu = select(this.parentElement).select(".tt-menu");
              const criteria = closure_data.filter_criteria.options.filter(
                opt => opt.selected
              )[0].value;

              // Check if it has several suggestion engine dataset
              // Only display all when several.
              let all_search = addFindInAllDatasetSuggestion(
                query,
                tt_menu,
                closure_data.biob_name,
                criteria,
                closure_data.id,
                closure_data.suggestion_engine,
                closure_data.type,
                closure_data.eventHandlers.addKeywordFilter
              );

              select(".tt-custom-search-" + name).on("click", () => {
                closure_data.eventHandlers.addKeywordFilter({
                  query,
                  type: selected_value,
                  subtype: name,
                  matching_type: "fuzzy"
                });
                $input.typeahead("val", "");
              });
            });
            $input.off("typeahead:change");
            $input.bind("typeahead:change", ev => {
              const query = ev.target.value;
              const tt_menu = select(this.parentElement).select(".tt-menu");

              const criteria = closure_data.filter_criteria.options.filter(
                opt => opt.selected
              )[0].value;
              let all_search = addFindInAllDatasetSuggestion(
                query,
                tt_menu,
                closure_data.biob_name,
                criteria,
                closure_data.id,
                closure_data.suggestion_engine,
                closure_data.type,
                closure_data.eventHandlers.addKeywordFilter
              );
            });
          } else {
            throw "No suggestion engine for this criteria";
          }
        })
        .attr("placeholder", function(d) {
          return d.placeholder;
        })
        .attr("aria-label", function(d) {
          return d.placeholder;
        })
        .on("keypress", function(d) {
          if (event.keyCode === 13) {
            const $input = $(this);
            event.preventDefault();
            var elem = select("#" + getSearchInputId(d)).node();
            var query = elem.value;
            const type = d.filter_criteria.options.reduce((accum, criteria) => {
              return criteria.selected ? criteria.value : accum;
            }, null);
            if (query !== "") {
              d.eventHandlers.addKeywordFilter({
                query,
                type,
                subtype: null,
                matching_type: "fuzzy"
              });
              $input.typeahead("val", "");
            }
          }
        })
        .on("input", function(d) {
          event.stopPropagation();
        });

      // The grouping select

      const filter_badge = advanced_filter_u
        .select("div.filters-badge")
        .selectAll("span.badge")
        .data(function(d) {
          return d.filters ? d.filters : [];
        });

      filter_badge.exit().remove();
      const filter_badge_e = filter_badge
        .enter()
        .append("span")
        .classed("badge badge-dark bg-microscope-dark mr-1", true);

      filter_badge_e.append("span").classed("badge-text", true);

      filter_badge_e
        .append("i")
        .classed("fa fa-times", true)
        .classed("pointer", "cursor")
        .attr("aria-hidden", true);

      const filter_badge_u = filter_badge_e.merge(filter_badge);

      filter_badge_u.select("span.badge-text").text(function(d) {
        return d.name + " (" + d.size + ")";
      });

      filter_badge_u.select("i").on("click", function(d) {
        d.eventHandlers.removeFilter();
      });

      //=======================
    });
  }
  function getSearchInputId(d) {
    return d.id + "_search_input";
  }
  function getCriteriaSelectId(d) {
    return d.id + "_criteria_select";
  }
  function addFindInAllDatasetSuggestion(
    query,
    tt_menu,
    biob_name,
    value,
    data_id,
    suggestion_engine,
    selector_type,
    addKeywordFilter
  ) {
    let all_search = tt_menu.select(".tt-custom-search-all");

    if (suggestion_engine[value].length > 1) {
      if (all_search.empty()) {
        all_search = tt_menu
          .insert("div", ":first-child")
          .classed("tt-suggestion tt-selectable tt-custom-search-all", true);
        all_search
          .append("i")
          .classed("fa-search", d => selector_type === "in_selection_false")
          .classed("fa-filter", d => selector_type === "in_selection_true")
          .classed("fa", true)
          .attr("aria-hidden", "true");

        all_search.append("span").classed("text-secondary", true);
      }

      const verb = selector_type === "in_selection_false" ? "Find" : "Keep";

      all_search
        .select("span")
        .text(`${verb} ${biob_name}s that contain "${query}" in the whole ${value}`);
      all_search.on("click", () => {
        addKeywordFilter({
          query,
          type: value,
          matching_type: "fuzzy"
        });
      });
    } else {
      all_search.remove();
    }

    return all_search;
  }

  return exports;
}
