/**
 * Based on d3-hierarchy from Mike Bostock
 */
import {utils} from "phylotree-utils";

export default function() {
  var dx = 1;
  var dy = 1;
  var separation = defaultSeparation;
  var phylo_utils = utils();
  var nodeSize = false;

  function phylogram(root) {
    var previousNode;
    var x = 0;
    var max_depth = 0;

    // calculate node depth
    root.eachBefore(function(node) {
	    // If a children has a negative length, truncate it to 0
	    var node_length = node.data.length < 0 ? 0 : node.data.length;
	    node.depth = node.parent ? node.parent.depth + node_length : node_length;
	    max_depth = node.depth > max_depth ? node.depth : max_depth;
	    if (node.children) {
	      for (var i of [0, 1]) {
	        let d = node.children[i].data.length;
	        if (d < 0) {
	          node.children[i].data.length = 0;
	          node.children[1-i].data.length -= d; // Add the absolute value
	        }
	      }
	    }
	    var node_length = node.data.length;
    });

    root.eachAfter(function(node) {
      var children = node.children;
      if (children) {
        node.x = meanX(children);
        node.y = node.depth;
      } else {
        node.x = previousNode ? (x += separation(node, previousNode)) : 0;
        node.y = node.depth;
        previousNode = node;
      }
    });
    var left_node = phylo_utils.reduceAfterLeft(root, getLeafNode, null);
    var right_node = phylo_utils.reduceAfterRight(root, getLeafNode, null);
    var x0 = left_node.x - separation(left_node, right_node) / 2;
    var x1 = right_node.x + separation(right_node, left_node) / 2;

    // Second walk, normalizing x & y to the desired size.
    return root.eachAfter(
      nodeSize
        ? function(node) {
            node.x = (node.x - root.x) * dx;
            node.y = (node.y / max_depth) * dy;
          }
        : function(node) {
            node.x = ((node.x - x0) / (x1 - x0)) * dx;
            node.y = (node.y / max_depth) * dy;
          }
    );
  }

  /**
   *
   */
  phylogram.size = function(x) {
    return arguments.length
      ? ((dx = +x[0]), (dy = +x[1]), phylogram)
      : [dx, dy];
  };

  phylogram.nodeSize = function(x) {
    return arguments.length
      ? ((nodeSize = true), (dx = +x[0]), (dy = +x[1]), phylogram)
      : nodeSize
        ? [dx, dy]
        : null;
  };

  phylogram.separation = function(x) {
    return arguments.length ? ((separation = x), phylogram) : separation;
  };

  return phylogram;
}

function defaultSeparation(a, b) {
  return 1;
}

function meanX(children) {
  return children.reduce(meanXReduce, 0) / children.length;
}

function meanXReduce(x, c) {
  return x + c.x;
}

function getLeafNode(acc, node) {
  if (!node.children) {
    return node;
  } else {
    return acc;
  }
}

function maxY(children) {
  return 1 + children.reduce(maxYReduce, 0);
}

function maxYReduce(y, c) {
  return Math.max(y, c.y);
}
