// 
// import
import {catchError, mergeMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
// Action
import {filterNotInCache, groupBy} from "./cache";
import {cacheData} from "../action/cache";

// Reducers
import {set_taxo_rank} from "../reducer/taxonomic-rank";
// Epics
import {cacheEpic, checkResponse, fetchData, isFetchTypeEpic} from "./mic-fetch";
import {failureAction} from "../action/mic-fetch";
import {paramEpicMap} from "./index";
import {pkgdbTaxoName, refseqTaxoName} from "../reducer/reducer-declaration";

// Code start here



export function fetchRefseqTaxonomyEpic(action$, state$) {
    return fetchTaxonomyEpic(action$, state$, refseqTaxoName);
}

export function cacheRefseqTaxonomyEpic(action$, state$) {
    return cacheEpic(action$, state$, refseqTaxoName);
}

export function fetchPkgdbTaxonomyEpic(action$, state$) {
    return fetchTaxonomyEpic(action$, state$, pkgdbTaxoName,);
}

export function cachePkgdbTaxonomyEpic(action$, state$) {
    return cacheEpic(action$, state$, pkgdbTaxoName);
}

/**
 *
 *
 * @export
 * @param {ActionsObservable<TaxonomyFetchAction>} action$
 * @param {*} state$
 * @param {string} name
 * @returns {*}
 */
export function fetchTaxonomyEpic(
  action$,
  state$,
  name
) {
  const paramFuncName = paramEpicMap.get(name)
  return action$.pipe(
    isFetchTypeEpic(name),
    mergeMap(action => {
      const all_param = action.param;
      return of(action).pipe(
        mergeMap(action => {
          return of(action).pipe(
            withLatestFrom(state$),
            filterNotInCache(paramFuncName.getCacheFunc, paramFuncName.hasAllFunc, name),
            mergeMap(param =>
              of(param).pipe(
                fetchData(paramFuncName.requestType),
                mergeMap(raw_data =>
                  of(raw_data.map(paramFuncName.toObjectFunc))
                ),
                // Filter the taxonomy that are not in the defined rank
                mergeMap(taxo => {
                  return of(taxo.filter(t => set_taxo_rank.has(t.rank)));
                }),
                groupBy(name),
                checkResponse(param),
                mergeMap(orga =>
                  of(cacheData(action.name, all_param, orga, name))
                )
              )
            )
          );
        }),
        catchError(error => of(failureAction(name,error)))
      );
    })
  );
}
