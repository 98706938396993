//

// import
import {notExistOrganismException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

// code start here
export const getOrganismCache = function(state) {
  return state.cache;
};

export function getOrganismById(
  state,
  id
) {
  const cache = getOrganismCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistOrganismException(id);
  }
}

export function getListOrganism(state) {
  const cache = getOrganismCache(state);
  return Object.values(cache).reduce(function(
    organismList,
    org
  ) {
    const oid_str = org.O_id.toString();
    organismList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return organismList;
  },
  {});
}

export function getStringifyOrganismById(state, oid) {
  const org = getOrganismById(state, oid);
  return stringify(org);
}

export function stringify(org) {
  return `${org.O_name} ${org.O_strain}`;
}

export function getStringifyMyOrganismById(state, oid) {
  const org = getOrganismById(state, oid);
  return stringifyMyOrganism(org);
}

//Nom affiché dans le select
export function stringifyMyOrganism(org) {
  return `${org.O_name} ${org.O_strain} ${org.O_access}`;
}

export const getMicFetchOrganism = (state) =>
  state.mic_fetch_state;

export function organismHasAll(state, id) {
  const fetchState = getMicFetchOrganism(state);
  return fromMicFetch.hasAll(fetchState, id);
}

export const isFetchingOrganism = (state, id) =>
  fromMicFetch.isFetching(getMicFetchOrganism(state), id);
