// 

//import
import {
  fetchBiobListAction,
  successBiobListAction
} from "./mic-fetch";
import {name} from "../reducer/biob-list";
// Code start Here

/*************
 * Action types
 **************/
export const BIOBS_IN_SELECTION_ADD = "BIOBS_IN_SELECTION_ADD";
export const BIOBS_IN_SELECTION_REMOVE = "BIOBS_IN_SELECTION_REMOVE";
export const BIOBS_IN_SELECTION_SET = "BIOBS_IN_SELECTION_SET";
export const BIOB_GROUPING_SET = "BIOB_GROUPING_SET";
export const BIOB_LIST_CREATE = "BIOB_LIST_CREATE";
export const BIOB_LIST_UPDATE = "BIOB_LIST_UPDATE";
export const BIOB_FILTER_ADD = "BIOB_FILTER_ADD";
export const BIOB_FILTER_REMOVE = "BIOB_FILTER_REMOVE";
export const BIOB_FILTER_CRITERIA_CHANGE = "BIOB_FILTER_CRITERIA_CHANGE";
export const BIOB_LISTS_STATE_HISTORY_PUSH = "BIOB_LISTS_STATE_HISTORY_PUSH";
export const BIOB_LISTS_SET_INITIAL_STATE = "BIOB_LISTS_SET_INITIAL_STATE";
export const BIOB_LISTS_SET_CANCEL_STATE = "BIOB_LISTS_SET_CANCEL_STATE";
export const BIOB_LISTS_UNDO = "BIOB_LISTS_UNDO";
export const SUGGESTION_ENGINE_PROCESSING_SET =
  "SUGGESTION_ENGINE_PROCESSING_SET";
export const SELECTED_BIOBS_RANGE = "SELECTED_BIOBS_RANGE";
export const BIOB_FLAG_SET = "BIOB_FLAG_SET";
export const BIOBS_SELECT_GENOME_CART_ADD = "BIOBS_SELECT_GENOME_CART_ADD";
export const BIOBS_SELECT_SEQUENCE_CART_ADD = "BIOBS_SELECT_SEQUENCE_CART_ADD";


/**
 *
 * Action creator to set biobs in selection.
 * The other biobs will be removed from the selection.
 * @export
 * @param {string[]} biob_ids
 * @param {string} biob_list_id
 * @returns
 */
export function setBiobsInSelection(biob_ids, biob_list_id) {
  return {
    type: BIOBS_IN_SELECTION_SET,
    biob_ids,
    biob_list_id
  };
}

/**
 * Action that add biobs in selection.
 * Don't touch the other biobs.
 *
 * @export
 * @param {string[]} biob_ids
 * @param {string} biob_list_id
 * @returns {BiobsInSelectionAdd}
 */
export function addBiobsInSelection(
  biob_ids,
  biob_list_id
) {
  return {
    type: BIOBS_IN_SELECTION_ADD,
    biob_ids,
    biob_list_id
  };
}

/**
 * Action that add biobs from a genome cart in selection.
 *
 * @export
 * @param biob_ids
 * @param {string} biob_list_id
 * @returns {BiobsInSelectionAdd}
 */
export function addGenomeCartInSelection(
  biob_ids,
  biob_list_id
) {
  return {
    type: BIOBS_SELECT_GENOME_CART_ADD,
    biob_ids,
    biob_list_id
  };
}

/**
 * Action that add biobs from a sequence cart in selection.
 *
 * @export
 * @param biob_ids
 * @param {string} biob_list_id
 * @returns {BiobsInSelectionAdd}
 */
export function addSequenceCartInSelection(
  biob_ids,
  biob_list_id
) {
  return {
    type: BIOBS_SELECT_SEQUENCE_CART_ADD,
    biob_ids,
    biob_list_id
  };
}

/**
 * Action that remove biobs from the selection
 *
 * @export
 * @param {string[]} biob_ids
 * @param {string} biob_list_id
 * @returns {BiobsInSelectionRemove}
 */
export function removeBiobsInSelection(
  biob_ids,
  biob_list_id
) {
  return {
    type: BIOBS_IN_SELECTION_REMOVE,
    biob_ids,
    biob_list_id
  };
}

/**
 * Action that set the biobs grouping criteria
 * that will be used to display biobs in the selector
 *
 * @export
 * @param {MicRank} rank
 * @param {string} biob_list_id
 * @param {BiobStatusType} status
 * @param {BiobStatusValues} status_value
 * @returns {BiobGroupingSet}
 */
export function setBiobGrouping(
  rank,
  biob_list_id,
  status,
  status_value
) {
  return {
    type: BIOB_GROUPING_SET,
    rank,
    biob_list_id,
    status,
    status_value
  };
}

/**
 * Action that set the biob select attribute (true/false)
 *
 * @export
 * @param {string[]} biob_ids
 * @param {string} biob_list_id
 * @param {boolean} value
 * @returns {BiobFlagSet}
 */
export function setBiobSelectedAttr(
  biob_ids,
  biob_list_id,
  value
) {
  return setBiobFlag(biob_ids, biob_list_id, {
    name: "selected_attr",
    value: value
  });
}

/**
 *
 * Action that create a new biob list
 * @export
 * @param {BiobListType} biob_type
 * @param {string} biob_list_id
 * @param {[number, number]} selected_biobs_range
 * @param {boolean} multiple
 * @param {number[]} preselected_biob_ids
 * @returns {BiobListCreate}
 */
export function createBiobList(
  biob_type,
  biob_list_id,
  selected_biobs_range,
  multiple,
  preselected_biob_ids
) {
  return {
    type: BIOB_LIST_CREATE,
    biob_type,
    biob_list_id,
    selected_biobs_range,
    multiple,
    preselected_biob_ids
  };
}

/**
 *
 *
 * @export
 * @param {BiobListType} biob_type
 * @param {string} biob_list_id
 * @param {ListBiobs} biobs
 * @returns {BiobListUpdate}
 */
export function updateBiobsList(
  biob_type,
  biob_list_id,
  biobs
) {
  return {
    type: BIOB_LIST_UPDATE,
    biob_list_id,
    biob_type,
    biobs
  };
}

/**
 *
 *
 * @export
 * @param {string} biob_list_id
 * @param {BiobStatusType} status
 * @param {BiobStatusValues} status_value
 * @param {string[]} filter_ids
 * @returns {BiobFilterAdd}
 */
export function addBiobFilters(
  biob_list_id,
  status,
  status_value,
  filter_ids
) {
  return {
    type: BIOB_FILTER_ADD,
    biob_list_id,
    status,
    status_value,
    filter_ids
  };
}

/**
 *
 *
 * @export
 * @param {string} biob_list_id
 * @param {BiobStatusType} status
 * @param {BiobStatusValues} status_value
 * @param {string[]} filter_ids
 * @returns {BiobFilterRemove}
 */
export function removeBiobFilters(
  biob_list_id,
  status,
  status_value,
  filter_ids
) {
  return {
    type: BIOB_FILTER_REMOVE,
    biob_list_id,
    status,
    status_value,
    filter_ids
  };
}

/**
 *
 *
 * @export
 * @param {string} biob_list_id
 * @param {BiobStatusType} status
 * @param {BiobStatusValues} status_value
 * @param {string} filter_criteria
 * @returns {BiobFilterCriteriaChange}
 */
export function changeBiobFilterCriteria(
  biob_list_id,
  status,
  status_value,
  filter_criteria
) {
  return {
    type: BIOB_FILTER_CRITERIA_CHANGE,
    biob_list_id,
    status,
    status_value,
    filter_criteria
  };
}

/**
 *
 *
 * @export
 * @param {BiobList} biobList
 * @param {string} biob_list_id
 * @returns {BiobListsStateToHistoryPush}
 */
export function pushBiobListsStateToHistory(
  biobList,
  biob_list_id
) {
  return {
    type: BIOB_LISTS_STATE_HISTORY_PUSH,
    biobList,
    biob_list_id
  };
}

/**
 *
 *
 * @export
 * @param {BiobList} biobList
 * @param {string} biob_list_id
 * @returns {BiobListsInitialState}
 */
export function setInitialStateToHistory(
  biobList,
  biob_list_id
) {
  return {
    type: BIOB_LISTS_SET_INITIAL_STATE,
    biobList,
    biob_list_id
  }
}

/**
 *
 *
 * @export
 * @param {BiobList} biobList
 * @param {string} biob_list_id
 * @returns {BiobListsCancelState}
 */
export function setCancelStateToHistory(
  biobList,
  biob_list_id
) {
  return {
    type: BIOB_LISTS_SET_CANCEL_STATE,
    biobList,
    biob_list_id
  }
}

/**
 *
 *
 * @export
 * @param {BiobLists} state
 * @param {string} biob_list_id
 * @returns {BiobListsUndo}
 */
export function undoBiobLists(
  state,
  biob_list_id
) {
  return {
    type: BIOB_LISTS_UNDO,
    state,
    biob_list_id
  };
}

/**
 *
 *
 * @export
 * @param {*} biob_list_id
 * @param {*} range
 * @returns
 */
export function setSelectedBiobRange(biob_list_id, range) {
  return {
    type: SELECTED_BIOBS_RANGE,
    biob_list_id,
    range
  };
}

/**
 *
 *
 * @export
 * @param {MicRequestValueParam} param
 * @param {BiobListType} biob_type
 * @param {string} biob_list_id
 * @param {boolean} multiple
 * @param {number[]} preselected_biob_ids
 * @returns {BiobListFetchAction}
 * 
 * [A link to another part of this documentation](#title)
 * 
 */
export function fetchBiobList(
  param,
  biob_type,
  biob_list_id,
  multiple,
  preselected_biob_ids
) {
  const fetch_action = fetchBiobListAction(biob_list_id, name, param);
  return {
    ...fetch_action,
    biob_type,
    biob_list_id,
    multiple,
    preselected_biob_ids
  };
}

/**
 *
 *
 * @export
 * @param {string} biob_list_id
 * @param {MicRequestValueParam} param
 * @param {ListBiobs} data
 * @returns {BiobListSuccessAction}
 */
export function fetchBiobListSuccess(
  biob_list_id,
  param,
  data
) {
  return successBiobListAction(biob_list_id, name, param, data);
}

/**
 *
 *
 * @param {string[]} biob_ids
 * @param {string} biob_list_id
 * @param {Flag} flag
 * @returns {BiobFlagSet}
 */
function setBiobFlag(
  biob_ids,
  biob_list_id,
  flag
) {
  return {
    type: BIOB_FLAG_SET,
    biob_ids,
    biob_list_id,
    flag
  };
}


