// 
// import
import {combineEpics} from "redux-observable";
import {cacheMicgcEpic, fetchMicgcEpic} from "./micgc";
import {fetchDistanceEpic} from "./distance";
import {addToBiobListHistory, createBiobListEpic, fetchBiobListEpic} from "./biob-lists";
import {cacheNjSpeciesTreeEpic, fetchNjSpeciesTreeByOcidEpic, fetchNjSpeciesTreeEpic} from "./nj-species-tree";

//Taxonomy
import {cachePkgdbTaxonomyEpic, fetchPkgdbTaxonomyEpic,
    cacheRefseqTaxonomyEpic, fetchRefseqTaxonomyEpic} from "./taxonomy";

import {
    favoriteGenomesHasAll, genomeCartsHasAll,
    sequenceCartsHasAll,
    getFavoriteGenomesCache, getGenomeCartsById, getGenomeCartsCache, getMicgcById, getMicgcByOcidCache, getMicgcCache,
    getSequenceCartsById, getSequenceCartsCache,
    getOrganismById,
    getOrganismCache, getPublicGenomeById, getPublicGenomeCache,
    getRefseqOrganismById,
    getRefseqOrganismCache,
    getRefseqSequenceById,
    getRefseqSequenceCache,
    getRefseqTaxonomyById,
    getRefseqTaxonomyCache,
    getSequenceById,
    getSequenceCache,
    getSpeciesMetadataById,
    getSpeciesMetadataCache,
    getStrainMetadataById,
    getStrainMetadataCache,
    getTaxonomyById,
    getTaxonomyCache, hasAllPublicGenome, micgcByOcidHasAll, micgcHasAll,
    myOrganismHasAll,
    organismHasAll,
    refseqOrganismHasAll,
    refseqSequenceHasAll,
    refseqTaxonomyHasAll,
    sequenceHasAll,
    speciesMetadataHasAll,
    strainMetadataHasAll,
    taxonomyHasAll
} from "../selector";

import {
    speciesMetadataName,
    strainMetadataName,
    refseqTaxoName,
    pkgdbTaxoName,
    favoriteGenomesName,
    refseqSequenceName,
    pkgdbSequenceName,
    refseqOrganismName,
    pkgdbOrganismName,
    pkgdbMyOrganismName,
    publicGenomeName,
    distanceName,
    micgcByOcidName,
    micgcName,
    genomeCartsName,
    sequenceCartsName
} from "../reducer/reducer-declaration";

import {
    cacheStrainMetadataEpic, fetchStrainMetadataEpic,
    cacheSpeciesMetadataEpic, fetchSpeciesMetadataEpic,
    cacheRefseqOrganism, fetchRefseqOrganismEpic,
    cachePkgdbMyOrganismEpic, fetchPkgdbMyOrganismEpic,
    cachePkgdbOrganismEpic, fetchPkgdbOrganismEpic,
    cacheFavoriteGenomes, fetchFavoriteGenomesEpic,
    cachePkgdbSequenceEpic, fetchPkgdbSequenceEpic,
    cacheRefseqSequence, fetchRefseqSequenceEpic,
    cachePublicGenomeEpic, fetchPublicGenomeEpic,
    cacheMicgcByOcidEpic, fetchMicgcByOcidEpic,
    cacheGenomeCartsEpic, fetchGenomeCartsEpic,
    cacheSequenceCartsEpic, fetchSequenceCartsEpic
} from "./fetch-cache";

import {
    toDistance,
    toFavoriteGenomes, toGenomeCart, toMetadata, toSequenceCart,
    toMicgc,
    toOrganism,
    toPublicGenome,
    toSequence,
    toTaxonomy
} from "./raw-to-object";

export const paramEpicMap = new Map([
    [strainMetadataName,
        {
            requestType: "strainmetadatabyoid",
            getCacheFunc: getStrainMetadataCache,
            getByIdFunc: getStrainMetadataById,
            hasAllFunc: strainMetadataHasAll,
            toObjectFunc: toMetadata,
        }
    ],
    [speciesMetadataName,
        {
            requestType: "speciesmetadatabyoid",
            getCacheFunc: getSpeciesMetadataCache,
            getByIdFunc: getSpeciesMetadataById,
            hasAllFunc: speciesMetadataHasAll,
            toObjectFunc: toMetadata,
        }
    ],
    [genomeCartsName,
        {
            requestType: "genomecarts",
            getCacheFunc: getGenomeCartsCache,
            getByIdFunc: getGenomeCartsById,
            hasAllFunc: genomeCartsHasAll,
            toObjectFunc: toGenomeCart,
        }
    ],
    [sequenceCartsName,
        {
            requestType: "sequencecarts",
            getCacheFunc: getSequenceCartsCache,
            getByIdFunc: getSequenceCartsById,
            hasAllFunc: sequenceCartsHasAll,
            toObjectFunc: toSequenceCart,
        }
    ],
    [pkgdbMyOrganismName,
        {
            requestType: "myorganism",
            getCacheFunc: getOrganismCache,
            getByIdFunc: getOrganismById,
            hasAllFunc: myOrganismHasAll,
            toObjectFunc: toOrganism,
        }
    ],
    [pkgdbOrganismName,
        {
            requestType: "organism",
            getCacheFunc: getOrganismCache,
            getByIdFunc: getOrganismById,
            hasAllFunc: organismHasAll,
            toObjectFunc: toOrganism,
        }
    ],
    [refseqOrganismName,
        {
            requestType: "refseqorganism",
            getCacheFunc: getRefseqOrganismCache,
            getByIdFunc: getRefseqOrganismById,
            hasAllFunc: refseqOrganismHasAll,
            toObjectFunc: toOrganism,
        }
    ],
    [pkgdbSequenceName,
        {
            requestType: "sequence",
            getCacheFunc: getSequenceCache,
            getByIdFunc: getSequenceById,
            hasAllFunc: sequenceHasAll,
            toObjectFunc: toSequence,
        }
    ],
    [refseqSequenceName,
        {
            requestType: "refseqsequence",
            getCacheFunc: getRefseqSequenceCache,
            getByIdFunc: getRefseqSequenceById,
            hasAllFunc: refseqSequenceHasAll,
            toObjectFunc: toSequence,
        }
    ],
    [favoriteGenomesName,
        {
            requestType: "favoritegenomes",
            getCacheFunc: getFavoriteGenomesCache,
            getByIdFunc: getFavoriteGenomesCache,
            hasAllFunc: favoriteGenomesHasAll,
            toObjectFunc: toFavoriteGenomes,
        }
    ],
    [pkgdbTaxoName,
        {
            requestType: "otaxonomylightbyoid",
            getCacheFunc: getTaxonomyCache,
            getByIdFunc: getTaxonomyById,
            hasAllFunc: taxonomyHasAll,
            toObjectFunc: toTaxonomy,
        }
    ],
    [refseqTaxoName,
        {
            requestType: "refseqtaxonomylight",
            getCacheFunc: getRefseqTaxonomyCache,
            getByIdFunc: getRefseqTaxonomyById,
            hasAllFunc: refseqTaxonomyHasAll,
            toObjectFunc: toTaxonomy,
        }
    ],
    [publicGenomeName,
        {
            requestType: "publicgenome",
            getCacheFunc: getPublicGenomeCache,
            getByIdFunc: getPublicGenomeById,
            hasAllFunc: hasAllPublicGenome,
            toObjectFunc: toPublicGenome,
        }
    ],
    [micgcName,
        {
            requestType: "speciesclusterbyoid",
            getCacheFunc: getMicgcCache,
            getByIdFunc: getMicgcById,
            hasAllFunc: micgcHasAll,
            toObjectFunc: toMicgc,
        }
    ],
    [micgcByOcidName,
        {
            requestType: "oidbyspeciescluster",
            getCacheFunc: getMicgcByOcidCache,
            getByIdFunc: getMicgcByOcidCache,
            hasAllFunc: micgcByOcidHasAll,
            toObjectFunc: toMicgc,
        }
    ],
    [distanceName,
        {
            requestType: "orgclustdistancebyoid",
            toObjectFunc: toDistance,
        }
    ]
])

const rootEpic = combineEpics(
  fetchNjSpeciesTreeEpic,
  fetchPkgdbOrganismEpic,
  fetchPkgdbMyOrganismEpic,
  cachePkgdbOrganismEpic,
  cachePkgdbMyOrganismEpic,
  fetchPkgdbTaxonomyEpic,
  fetchMicgcEpic,
  fetchStrainMetadataEpic,
  fetchSpeciesMetadataEpic,
  fetchMicgcByOcidEpic,
  fetchPkgdbSequenceEpic,
  createBiobListEpic,
  fetchBiobListEpic,
  fetchDistanceEpic,
  fetchNjSpeciesTreeByOcidEpic,
  cachePkgdbSequenceEpic,
  cacheMicgcEpic,
  cacheStrainMetadataEpic,
  cacheSpeciesMetadataEpic,
  cachePkgdbTaxonomyEpic,
  cacheMicgcByOcidEpic,
  cacheNjSpeciesTreeEpic,
  fetchPublicGenomeEpic,
  cachePublicGenomeEpic,
  fetchRefseqOrganismEpic,
  cacheRefseqOrganism,
  fetchRefseqSequenceEpic,
  cacheRefseqSequence,
  fetchRefseqTaxonomyEpic,
  cacheRefseqTaxonomyEpic,
  fetchFavoriteGenomesEpic,
  cacheFavoriteGenomes,
  addToBiobListHistory,
  cacheGenomeCartsEpic,
  fetchGenomeCartsEpic,
  cacheSequenceCartsEpic,
  fetchSequenceCartsEpic
);
export default rootEpic;
