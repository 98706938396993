// 

import {createNamedWrapperReducer} from "./utils";
import micFetch, * as fromMicFetch from "./mic-fetch";
import oidCache, * as fromOidCache from "./cache";

export function reducerInit(name) {
    const initialState = {
        type: name,
        cache: fromOidCache.initialState,
        mic_fetch_state: fromMicFetch.initialState
    };

    const micFetchReducer = createNamedWrapperReducer(
        micFetch,
        name,
        initialState.mic_fetch_state
    );
    const oidCacheReducer = createNamedWrapperReducer(
        oidCache,
        name,
        initialState.cache
    );

    function setType(state = initialState.type) {
        return state;
    }

    return {
        type: setType,
        cache: oidCacheReducer,
        mic_fetch_state: micFetchReducer
    };
}
