//

export function notExistException(
  id,
  message
) {
  return {
    name: "NotExistException",
    message,
    value: id
  };
}

export function notExistBiobListException(id) {
  return notExistException(id, `The list ${id} does not exist.`);
}

export function notExistListBiobException(biob_id, list_id) {
  return notExistException(biob_id, `The biob ${biob_id} does not exist in the list ${list_id}.`);
}

// Taxonomy
export function notExistTaxonomyException(id) {
  return notExistException(id, `No taxonomy found for the id ${id}.`);
}
export function notExistRankException(id, rank) {
  return notExistException(id, `No rank ${rank} found for the id ${id}.`);
}
// Organism
export function notExistOrganismException(id) {
  return notExistException(id, `No organism found for the id ${id}.`);
}
// Genome Cart
export function notExistGenomeCartException(id) {
  return notExistException(id, `No Genome Cart found for the id ${id}.`);
}
// Sequence Cart
export function notExistSequenceCartException(id) {
   return notExistException(id, `No Sequence Cart found for the id ${id}.`);
}
// Public Genome
export function notExistPublicGenomeException(id) {
  return notExistException(id, `No public genome found for the id ${id}.`);
}
// Sequence
export function notExistSequenceException(id) {
  return notExistException(id, `No sequence found for the id ${id}.`);
}
// MICGC
export function notExistMicgcException(id) {
  return notExistException(id, `No micgc found for the id ${id}.`);
}
// Metadata
export function notExistMetadataException(id) {
  return notExistException(id, `No strain metadata found for the id ${id}.`);
}
// Filters
export function notExistFilterException(id) {
  return notExistException(id, `No filter found for the id ${id}.`);
}

export function notExistNjSpeciesTreeException(id) {
  return notExistException(id, `No nj species tree found for the id ${id}.`);
}
