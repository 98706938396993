// 


// import
import {CACHE_TYPE} from "../action/cache";

export const initialState = {};

export default function cache(
    state = initialState,
    action
) {
  switch (action.type) {
    case CACHE_TYPE:
      if (Object.keys(action.data_to_cache).length > 0) {
        let array = []
        for (let biob of Object.values(action.data_to_cache)) {
          for (let keys of Object.values(biob)) {
            keys.map(key=>{
              if (!array.find((item) => item.name === key.metadata_name))
                array.push({name: key.metadata_name, harmonized_name: key.metadata_harmonized_name})
            })
          }
        }
        return array
      } else {
        return state
      }

    default:
      return state;
  }
}
