// 


export const taxo_rank = [
  "superkingdom",
  "phylum",
  "class",
  "order",
  "family",
  "genus",
  "species"
];

export const set_taxo_rank = new Set(taxo_rank);

export function taxonomicRank(
  state = taxo_rank,
  action
) {
  return state;
}
