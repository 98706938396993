// 
//import
import {notExistMicgcException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

export function getMicgcByOcidCache(state) {
  return state.cache;
}

// code start here
export function getMicgcByOcidById(
  state,
  ocid
) {
  const cache = getMicgcByOcidCache(state);
  if (cache.hasOwnProperty(ocid)) {
    return cache[ocid];
  } else {
    throw new notExistMicgcException(ocid);
  }
}

export function micgcByOcidHasAll(state, id) {
  return fromMicFetch.hasAll(state.mic_fetch_state, id);
}
