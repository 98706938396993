//
//import
// We use typeahead. As it's hard to use from NPM it is included in the header (see #6682).
import Handlebars from "handlebars/dist/handlebars";
import * as fromRootSelector from ".";
import {taxo_rank} from "../reducer/taxonomic-rank";
import {genome_carts_name_sort} from "../reducer/genome-carts-name"

export function getSequenceSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  /* The key of the return object will be used to define the filter 
  criteria */
  return multiple
    ? {
        Sequence: getSeqSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getOrganismById,
          fromRootSelector.getSequenceById
        ),
        Taxonomy: getTaxonomySuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getTaxonomyById
        ),
        ...(!$.isEmptyObject(state.strain_metadata.cache)) && {
          Strain_metadata:
            getStrainMetadataSuggestionEngine(
              state,
              list_biobs,
              in_selection_value,
              biob_list_id,
              multiple
            ),
          },
        ...(!$.isEmptyObject(state.species_metadata.cache)) && {
          Species_metadata:
            getSpeciesMetadataSuggestionEngine(
              state,
              list_biobs,
              in_selection_value,
              biob_list_id,
              multiple
            ),
          },
        ...(!$.isEmptyObject(state.sequence_carts.cache)) && {
          Genome_carts:  // This was explicitely asked (#9026)
            getSequenceCartsSuggestionEngine(
              state,
              list_biobs,
              in_selection_value,
              biob_list_id,
              multiple
            ),
          },
        MICGC: getMicgcSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        )
      }
    : {
        Strain_name: getSeqSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getOrganismById,
          fromRootSelector.getSequenceById
        ),
      };
}

export function getOrganismSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  console.count("Create the organism suggestion engine");
  console.log("Create the organism suggestion engine", in_selection_value);
  return multiple
    ? {
        Strain_name: getStrainSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
        Taxonomy: getTaxonomySuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getTaxonomyById
        ),
        ...(!$.isEmptyObject(state.strain_metadata.cache)) && {
            Strain_metadata:
                getStrainMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.species_metadata.cache)) && {
            Species_metadata:
                getSpeciesMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.genome_carts.cache)) && {
            Genome_carts:
                getGenomeCartsSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        MICGC: getMicgcSuggestionEngine(
            state,
            list_biobs,
            in_selection_value,
            biob_list_id,
            multiple
        )
      }
    : {
        Strain_name: getStrainSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
      };
}

export function getMyOrganismSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  console.count("Create the organism suggestion engine");
  console.log("Create the organism suggestion engine", in_selection_value);
  return multiple
    ? {
        Strain_name: getStrainMyOrganismSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
        Taxonomy: getTaxonomySuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getTaxonomyById
        ),
        ...(!$.isEmptyObject(state.strain_metadata.cache)) && {
            Strain_metadata:
                getStrainMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.species_metadata.cache)) && {
            Species_metadata:
                getSpeciesMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.genome_carts.cache)) && {
            Genome_carts:
                getGenomeCartsSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        MICGC: getMicgcSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        )
    }
    : {
        Strain_name: getStrainSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
      };
}

export function getGenomeRessourceSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return {
    Strain_name: getPublicGenomeSuggestionEngine(
      state,
      list_biobs,
      in_selection_value,
      biob_list_id,
      multiple
    ),
  };
}

export function getRefseqSequenceSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return multiple
    ? {
        Sequence: getSeqSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getRefseqOrganismById,
          fromRootSelector.getRefseqSequenceById
        ),
        Taxonomy: getTaxonomySuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getRefseqTaxonomyById
        ),
        ...(!$.isEmptyObject(state.strain_metadata.cache)) && {
            Strain_metadata:
                getStrainMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.species_metadata.cache)) && {
            Species_metadata:
                getSpeciesMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        }
    }
    : {
        Strain_name: getSeqSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getRefseqOrganismById,
          fromRootSelector.getRefseqSequenceById
        ),
    };
}

export function getRefseqOrganismSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return multiple
    ? {
        Strain_name: getRefseqOrganismNameSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
        Taxonomy: getTaxonomySuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple,
          fromRootSelector.getRefseqTaxonomyById
        ),
        ...(!$.isEmptyObject(state.strain_metadata.cache)) && {
            Strain_metadata:
                getStrainMetadataSuggestionEngine(
                    state,
                    list_biobs,
                    in_selection_value,
                    biob_list_id,
                    multiple
                ),
        },
        ...(!$.isEmptyObject(state.species_metadata.cache)) && {
            Species_metadata:
               getSpeciesMetadataSuggestionEngine(
                   state,
                   list_biobs,
                   in_selection_value,
                   biob_list_id,
                   multiple
               ),
        }
    }
    : {
        Strain_name: getRefseqOrganismNameSuggestionEngine(
          state,
          list_biobs,
          in_selection_value,
          biob_list_id,
          multiple
        ),
    };
}

/****************************************
 * * Refseq organism suggestion engine *
 ****************************************/

function getRefseqOrganismNameSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  const suggestion_engine = getRefseqOrganismNameSuggestionData(
    state,
    list_biobs
  );
  return [
    constructSuggestionEngine(
      "Strain",
      "Strain",
      suggestion_engine,
      50,
      datumTokenizerByWord,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
}

function getRefseqOrganismNameSuggestionData(
  state,
  list_biobs
) {
  const clustering = {};
  const suggestion_data = list_biobs.reduce(function(
    clustering,
    biob
  ) {
    let strain = null;
    try {
      strain = fromRootSelector.getRefseqOrganismById(state, biob.id);
    } catch (error) {
      //micgc = null;
    }

    // const strain = biob.annotation.strain;
    if (strain) {
      const id = strain.O_id.toString();

      if (clustering.hasOwnProperty(id)) {
        clustering[id].biobs.push(biob);
        clustering[id].size++;
      } else {
        // O_strain might be empty so we avoid to add " "
        const cluster_name = strain.O_name + (strain.O_strain !== "" ? " " + strain.O_strain : "");
        // Construct keywords from cluster_name (remove duplicates)
        const set_keywords = new Set(cluster_name.split(" "));
        const keywords = [...set_keywords].join(" ");
        clustering[id] = {
          clustering_name: "Strain",
          cluster_name: cluster_name,
          cluster_id: id,
          cluster_search: keywords,
          biobs: [biob],
          size: 1,
        };
      }
    }
    return clustering;
  },
  clustering);

  return Object.keys(suggestion_data).map(k => {
    const suggestionObj = suggestion_data[k];
    return {
      ...suggestionObj,
      filter_name:
        suggestionObj.biobs.length === 1
          ? suggestionObj.cluster_name
          : suggestionObj.cluster_id,
    };
  });
}

/*****************************
 * Taxonomy suggestion engine
 *****************************/
const getTaxonomySuggestionData = function(
  state,
  list_biobs,
  rank,
  getTaxonomyById
) {
  const taxo_clustering = {};

  const suggestion_data = list_biobs.reduce(function(
    taxo_clustering,
    list_biob
  ) {
    let taxo = null;
    try {
      taxo = getTaxonomyById(state, list_biob.oid);
      if (taxo && taxo.hasOwnProperty(rank)) {
        const taxo_rank = taxo[rank];
        const taxid = taxo_rank.tax_id.toString();
        const oid = taxo_rank.O_id.toString();  // For error message
        if (taxo_clustering.hasOwnProperty(taxid)) {
          taxo_clustering[taxid].biobs.push(list_biob);
          taxo_clustering[taxid].size++;
        } else {
          // Use the tax_id separated by a "-"
          const cluster_name = taxo_rank.name_txt + " - " + taxid;
          // Keywords are just the name and tax_id
          const keywords = [taxo_rank.name_txt, taxid].join(" ");
          taxo_clustering[taxid] = {
            clustering_name: taxo_rank.rank,
            cluster_name: cluster_name,
            cluster_id: taxid,
            cluster_search: keywords,
            biobs: [list_biob],
            filter_name: cluster_name,
            size: 1,
          };
        }
        return taxo_clustering;
      } else {
        return taxo_clustering;
      }
    } catch (error) {
      //console.error("taxo missing while suggestion engine: ", list_biob.oid);
      return taxo_clustering;
    }

    //
    //const taxo = list_biob.annotation.taxonomy;
  },
  taxo_clustering);

  // Use Object.keys instead of Object.values because of Flow and typecheck.
  return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getTaxonomySuggestionEngine = function(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple,
  getTaxonomyById
) {
  return taxo_rank.map(rank =>
    constructSuggestionEngine(
      rank,
      rank,
      getTaxonomySuggestionData(state, list_biobs, rank, getTaxonomyById),
      50,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    )
  );
};

/************************************************
 *            MICGC suggestion engine
 ************************************************/
const getMicgcSuggestionData = function(
  state,
  list_biobs
) {
  const micgc_clustering = {};
  const suggestion_data = list_biobs.reduce(function(
    micgc_clustering,
    biob
  ) {
    let micgc = null;
    try {
      micgc = fromRootSelector.getMicgcById(state, biob.oid);
    } catch (error) {}

    if (micgc) {
      const ocid = micgc.OC_id.toString();
      const oid = micgc.O_id.toString();

      if (micgc_clustering.hasOwnProperty(ocid)) {
        micgc_clustering[ocid].biobs.push(biob);
        micgc_clustering[ocid].size++;
      } else {
        const clustering_name = "MICGC";
        const cluster_name = clustering_name + ocid;
        // Construct keywords from cluster_name (remove duplicates)
        const set_keywords = new Set(cluster_name.split(" "));
        const keywords = [...set_keywords].join(" ");
        micgc_clustering[ocid] = {
          clustering_name,
          cluster_name: cluster_name,
          cluster_id: ocid,
          cluster_search: keywords,
          biobs: [biob],
          filter_name: cluster_name,
          size: 1,
        };
      }
    } else {
      //console.log(annotated_biob);
    }
    return micgc_clustering;
  },
  micgc_clustering);

  return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getMicgcSuggestionEngine = function(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return [
    constructSuggestionEngine(
      "MICGC",
      "MICGC",
      getMicgcSuggestionData(state, list_biobs),
      50,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
};

/************************************************
 *          Strain Metadata suggestion engine
 ************************************************/
const getStrainMetadataSuggestionData = function (
    state,
    list_biobs,
    metadataName
) {
    const metadata_clustering = {};
    const suggestion_data = list_biobs.reduce(function (
        metadata_clustering,
        list_biob
        ) {
            let metadata = null;
            try {
                metadata = fromRootSelector.getStrainMetadataById(state, list_biob.oid)
                if (metadata && metadata.hasOwnProperty(metadataName)) {
                    const organism_metadatum = metadata[metadataName];
                    organism_metadatum.map( datum => {
                        // datum est un Objet avec son o_id, un type de métadonnée et sa valeur
                        // {
                        //  O_id: 10
                        //  metadata_harmonized_name: "cell_shape"
                        //  metadata_id: null
                        //  metadata_key: "cell_shape#bacillus"
                        //  metadata_name: "cell shape"
                        //  metadata_value: "bacillus"
                        // }
                        const meta_key = datum.metadata_key;
                        if (metadata_clustering.hasOwnProperty(meta_key)) {
                            metadata_clustering[meta_key].biobs.push(list_biob);
                            metadata_clustering[meta_key].size++;
                        } else {
                            const cluster_name = datum.metadata_value;
                            // Keywords are just the name and meta_key
                            const keywords = [datum.metadata_value, meta_key].join(" ");
                            metadata_clustering[meta_key] = {
                                clustering_name: datum.metadata_harmonized_name,
                                cluster_name: cluster_name,
                                cluster_id: meta_key,
                                cluster_search: keywords,
                                biobs: [list_biob],
                                filter_name: cluster_name,
                                size: 1,
                            };
                        }
                    })
                    return metadata_clustering;
                } else {
                    return metadata_clustering;
                }
            } catch (error) {
                console.log(error)
                return metadata_clustering;
            }
        },
        metadata_clustering);
    return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getStrainMetadataSuggestionEngine = function (
    state,
    list_biobs,
    in_selection_value,
    biob_list_id,
    multiple
) {
    return state.strain_metadata.metadata_list.map(metadata_name =>
        constructSuggestionEngine(
            metadata_name.name,
            metadata_name.harmonized_name,
            getStrainMetadataSuggestionData(state, list_biobs, metadata_name.harmonized_name),
            50,
            datumTokenizerByLetter,
            in_selection_value,
            fromRootSelector.getBiobName(state, biob_list_id),
            multiple
        )
    );
};

/************************************************
 *      Species Metadata suggestion engine
 ************************************************/
const getSpeciesMetadataSuggestionData = function (
    state,
    list_biobs,
    metadataName
) {
    const metadata_clustering = {};
    const suggestion_data = list_biobs.reduce(function (
        metadata_clustering,
        list_biob
        ) {
            let metadata = null;
            try {
                metadata = fromRootSelector.getSpeciesMetadataById(state, list_biob.oid)
                if (metadata && metadata.hasOwnProperty(metadataName)) {
                    const organism_metadatum = metadata[metadataName];
                    organism_metadatum.map( datum => {
                        // datum est un Objet avec son o_id, un type de métadonnée et sa valeur
                        // {
                        //  O_id: 10
                        //  metadata_harmonized_name: "cell_shape"
                        //  metadata_id: null
                        //  metadata_key: "cell_shape#bacillus"
                        //  metadata_name: "cell shape"
                        //  metadata_value: "bacillus"
                        // }
                        const meta_key = datum.metadata_key;
                        if (metadata_clustering.hasOwnProperty(meta_key)) {
                            metadata_clustering[meta_key].biobs.push(list_biob);
                            metadata_clustering[meta_key].size++;
                        } else {
                            const cluster_name = datum.metadata_value;
                            // Keywords are just the name and meta_key
                            const keywords = [datum.metadata_value, meta_key].join(" ");
                            metadata_clustering[meta_key] = {
                                clustering_name: datum.metadata_harmonized_name,
                                cluster_name: cluster_name,
                                cluster_id: meta_key,
                                cluster_search: keywords,
                                biobs: [list_biob],
                                filter_name: cluster_name,
                                size: 1,
                            };
                        }
                    })
                    return metadata_clustering;
                } else {
                    return metadata_clustering;
                }
            } catch (error) {
                console.log(error)
                return metadata_clustering;
            }
        },
        metadata_clustering);
    return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getSpeciesMetadataSuggestionEngine = function (
    state,
    list_biobs,
    in_selection_value,
    biob_list_id,
    multiple
) {
    return state.species_metadata.metadata_list.map(metadata_name =>
        constructSuggestionEngine(
            metadata_name.name,
            metadata_name.harmonized_name,
            getSpeciesMetadataSuggestionData(state, list_biobs, metadata_name.harmonized_name),
            50,
            datumTokenizerByLetter,
            in_selection_value,
            fromRootSelector.getBiobName(state, biob_list_id),
            multiple
        )
    );
};

/************************************************
 *      Genome Carts suggestion engine
 ************************************************/
const getGenomeCartsSuggestionData = function (state, list_biobs) {
    const gc_clustering = {};
    const clustering_name = "genome_carts"; // Computer name of the search category
    const suggestion_data = list_biobs.reduce(
        function(gc_clustering, biob) {
            try {
                // Get the genome_carts in which the current Oid is contained.
                let oid_genome_carts = fromRootSelector.getGenomeCartsById(state, biob.oid);
                if (oid_genome_carts) {
                    for (var cart_name of oid_genome_carts) {
                        if (gc_clustering.hasOwnProperty(cart_name)) {
                            gc_clustering[cart_name].biobs.push(biob);
                            gc_clustering[cart_name].size++;
                        } else {
                            const cluster_name = cart_name;
                            const keywords = cart_name;
                            gc_clustering[cart_name] = {
                                clustering_name: clustering_name,
                                cluster_name: cluster_name, // Human friendly name of the search category
                                cluster_id: cluster_name,
                                cluster_search: keywords,
                                biobs: [biob], // Biobs contained in the current option of the search category (i.e. value)
                                filter_name: cluster_name,
                                size: 1, // Number of biobs contained in the current option
                           };
                        }
                    }
                    return gc_clustering
                } else {
                    return gc_clustering;
                }
            } catch(error) {
                console.log(error)
            }
        },
        gc_clustering
    );
    return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getGenomeCartsSuggestionEngine = function (
    state,
    list_biobs,
    in_selection_value,
    biob_list_id,
    multiple
) {
    return [
        constructSuggestionEngine(
            "Genome carts",
            "genome_carts",
            getGenomeCartsSuggestionData(state, list_biobs),
            50,
            datumTokenizerByLetter,
            in_selection_value,
            fromRootSelector.getBiobName(state, biob_list_id),
            multiple,
            (a, b) => genome_carts_name_sort(a.cluster_name, b.cluster_name)
        )
    ];
};

/************************************************
 *      Sequence Carts suggestion engine
************************************************/
const getSequenceCartsSuggestionData = function (state, list_biobs) {
    const sc_clustering = {};
    const clustering_name = "sequence_carts"; // Computer name of the search category
    const suggestion_data = list_biobs.reduce(
        function(sc_clustering, biob) {
            try {
                // Get the sequence_carts in which the current Oid is contained.
                let sid_sequence_carts = fromRootSelector.getSequenceCartsById(state, biob.id);
                if (sid_sequence_carts) {
                    for (var cart_name of sid_sequence_carts) {
                        if (sc_clustering.hasOwnProperty(cart_name)) {
                            sc_clustering[cart_name].biobs.push(biob);
                            sc_clustering[cart_name].size++;
                        } else {
                            const cluster_name = cart_name;
                            const keywords = cart_name;
                            sc_clustering[cart_name] = {
                                clustering_name: clustering_name,
                                cluster_name: cluster_name, // Human friendly name of the search category
                                cluster_id: cluster_name,
                                cluster_search: keywords,
                                biobs: [biob], // Biobs contained in the current option of the search category (i.e. value)
                                filter_name: cluster_name,
                                size: 1, // Number of biobs contained in the current option
                            };
                        }
                    }
                    return sc_clustering
                } else {
                    return sc_clustering;
                }
            } catch(error) {
                console.log(error)
            }
        },
        sc_clustering
    );
    return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getSequenceCartsSuggestionEngine = function (
    state,
    list_biobs,
    in_selection_value,
    biob_list_id,
    multiple
) {
   return [
       constructSuggestionEngine(
           "Genome carts",  // This was explicitely asked (#9026)
           "sequence_carts",
           getSequenceCartsSuggestionData(state, list_biobs),
           50,
           datumTokenizerByLetter,
           in_selection_value,
           fromRootSelector.getBiobName(state, biob_list_id),
           multiple,
           (a, b) => genome_carts_name_sort(a.cluster_name, b.cluster_name)
        )
   ];
};

/***********************
 * SEQUENCE SUGGESTION *
 ***********************/

const getSequenceSuggestionData = function(
  state,
  list_biobs,
  getOrganismById,
  getSequenceById
) {
  const clustering = {};
  const suggestion_data = list_biobs.reduce(function(
    strain_clustering,
    biob
  ) {
    let orga = null;
    try {
      orga = getOrganismById(state, biob.oid);
    } catch (error) {
      //console.log(error);
    }

    let seq = null;
    try {
      seq = getSequenceById(state, biob.id);
    } catch (error) {
      //console.log(error);
    }

    if (orga && seq) {
      const seqVersion = seq.R_version !== "" ? "." + seq.R_version : "";
      const cluster_id = seq.S_id.toString();

      if (strain_clustering.hasOwnProperty(cluster_id)) {
        strain_clustering[cluster_id].biobs.push(biob);
        strain_clustering[cluster_id].size++;
      } else {
        const cluster_name = [
          orga.O_name,
          orga.O_strain,
          seq.R_type,
          seq.R_name + seqVersion,
        ].join(" ");
        // Search keywords: same as cluster name but we don't include version
        const set_keywords = new Set([
          orga.O_name,
          orga.O_strain,
          seq.R_name,
          seq.R_type,
        ]);
        const keywords = [...set_keywords].join(" ");
        strain_clustering[cluster_id] = {
          clustering_name: "Sequence",
          cluster_name: cluster_name,
          // need this to make line plot work (use this label as id)
          cluster_line_plot_id: [
            orga.O_name,
            orga.O_strain,
            seq.R_type,
            seq.S_label_tag,
            seq.R_name,
          ].join(" "),
          // need this to make line plot work (use this label as id)
          cluster_line_plot_refseq_id: [
            orga.O_name,
            orga.O_strain,
            seq.R_name,
          ].join(" "),
          cluster_id: cluster_id,
          cluster_search: keywords,
          biobs: [biob],
          size: 1,
        };
      }
      return strain_clustering;
    } else {
      return strain_clustering;
    }
  },
  clustering);


  return Object.keys(suggestion_data).map(k => {
    const suggestionObj = suggestion_data[k];

    return {
      ...suggestionObj,
      filter_name:
        suggestionObj.biobs.length === 1
          ? suggestionObj.cluster_name
          : suggestionObj.cluster_id,
    };
  });
};

export const getSeqSuggestionEngine = function(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple,
  getOrganismById,
  getSequenceById
) {
  return [
    constructSuggestionEngine(
      "Sequence",
      "Sequence",
      getSequenceSuggestionData(
        state,
        list_biobs,
        getOrganismById,
        getSequenceById
      ),
      50,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
};

/*
  FAVORITE ORGANISM SUGGESTION ENGINE
*/

function getFavoriteGenomesSuggestionData(
  state,
  list_biobs
) {
  const clustering = {};
  const favOrgCache = fromRootSelector.getFavoriteGenomesCache(state);
  const suggestion_data = list_biobs
    .filter(biob => favOrgCache.hasOwnProperty(biob.oid))
    .reduce(function(clustering, biob) {
      let favOrg = null;
      try {
        favOrg = fromRootSelector.getOrganismById(state, biob.oid);
      } catch (error) {}

      if (favOrg) {
        const oid = favOrg.O_id.toString();

        if (clustering.hasOwnProperty(oid)) {
          clustering[oid].biobs.push(biob);
          clustering[oid].size++;
        } else {
          const cluster_name = favOrg.O_name + " " + favOrg.O_strain;
          // Construct keywords from cluster_name (remove duplicates)
          const set_keywords = new Set(cluster_name.split(" "));
          const keywords = [...set_keywords].join(" ");
          clustering[oid] = {
            clustering_name: "favorite_genomes",
            cluster_name: cluster_name,
            cluster_id: oid,
            cluster_search: keywords,
            biobs: [biob],
            size: 1,
          };
        }
      }
      return clustering;
    }, clustering);
  //return Object.keys(suggestion_data).map(k => suggestion_data[k]);
  return Object.keys(suggestion_data).map(k => {
    const suggestionObj = suggestion_data[k];
    return {
      ...suggestionObj,
      filter_name:
        suggestionObj.biobs.length === 1
          ? suggestionObj.cluster_name
          : suggestionObj.cluster_id,
    };
  });
}

// function getFavoriteGenomesSuggestionEngine(
//   state: MicState,
//   list_biobs: ListBiob[],
//   in_selection_value: boolean,
//   biob_list_id: string,
//   multiple: boolean
// ) {
//   return [
//     constructSuggestionEngine(
//       "Favorite Organism",
//       getFavoriteGenomesSuggestionData(state, list_biobs),
//       50,
//       datumTokenizerByLetter,
//       in_selection_value,
//       fromRootSelector.getBiobName(state, biob_list_id),
//       multiple
//     )
//   ];
// }

/*******************************************
 * STRAIN suggestion engine
 *******************************************/

const getStrainSuggestionData = function(
  state,
  list_biobs
) {
  const strain_clustering = {};
  const suggestion_data = list_biobs.reduce(function(
    strain_clustering,
    biob
  ) {
    let strain = null;
    try {
      strain = fromRootSelector.getOrganismById(state, biob.oid);
    } catch (error) {
      //micgc = null;
    }

    // const strain = biob.annotation.strain;
    if (strain) {
      const oid = strain.O_id.toString();

      if (strain_clustering.hasOwnProperty(oid)) {
        strain_clustering[oid].biobs.push(biob);
        strain_clustering[oid].size++;
      } else {
        const strain_name = strain.O_name + " " + strain.O_strain;
        const cluster_name = strain_name;
        // Construct keywords from cluster_name (remove duplicates)
        const set_keywords = new Set(cluster_name.split(" "));
        const keywords = [...set_keywords].join(" ");
        strain_clustering[oid] = {
          clustering_name: "Strain",
          cluster_name: cluster_name,
          cluster_id: oid,
          cluster_search: keywords,
          biobs: [biob],
          size: 1,
        };
      }
    }
    return strain_clustering;
  },
  strain_clustering);

  return Object.keys(suggestion_data).map(k => {
    const suggestionObj = suggestion_data[k];
    return {
      ...suggestionObj,
      // Note sure here.
      filter_name:
        suggestionObj.cluster_name
    };
  });
  //return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

const getStrainSuggestionMyOrganismData = function(
  state,
  list_biobs
) {
  const strain_clustering = {};
  const suggestion_data = list_biobs.reduce(function(
    strain_clustering,
    biob
  ) {
    let strain = null;
    try {
      strain = fromRootSelector.getMyOrganismById(state, biob.oid);
    } catch (error) {
      //micgc = null;
    }

    // const strain = biob.annotation.strain;
    if (strain) {
      const oid = strain.O_id.toString();

      if (strain_clustering.hasOwnProperty(oid)) {
        strain_clustering[oid].biobs.push(biob);
        strain_clustering[oid].size++;
      } else {
        //Nom affiché dans le menu de suggestion
        const strain_name = strain.O_name + " " + strain.O_strain + " " + strain.O_access;
        const cluster_name = strain_name;
        // Construct keywords from cluster_name (remove duplicates)
        const set_keywords = new Set(cluster_name.split(" "));
        const keywords = [...set_keywords].join(" ");
        strain_clustering[oid] = {
          clustering_name: "Strain",
          cluster_name: cluster_name,
          cluster_id: oid,
          cluster_search: keywords,
          biobs: [biob],
          size: 1,
        };
      }
    }
    return strain_clustering;
  },
  strain_clustering);

  return Object.keys(suggestion_data).map(k => {
    const suggestionObj = suggestion_data[k];
    return {
      ...suggestionObj,
      // Note sure here.
      filter_name:
        suggestionObj.cluster_name
    };
  });
  //return Object.keys(suggestion_data).map(k => suggestion_data[k]);
};

export const getStrainSuggestionEngine = function(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return [
    constructSuggestionEngine(
      "Favorite Genomes",
      "favorite_genomes",
      getFavoriteGenomesSuggestionData(state, list_biobs),
      50,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
    constructSuggestionEngine(
      "Strain",
      "Strain",
      getStrainSuggestionData(state, list_biobs),
      //100,
      list_biobs.length,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
};

export const getStrainMyOrganismSuggestionEngine = function(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  return [
    constructSuggestionEngine(
      "Favorite Genomes",
      "favorite_genomes",
      getFavoriteGenomesSuggestionData(state, list_biobs),
      50,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
    constructSuggestionEngine(
      "Strain",
      "Strain",
      getStrainSuggestionMyOrganismData(state, list_biobs),
      //100,
      list_biobs.length,
      datumTokenizerByLetter,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
};

/***********************************
 * PUBLIC GENOME suggestion engine *
 ***********************************/

function getPublicGenomeSuggestionData(
  state,
  list_biobs
) {
  const public_genome_clustering = {};
  // console.profile("reduce");
  const suggestion_data = list_biobs.reduce(function(
    pg_clustering,
    list_biob
  ) {
    // get the public genome annotation
    try {
      const public_genome = fromRootSelector.getPublicGenomeById(
        state,
        list_biob.id
      );
      const gaid = public_genome.GA_id.toString();
      if (pg_clustering.hasOwnProperty(gaid)) {
        pg_clustering[gaid].biobs.push(list_biob);
        pg_clustering[gaid].size++;
      } else {
        const cluster_name = public_genome.name;  // Contains the assembly level (between parenthesis)
        // Remove ( and ) in words (this is for assembly level)
        const keywords_no_paren = cluster_name.replace(/\(|\)/g, "");
        pg_clustering[gaid] = {
          clustering_name: "Strain",
          cluster_name: cluster_name,
          cluster_id: gaid,
          cluster_search: keywords_no_paren,
          biobs: [list_biob],
          size: 1,
        };
      }
    } catch (error) {}
    return pg_clustering;
  },
  public_genome_clustering);
  return Object.keys(suggestion_data).map(k => {
    const suggestion_obj = suggestion_data[k];
    return {
      ...suggestion_obj,
      filter_name:
        suggestion_obj.biobs.length === 1
          ? suggestion_obj.cluster_name
          : suggestion_obj.cluster_id,
    };
  });
}

export function getPublicGenomeSuggestionEngine(
  state,
  list_biobs,
  in_selection_value,
  biob_list_id,
  multiple
) {
  console.log("bloodhound => getPublicGenomeSuggestionData start");
  const dataset = getPublicGenomeSuggestionData(state, list_biobs);
  console.log("bloodhound => getPublicGenomeSuggestionData stop");
  return [
    constructSuggestionEngine(
      "Strain",
      "Strain",
      dataset,
      50,
      datumTokenizerByWord,
      in_selection_value,
      fromRootSelector.getBiobName(state, biob_list_id),
      multiple
    ),
  ];
}


/**
 * Construct the bloodhound suggestion engine @see https://github.com/corejavascript/typeahead.js/blob/master/doc/bloodhound.md
 * 
 * @param {string} label Nom des datasets utilise pour l'affichage (peut contenir des espaces, caractere speciaux, etc.)
 * @param {string} name Nom des datasets utilise pour creer des elements HTML (pas d'espace, caractere speciaux, etc.)
 * @param {*} datasets
 * @param {*} limit
 * @param {*} datumTokenizer
 * @param {boolean} in_selection_value
 * @param {string} biob_name
 * @param {boolean} multiple
 * @param {function} sort_function Fonction de tri utilisée pour trier les suggestions, par défaut par ordre alphabétique.
 * @returns
 */
function constructSuggestionEngine(
  label,
  name,
  datasets,
  limit,
  datumTokenizer,
  in_selection_value,
  biob_name,
  multiple,
  sort_function = (a, b) => a.cluster_name.localeCompare(b.cluster_name)
) {
  const bloodhound = new Bloodhound({
    local: datasets,
    identify: function(obj) {
      return obj.cluster_id;
    },
    matchAnyQueryToken: true,
    datumTokenizer,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    sorter: sort_function,
  });

  const icon = in_selection_value
    ? `<i class="fa fa-filter" aria-hidden="true"></i> <span class="text-secondary">Keep ${biob_name}s that contain "`
    : `<i class="fa fa-search" aria-hidden="true"></i> <span class="text-secondary">Find ${biob_name}s that contain "`

  const sugg = Handlebars.compile(
      '<div class="d-flex bd-highlight">' +
      '<div class="w-100 bd-highlight">{{cluster_name}}</div>' +
      '<div class="flew-shrink-1 bd-highlight">({{size}})</div>' +
      "</div>"
  );

  const templates = multiple
    ? {
        header: (data) => {
          if (data.query !== "" && data.dataset !== "favorite_genomes") {
            return (
              `<div>
                <h5>${label}</h5>
               </div>
              <div class="tt-suggestion tt-selectable tt-custom-search-${label}">
                ${icon}${data.query}" in ${label}
              </span></div>`
            );
          } else {
            return `<div><h5>${label}</h5></div>`;
          }
        },
        suggestion: sugg,
      }
    : {
        header: (data) => `<div><h5>${label}</h5></div>`,
      };

  return {
    name,
    bloodhound,
    source: function(q, sync, async) {
      if (q === "") {
        const all = bloodhound
          .all()
          .sort(sort_function);
        sync(all);
      } else {
        bloodhound.search(q, sync, async);
      }
    },
    async: true,
    display: "cluster_name",
    // limit,
    limit: 200,
    templates,
  };
}

export function filterListBiob(
  state,
  suggestion_engine,
  biob_list_id,
  list_biob,
  filters
) {

  const set_list_biobs = new Set([...list_biob]);
  const list_biob_to_keep = filters.reduce(
    (filtered_annotated_biobs, filter) => {
      const matching_oids = getMatchingListBiobs(filter, suggestion_engine);

      // get the last value inserted in the array
      const annotated_biobs =
        filtered_annotated_biobs[filtered_annotated_biobs.length - 1];

      // do the intersection.
      const intersection = new Set(
        Object.keys(matching_oids)
          .map((k) => matching_oids[k])
          .filter(biob => annotated_biobs.biobs.has(biob))
      );
      filtered_annotated_biobs.push({
        filter_id: filter.id,
        size: intersection.size,
        biobs: intersection,
      });
      return filtered_annotated_biobs;
    },
    [
      {
        filter_id: "none",
        size: set_list_biobs.size,
        biobs: set_list_biobs,
      },
    ]
  );
  return list_biob_to_keep.map(obj => {
    return {
      size: obj.size,
      biobs: [...obj.biobs],
      filter_id: obj.filter_id,
    };
  });
}

export function searchListBiob(
  state,
  suggestion_engine,
  biob_list_id,
  list_biob,
  filters
) {

  const matching_list_biobs = filters.reduce(
    (biobs_set, filter) => {
      const matching_list_biobs = getMatchingListBiobs(
        filter,
        suggestion_engine
      );

      // Union.
      return new Set([
        ...biobs_set,
        ...Object.keys(matching_list_biobs).map(
          (k) => matching_list_biobs[k]
        ),
      ]);
    },
    new Set()
  );
  return [...matching_list_biobs];
}

function getMatchingListBiobs(
  filter,
  suggestion_engine
) {
  // Exact Match
  if (filter && filter.matching_type === "exact") {
    return getIdsBySuggestionFilter(filter, suggestion_engine[filter.type]);
  } else {
    // Not exact match
    return getOidsByCustomFilter(filter, suggestion_engine[filter.type]);
  }
}

function getIdsBySuggestionFilter(
  filter,
  search_engines
) {
  return search_engines
    // Keep search engine corresponding to the subtype
    .filter(
      subtype_search_engine => filter.subtype === subtype_search_engine.name
    )
    // Sum oids that match any of these engines
    .reduce(function(accum, rank_taxo_engine) {
      const matching_filter_oids = rank_taxo_engine.bloodhound
        .get([filter.value])
        // reduce all the suggestion data.
        .reduce(function(accum, res) {
          // reduce one suggestion data
          const oids_to_keep = res.biobs.reduce(function(accum, biob) {
            accum[biob.id] = biob;
            return accum;
          }, accum);
          return accum; //{ ...accum, ...oids_to_keep };
        }, accum);
      return accum; //{ ...accum, ...matching_filter_oids };
    }, {});
}

function getOidsByCustomFilter(
  filter,
  search_engines
) {
  return search_engines
    .filter(search_engine => {
      if (filter.subtype) {
        return search_engine.name === filter.subtype;
      } else {
        return true;
      }
    })
    .reduce(function(accum, search_engine) {
      let search_results = [];
      const query = filter.value;
      const results = search_engine.bloodhound.search(query, results => {
        search_results = results;
      });
      const matching_filter_oids = search_results.reduce(function (accum, res) {
        const oids_to_keep = res.biobs.reduce(function(accum, biob) {
          accum[biob.id] = biob;
          return accum;
        }, accum);
        return accum;
      },
      accum);
      return accum;
    }, {});
}

function datumTokenizerByWord(datum) {
  return datum.cluster_search.split(/\s+/);
}

function datumTokenizerByLetter(datum) {
  return datum["cluster_search"]
    .split(/\s+/)
    .map(function(str) {
      return str
        .split("")
        .reduce(function(acc, curr, i, array) {
          const slice = array.slice(i);
          acc.push(slice.join(""));
          return acc;
        }, [])
        .join(" ");
    })
    .join(" ")
    .split(/\s+/);
}
