// 

export function getCache(
  state
) {
  return state.cache;
}

export function getMicFetch(
  state
) {
  return state.mic_fetch_state;
}
