// 
import { select, selectAll } from "d3-selection";


/**
 * Root d3 component that draw the single biob selector.
 *
 * @example
 * const component = biobListSingleSelector();
 * selection.datum([data]).call(component, width, height, "Strain_name", html_class);
 *
 * @export
 * @returns
 */
export default function biobListSingleSelector() {
  function exports(
    selection,
    grid_width,
    max_height,
    suggestion_id = "strain",
    input_class = ""
  ) {
    selection.each(function(_data) {
      const root = select(this);
      const simple_genome_selector = root
        .selectAll("div.simple-biob-selector")
        .data(_data);

      // process enter selection for the selector
      const simple_genome_selector_e = simple_genome_selector
        .enter()
        .append("div")
        .classed("simple-biob-selector input-group", true);

      simple_genome_selector_e // add the loop icon
        .append("div")
        .classed("input-group-prepend", true)
        .append("span")
        .classed("input-group-text", true)
        .append("i")
        .attr("aria-hidden", "true")
        .classed("fa fa-search", true);

      simple_genome_selector_e // add the input
        .append("input")
        .property("disabled", true)
        .classed("single-search form-control", true)
        .classed(input_class, true)
        .attr("type", "search")
        .attr("autocomplete", "off");

      simple_genome_selector_e // add the spinner indicating fetch (this spinner will be removed)
        .append("div")
        .classed("input-group-append", true);

      // process exit selection for the selector
      simple_genome_selector.exit().remove();

      // process update selection for the selector
      const simple_genome_selector_u = simple_genome_selector.merge(
        simple_genome_selector_e
      );
      simple_genome_selector_u
        .select(".input-group-prepend")
        .attr("id", d => d.id + "-input-group-prepend");

      simple_genome_selector_u
        .select(".single-search")
        .attr("aria-describedby", d => d.id + "-input-group-prepend")
        .attr("id", getSearchInputId)
        .attr("placeholder", d => d.placeholder)
        .each(function(d) {
          const $input = $(this);
          const suggestion_engine = d.suggestion_engine[suggestion_id];
          $input.typeahead("destroy");
          $input.typeahead(
            {
              hint: false,
              highlight: true,
              minLength: 0
            },
            suggestion_engine
          );

          $input.off("typeahead:select");
          $input.bind("typeahead:select", function(ev, suggestion) {
            d.eventHandlers.selectSuggestion(suggestion.cluster_id);
            // exports.dispatcher.call("select-suggestion", this, suggestion);
            $input.typeahead("val", "");
            $input.blur();
          });
        })
        // Modify style now because it seems that typeahead modifies it
        .property("disabled", d => d.isFetching)
        .style("border-radius", d => (d.isFetching ? "0rem 0rem 0rem 0rem" : "0rem .25rem .25rem 0rem"))
        .classed("radius-rounded", d => d.isFetching ? true : false);

      // Modify the spinner according to fetch
      const spinner = simple_genome_selector_u
        .select(".input-group-append")
        .selectAll("span.input-group-text")
        .data(d => (d.isFetching ? [true] : []));

      // ENTER spinner
      spinner
        .enter()
        .append("span")
        .classed("input-group-text", true)
        .append("i")
        .classed("fa fa-spinner fa-spin fa-fw", true);

      // EXIT spinner
      spinner.exit().remove();
    });
  }
  function getSearchInputId(d) {
    return d.id + "_single_search_input";
  }
  return exports;
}
