//
//import
import {notExistMetadataException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";
// code start here
export function getMetadataCache(state) {
  return state.cache;
}

export function getListMetadata(state) {
  const cache = getMetadataCache(state);
  return Object.values(cache).reduce(function(
    metadataList,
    metadata
  ) {
    const oid_str = metadata.O_id.toString();
    metadataList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return metadataList;
  },
  {});
}

export function getMetadataById(state, id) {
  const cache = getMetadataCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    // throw new notExistMetadataException(id);
  }
}

export function getStringifyMetadataById(
  state,
  id,
  org_string
) {
  return stringifyMetadata(state, id) + "; " + org_string;
}

export function stringifyMetadata(metadata, org_string) {
  const group = getMetadataById(metadata, org_string);
  return Object.values(group)
      .sort((a, b) => a.depth - b.depth)
      .map((meta) => meta.name_txt)
      .join("; ");
}

export function metadataHasAll(state, id) {
  const fetchState = state.mic_fetch_state;
  return fromMicFetch.hasAll(fetchState, id);
}
