// 

// imports
import { select, selectAll, event } from "d3-selection";

// Custom d3 components
import { SelectButton } from "../utils/bio-viz";
import { SimpleSelect } from "../utils/bio-viz";

import AdvancedFilter from "./advanced-filter";

export default function() {
  function exports(selection, getModalId) {
    const biob_select_component = SelectButton();
    const biob_unselect_component = SelectButton();
    const grouping_genomes_component = SimpleSelect();
    const advanced_filter_component = AdvancedFilter();
    selection.each(function(_data) {
      const modal = select(this)
        .selectAll("div.modal")
        .data(_data);

      modal.exit().remove();
      const modal_e = modal
        .enter()
        .append("div")
        .attr("tabindex", "-1")
        .attr("role", "dialog")
        .attr("aria-hidden", "true")
        .attr("data-backdrop", "static")
        .attr("data-keyboard", false)
        .classed("modal fade", true);

      const modal_content_e = modal_e
        .append("div")
        .classed("modal-dialog modal-lg", true)
        .attr("role", "document")
        .append("div")
        .classed("modal-content", true);

      const modal_header_e = modal_content_e
        .append("div")
        .classed("modal-header py-2 bg-light", true);
      modal_header_e
        .append("div")
        .classed("col", true)
        .append("h5")
        .classed("modal-title", true);

      const form_group_enter = modal_header_e
        .append("form")
        .classed("grouping-selector", true)
        .append("div")
        .classed("form-group row mb-0", true);

      form_group_enter.append("div").classed("grouping-label", true);

      form_group_enter.append("div").classed("col-auto grouping-select", true);

      const modal_body_e = modal_content_e
        .append("div")
        .classed("modal-body p-1", true);

      const container_body_e = modal_body_e
        .append("div")
        .classed("container-fluid", true);

      const card_body = container_body_e
        .append("div")
        .classed("card border-0", true)
        .append("div")
        .classed("card-body p-2", true);

      const list_group = card_body.append("ul").classed("list-group", true);

      const unselected_list_group_item = list_group
        .append("li")
        .classed("list-group-item p-1 border-0", true);
      unselected_list_group_item
        .append("div")
        .classed("spinner-container row", true)
        .append("div")
        .classed("col-sm-12", true);

      unselected_list_group_item
        .append("div")
        .classed("advanced-filters unselected mb-2", true);

      unselected_list_group_item
        .append("div")
        .classed("multiple-genome-selection unselected", true);

      const btn_list_group_item = list_group
        .append("li")
        .classed("list-group-item p-1 border-0", true);

      const btn_group_e = btn_list_group_item
        .append("div")
        .classed("add-remove-btn justify-content-md-center row", true)
        .append("div")
        .classed("btn-group add-remove-btn-grp", true)
        .attr("role", "group");

      btn_group_e
        .append("button")
        .classed("btn btn-success add-genome", true)
        .attr("type", "button")
        .append("i")
        .classed("fa fa-arrow-down", true)
        .attr("aria-hidden", "true");
      btn_group_e
        .append("button")
        .classed("btn btn-danger remove-genome", true)
        .attr("type", "button")
        .append("i")
        .classed("fa fa-arrow-up", true)
        .attr("aria-hidden", "true");

      const selected_list_group_item = list_group
        .append("li")
        .classed("list-group-item p-1 border-0", true);

      const accordion_e = selected_list_group_item
        .append("div")
        .classed("advanced-filters selected mb-2", true)
        .append("div")
        .classed("card", true);
      const accordion_header_e = accordion_e
        .append("div")
        .classed("card-header d-flex justify-content-between  p-1 px-3", true)
        .attr("data-toggle", "collapse")
        .attr("aria-expanded", "false");

      accordion_header_e.append("div").text("Advanced filters");

      accordion_header_e
        .append("div")
        .append("i")
        .classed("fa fa-caret-right justify-content-end", true)
        .attr("aria-hidden", "true");

      accordion_e
        .append("div")
        .classed("collapse", true)
        .attr("aria-labelledby", "")
        .append("div")
        .classed("card-body p-2", true);

      selected_list_group_item
        .append("div")
        .classed("multiple-genome-selection selected", true);

      selected_list_group_item
        .append("div")
        .classed("alert-message mt-2", true);

      const modal_footer_e = modal_content_e
        .append("div")
        .style("justify-content", "normal")
        .style("display", "grid")
        .classed("modal-footer py-1", true);

      const flex_div = modal_footer_e.append("div").classed("d-flex", true);

      flex_div
        .append("div")
        .classed("p-2", true)
        .append("button")
        .attr("type", "button")
        .classed("btn btn-dark reset", true)
        .attr("data-dismiss", "modal")
        .attr("aria-label", "Close")
        .on("click", function(d) {
          d.eventHandlers.cancel();
        })
        .text("Cancel");

      flex_div
        .append("div")
        .classed("mr-auto clear-selection p-2", true)
        .append("button")
        .attr("type", "button")
        .classed("btn btn-danger", true)
        .attr("aria-label", "Close")
        .on("click.resetbtn", function(d) {
          d.eventHandlers.reset();
        })
        .text("Reset");

      const save_btn = flex_div
        .append("div")
        .classed("p-2", true)
        .append("button")
        .attr("type", "button")
        .attr("data-dismiss", "modal")
        .attr("aria-label", "Close")
        .classed("btn btn-primary save-selection", true)
        .on("click", (d) => d.eventHandlers.save());

      save_btn.append("span").text("Save ");

      save_btn
        .append("span")
        .classed("badge badge-light num-genome-saved", true);

      // MODAL UPDATE
      const modal_u = modal_e.merge(modal);

      // On

      const modal_header = modal_u
        .select(".modal-header")
        .attr("id", (d) => d.id + "-header");
      modal_header
        .select("h5")
        .html(
          d =>
            d.title +
            "  " +
            '<span class="badge badge-primary">' +
            (d.in_selection_biobs.total_biobs +
              d.not_in_selection_biobs.total_biobs) +
            "</span>"
        );

      // Grouping label:
      const grouping_label = modal_header
        .select(".grouping-label")
        .selectAll("label")
        .data(
          d => (d.biobs_grouping_btn_modal ? [d.biobs_grouping_btn_modal] : [])
        );

      grouping_label.exit().remove();

      grouping_label
        .enter()
        .append("label")
        .classed("col-auto col-form-label px-1", true)
        .text("Display by:");

      modal_header
        .select(".grouping-select")
        .datum(
          d => (d.biobs_grouping_btn_modal ? [d.biobs_grouping_btn_modal] : [])
        )
        .call(grouping_genomes_component);

      const modal_content_u = modal_u
        .attr("aria-labelledby", getModalTitleId)
        .attr("id", (d) => getModalId(d))
        .select(".modal-title")
        .attr("id", (d) => d.id + "-title");

      // update save button
      modal_u
        .select(".save-selection")
        .property("disabled", function(d) {
          return !d.valid_selection;
        });

      const modal_body_u = modal_u.select(".modal-body .container-fluid");

      modal_body_u.select(".add-remove-btn").attr("id", getAddRemoveBtnId);
      // spinner id
      modal_u
        .select(".spinner-container")
        .select("div")
        .attr("id", function(d) {
          return d.id + "-spinner-container";
        });

      // Multiple unselected
      const unselected_genome = modal_u
        .select(".multiple-genome-selection.unselected")
        .attr("id", getUnselectedId);

      // the advanced filter of unselected
      const advanced_filter = modal_u
        .select(".advanced-filters")
        .datum(d => [d.not_in_selection_biobs])
        .call(advanced_filter_component);

      const multiple_unselected_genome = unselected_genome
        .datum(function(d) {
          d.not_in_selection_biobs.show = true;
          const data = d.not_in_selection_biobs.data;
          data.eventHandlers = {
            ...data.eventHandlers,
            selectOption: function(data) {
              const genomes = data.filter(d => d.selected);
              const button = modal_u.select(".add-genome");
              if (genomes.length < 1) {
                button.property("disabled", d => true);
              } else {
                button.property("disabled", d => false);
              }
            }
          };
          return [data];
        })
        .call(biob_unselect_component);

      // update the grouping select

      // Multiple Selected
      const selected_genome = modal_u
        .select(".multiple-genome-selection.selected")
        .attr("id", getSelectedId);

      // the advanced filter of selected
      const filter_accordion_u = modal_u
        .select(".advanced-filters.selected")
        .attr("id", getAdvancedFilterCardContainerId)
        .select("div.card");

      filter_accordion_u
        .select(".card-header")
        .attr("id", getAdvancedFilterHeaderId)
        .each(d => {
          $(this).on("show.bs.collapse", function() {
            select(this)
              .select(".card-header")
              .select("i")
              .classed("fa-caret-down", true)
              .classed("fa-caret-right", false);
          });

          $(this).on("hide.bs.collapse", function() {
            select(this)
              .select(".card-header")
              .select("i")
              .classed("fa-caret-down", false)
              .classed("fa-caret-right", true);
          });
        })
        .attr("data-target", d => "#" + getAdvancedFilterCollapseContent(d))
        .attr("aria-controls", getAdvancedFilterCollapseContent);

      filter_accordion_u
        .select("div.collapse")
        .attr("id", getAdvancedFilterCollapseContent)
        .attr("data-parent", getAdvancedFilterCardContainerId);

      filter_accordion_u
        .select("div.card-body")
        .datum(d => [d.in_selection_biobs])
        .call(advanced_filter_component);

      selected_genome
        .datum(function(d) {
          d.in_selection_biobs.show = true;
          const data = d.in_selection_biobs.data;
          data.eventHandlers = {
            ...data.eventHandlers,
            selectOption: function(data) {
              const genome_to_add = data.filter(d => d.selected);
              const button = modal_u.select(".remove-genome");
              if (genome_to_add.length < 1) {
                button.property("disabled", d => true);
              } else {
                button.property("disabled", d => false);
              }
            }
          };

          return [data];
        })
        .call(biob_select_component);

      // update buttons
      modal_u
        .select(".add-genome")
        .property(
          "disabled",
          d =>
            d.not_in_selection_biobs.data.options.filter(it => it.selected)
              .length === 0
        )
        .on("click", d => {
          d.eventHandlers.add(
            d.not_in_selection_biobs.data.options
              .filter(opt => opt.selected)
              .map(opt => opt.value)
          );
        });

      modal_u
        .select(".remove-genome")
        .property(
          "disabled",
          d =>
            d.in_selection_biobs.data.options.filter(it => it.selected)
              .length === 0
        )
        .on("click", d =>
          d.eventHandlers.remove(
            d.in_selection_biobs.data.options
              .filter(opt => opt.selected)
              .map(opt => opt.value)
          )
        );

      // Update alert message

      // The class depends on data but "classed" can't be a function
      // so we use this function to se the "class" attribute directly
      // (see https://stackoverflow.com/questions/17452453).
      function alert_class(d) {
        return d.msg.correct_selection ? "alert alert-info mb-1" : "alert alert-danger mb-1";
      }

      function alert_text(d) {
        return d.msg.message;
      }

      const alert = modal_u
        .select("div.alert-message")
        .selectAll("div.alert")
        .data(function(d) {
          return d.error_msg_data;
        });

      const alert_e = alert
        .enter()
        .append("div")
        .attr("role", "alert")
        .attr("class", alert_class)
        .text(alert_text);

      alert.exit().remove();

      const alert_u = alert_e.merge(alert)
        .attr("class", alert_class)
        .text(alert_text);

      const modal_footer_u = modal_u.select(".modal-footer");

      modal_footer_u
        .select(".clear-selection")
        .select("button")
        .property("disabled", function(d) {
          return d.in_selection_biobs.data.options.length === 0;
        });

      modal_footer_u
        .select("button.save-selection")
        .attr("id", d => d.id + "_save_btn")
        .select(".num-genome-saved")
        .text(function(d) {
          return d.in_selection_biobs.total_biobs;
        });
    });
  }

  function getUnselectedId(d) {
    return d.id + "-unselected";
  }

  function getSelectedId(d) {
    return d.id + "-selected";
  }

  function getModalTitleId(d) {
    return d.id + "-title";
  }
  function getAddRemoveBtnId(d) {
    return d.id + "-add-remove-btn";
  }

  function getAdvancedFilterHeaderId(d) {
    return d.id + "-advanced-filter-heading";
  }
  function getAdvancedFilterCollapseContent(d) {
    return d.id + "-advanced-filter-collapse-content";
  }

  function getAdvancedFilterCardContainerId(d) {
    return d.id + "--advanced-filter-card-container";
  }
  return exports;
}
