//

import {
    getMicgcByOcidById,
    getMicgcCache,
    getNjSpeciesTreeById,
    getOrganismById,
    getOrganismCache,
    getStringifyOrganismById,
    getTaxonomyById,
    getTaxonomyCache
} from "../selector";

import {taxo_rank} from "../reducer/taxonomic-rank";
import RapidNJ from "neighbor-joining";
import {utils} from "phylotree-utils";
import FileSaver from "file-saver";
import store from "../store";

const PhylotreeUtils = utils();

const RapidNeighborJoining = RapidNJ.RapidNeighborJoining;

const outgroup_taxo_obj = {
  gram: "",
  name: "outgroup",
  oid: -1,
  strain: "",
  taxon: 28384,  // NCBI other sequences
  grouping: [],
};

export const getNjSpeciesDataById = function(state, id) {
  const species_tree = getNjSpeciesTreeById(state, id);
  const grouping_options = taxo_rank.map(function(rank) {
    return { type: rank };
  });
  grouping_options.push({ type: "MICGC" });
  const organisms = getOrganismCache(state);
  const taxonomy = getTaxonomyCache(state);
  const micgc = getMicgcCache(state);
  const micgc_by_oids = species_tree.ocids.reduce((accum, ocid) => {
    const micgc_by_ocid = getMicgcByOcidById(state, ocid.toString());
    accum[ocid] = micgc_by_ocid;
    return accum;
  }, {});
  //species_tree.micgc_by_ocid;
  const taxa = constructTaxa(
    state,
    species_tree.oids.map(oid => oid.toString()),
    organisms,
    taxonomy,
    micgc,
    micgc_by_oids,
    taxo_rank
  );
  const nj_species_tree = computeNJTree(species_tree.matrix_nj_tree, taxa);

  return {
    ...species_tree,
    taxa,
    alert_box: [],
    grouping_options,
    species_tree: nj_species_tree,
    eventHandlers: {
      exportSvgTree: function(svgNode) {
        saveSVG(svgNode, "species-tree.svg");
      },
      exportTsvMatrix: function(speciesTree) {
        saveTsvMatrix(store.getState(), speciesTree, "matrix.tsv");
      },
      exportTsvDistance: function(speciesTree) {
        saveDistancePairwise(
          store.getState(),
          speciesTree,
          "species-tree-pairwise-distance.tsv"
        );
      },
      exportNewickTree: function(speciesTreeData, only_taxid=false) {
        saveNewickTree(store.getState(), speciesTreeData, "species-tree.nwk", only_taxid);
      }
    }
  };
};

function computeNJTree(matrix, taxa) {
  const RNJ = new RapidNeighborJoining(matrix, taxa, true);
  RNJ.run();

  const treeObj = RNJ.getAsObject();
  let reroot;
  // Reroot the tree at outgroup node
  try {
    let outgroups = PhylotreeUtils.filter(treeObj, function(node) {
      return node.taxon && node.taxon === outgroup_taxo_obj;
    });
    if (outgroups.length === 1) {
      let outgroup = outgroups[0];
      reroot = PhylotreeUtils.rootTree(outgroup, treeObj);
    } else {
      throw "Did not find the outgroup";
      // reroot = treeObj;
    }
  } catch (e) {
    reroot = treeObj;
  }

  // Remove the subtree that is the outgroup.
  let newroot = reroot.children.find(function(child) {
    // oid = -1 correspond to the outgroup.
    return child.taxon && parseInt(child.taxon.oid) === outgroup_taxo_obj.oid ? false : true;
  });
  console.log(newroot);
  newroot.parent = null;
  newroot.length = 0;
  PhylotreeUtils.eachAfter(newroot, addLabelToInternalNode);
  //data.species_tree = newroot;
  return newroot;
}

function addLabelToInternalNode(node) {
  if (!node.children || node.children.length === 0) {
    node.taxon.grouping = node.taxon.grouping.map(function(grp) {
      grp.children_sum = 1;
      return grp;
    });
  } else {
    // merge children grouping
    let all_grouping = node.children.reduce(function(acc, child) {
      return acc.concat(child.taxon.grouping);
    }, []);
    node.taxon = {
      grouping: all_grouping.reduce(function(accu, elem, index, array) {
        let idx = accu.findIndex(function(el) {
          return el.id === elem.id && el.type === elem.type;
        });

        if (idx >= 0) {
          accu[idx].children_sum += elem.children_sum;
          return accu;
        } else {
          accu.push({
            name: elem.name,
            id: elem.id,
            type: elem.type,
            children_sum: elem.children_sum,
          });
          return accu;
        }
      }, []),
    };
  }
}

function constructTaxa(
  state,
  oids,
  organisms,
  taxonomy,
  micgc,
  micgc_by_ocid,
  taxo_rank
) {
  const accepted_rank_map = new Set(taxo_rank);
  const taxa_nj = oids
    .map(oid => parseInt(oid))
    .sort((a, b) => a - b)
    .map(oid => organisms[oid.toString()])
    .map(function(org) {
      const oid = org.O_id.toString();
      const taxon = getTaxonomyById(state, oid);
      const grouping =
        taxon != null
          ? new Map(
              Object.keys(taxon)
                .map(rank => taxon[rank])
                .sort(function(a, b) {
                  return a.depth - b.depth;
                })
                .map(rank_obj => [
                  rank_obj.rank,
                  convertTaxonomyToGroupingOrg(rank_obj),
                ])
            )
          : new Map();
      // Add micgc grouping
      const groupingOrg = convertMicgctoGroupingOrg(oid, micgc, micgc_by_ocid);
      if (groupingOrg != null) {
        grouping.set("MICGC", groupingOrg);
      }
      return {
        oid: parseInt(oid),
        strain: org.O_strain,
        name: org.O_name,
        gram: org.O_gram,
        taxon: org.O_taxon,
        grouping: Array.from(grouping.values()),
      };
    });

  //////////
  taxa_nj.push(outgroup_taxo_obj);
  return taxa_nj;
}

function convertTaxonomyToGroupingOrg(taxo) {
  return {
    id: taxo.tax_id,
    name: taxo.name_txt,
    type: taxo.rank,
  };
}

function convertMicgctoGroupingOrg(oid, micgcs, micgcs_by_ocid) {
  const micgc = micgcs[oid];
  if (micgc != null) {
    const ocid = micgc.OC_id.toString();
    const size = Object.keys(micgcs_by_ocid[ocid]).length;
    return {
      id: micgc.OC_id,
      name: "MICGC" + String(ocid),
      type: "MICGC",
      count: size,
    };
  } else {
    return null;
  }
}

function saveSVG(svg, file_name) {
  let svg_xml = new XMLSerializer().serializeToString(svg);
  let blob = new Blob([svg_xml], { type: "image/svg+xml;charset=utf-8" });
  FileSaver.saveAs(blob, file_name);
}

function saveNewickTree(state, species_tree, file_name, only_taxid) {
  // Create the recursive function with the value of only_taxid
  // to ease recursion
  function toNewick(node) {
    if (node.children.length == 0) {
      // No recursion
      if (only_taxid) {
        return `${node.taxon.taxon}:${node.length}`
      } else {
       // Strain name may contain reserved characters (";", ",", ":", "(", ")") so we replace them
        let name = `${node.taxon.name} ${node.taxon.strain}`.replace(/[(),:;]/g, "");
        return `${name}:${node.length}`;
      }
    } else {
      // Recursion
      let children_newick = node.children.map(toNewick);
      let str = "(" + children_newick.join(",") + "):" + node.length;
      if (node.parent) {
        return str;
      } else {
        return str + ";";
      }
    }
  }
  let newick = toNewick(species_tree.species_tree);
  // Don't use UTF-8 here because njplot don't read UTF-8 files
  const blob = new Blob([newick], {
    type: "text/plain;charset=iso-8859-1"
  });
  FileSaver.saveAs(blob, file_name);
}

function saveTsvMatrix(state, species_tree, file_name) {
  const matrix = species_tree.matrix;
  // construct the first line:
  const matrix_header_str =
    matrix[0]
      .reduce(
        function(acc, it) {
          if (it.O_id_2 === outgroup_taxo_obj.oid) {
            acc.push(outgroup_taxo_obj.name);
          } else {
            //const organism = getOrganismById(state, it.O_id_2);
            acc.push(
              getPairwiseDistanceString(state, it.O_id_2)
              //[organism.O_name, organism.O_strain, organism.O_taxon].join("\n")
            );
          }
          return acc;
        },
        ["Genomes"]
      )
      .join("\t") + "\n";

  const matrix_str = matrix.reduce((acc, curr, i) => {
    const oid = matrix[0][i].O_id_2;
    const name =
      oid === outgroup_taxo_obj.oid
        ? outgroup_taxo_obj.name
        : getPairwiseDistanceString(state, oid.toString());
    return (
      acc + name + "\t" + curr.map(dist => dist.distance).join("\t") + "\n"
    );
  }, matrix_header_str);
  const blob = new Blob([matrix_str], { type: "text/tsv;charset=utf-8" });
  FileSaver.saveAs(blob, file_name);
}

function getPairwiseDistanceString(state, oid) {
  return (
    getStringifyOrganismById(state, oid.toString()) +
    " " +
    getOrganismById(state, oid.toString()).O_taxon
  );
}

function saveDistancePairwise(state, species_tree, file_name) {
  const distances = species_tree.distance;
  const distances_str = distances
    .map(dist =>
      [
        getPairwiseDistanceString(state, dist.O_id_1),
        getPairwiseDistanceString(state, dist.O_id_2),
        dist.O_id_1,
        dist.O_id_2,
        dist.distance
      ].join("\t")
    )
    .join("\n");
  let blob = new Blob([distances_str], { type: "text/tsv;charset=utf-8" });
  FileSaver.saveAs(blob, file_name);
}
