// 

import {notExistException} from "../utils/exception";

// code start here

export function getFetchObj(state, id) {
  if (state.hasOwnProperty(id)) {
    return state[id];
  } else {
    throw new notExistException(id);
  }
}

export function isFetching(state, id) {
  try {
    return getFetchObj(state, id).isFetching;
  } catch (error) {
    console.log("No Fetch object", error);
  }
}

/**
 * return the flag hasAll of mic-fetch reducer (FetchState).
 *
 * @export
 * @param {FetchState} state
 * @param {string} id Identifier of the biob list
 * @returns {boolean}
 */
export function hasAll(state, id) {
  return getFetchObj(state, id).hasAll;
}
