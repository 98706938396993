// 

// Note: range_status is created null.
// getRangeStatus and setRangeStatus allow to set it.
// However, it's not always up to date (for instance when changing the range).
// It should be created in (redux) selector getRangeStatusById.
// However, some functions here use it (setBiobFlagReducer) so we must be careful (see #7487).

//import
import {combineReducers} from "redux";
import {createNamedWrapperReducer} from "./utils";
import micFetch, * as fromMicFetch from "./mic-fetch";
// Action types
import {
    BIOB_FILTER_ADD,
    BIOB_FILTER_CRITERIA_CHANGE,
    BIOB_FILTER_REMOVE,
    BIOB_FLAG_SET,
    BIOB_GROUPING_SET,
    BIOB_LIST_CREATE,
    BIOB_LIST_UPDATE,
    BIOB_LISTS_SET_CANCEL_STATE,
    BIOB_LISTS_SET_INITIAL_STATE,
    BIOB_LISTS_STATE_HISTORY_PUSH,
    BIOB_LISTS_UNDO,
    BIOBS_IN_SELECTION_ADD,
    BIOBS_IN_SELECTION_REMOVE,
    BIOBS_IN_SELECTION_SET,
    BIOBS_SELECT_GENOME_CART_ADD,
    BIOBS_SELECT_SEQUENCE_CART_ADD,
    SELECTED_BIOBS_RANGE,
    SUGGESTION_ENGINE_PROCESSING_SET,
} from "../action/biob-list";

// Code start here
export const name = "biob_list";

/**
 *
 * return the skeleton of an empty BiobList
 * @export
 * @param {BiobListStateType} type
 * @returns {BiobList}
 */
export function getEmptyBiobList(type) {
  // Initialize variables with value of default label
  let grouping;
  let filter_criteria;
  let biob_name;

  switch (type) {
    case "organism":
    case "my_organism":
    case "refseq_organism":
      grouping = "genus";
      filter_criteria = "Strain_name";
      // Human-friendly name (used in search box text)
      biob_name = "genome"; // The doc use "genome" in this case
      break;

    case "public_genome":
      grouping = "genus";
      filter_criteria = "Strain_name";
      //Human-friendly name (used in search box text)
      biob_name = "public genome";
      break;

    case "refseq_sequence":
    case "pkgdb_sequence":
      grouping = "genus";
      filter_criteria = "Sequence";
      // Human-friendly name (used in search box text)
      biob_name = "sequence";
      break;

    default:
      grouping = "genus";
      filter_criteria = "Sequence";
      biob_name = "sequence";
      break;
  }
  return {
    type,
    biob_name,
    biobs: {},
    range_status: null,
    up_to_date: false,
    selected_biobs_range: [3, 100],
    biob_status: {
      in_selection: {
        true: {
          is_processing_suggestion_engine: false,
          grouping,
          filter_criteria,
          filters: [],
        },
        false: {
          is_processing_suggestion_engine: false,
          grouping,
          filter_criteria,
          filters: [],
        },
      },
    },
  };
}

export const initialState = {
  type: name,
  mic_fetch_state: fromMicFetch.initialState,
  biob_lists: {},
  biob_lists_history: {},
};

/**
 *
 * @export
 * @param {Object} state biob list history state
 * @param {BiobListHistoryAction} action
 * @returns {Object} state
 */
function biobListsHistory(
  state = initialState.biob_lists_history,
  action
) {
  switch (action.type) {
    case BIOB_LISTS_STATE_HISTORY_PUSH: {
      const biobHistory = state[action.biob_list_id];

      if (biobHistory) {
        if (biobHistory.length >= 5) {
          const historySlice = biobHistory.slice(1);
          return {
            ...state,
            [action.biob_list_id]: {
              ...history,
                states: [
                  ...historySlice,
                  { ...action.biobList },
                ]
            },
          };
        } else {
          return {
            ...state,
            [action.biob_list_id]: {
              ...biobHistory,
              states: [
                ...biobHistory.states,
                { ...action.biobList }
              ],
            }
          };
        }
      } else {
        // Create the history with current biobList as initialState and cancelState
        return {
          ...state,
          [action.biob_list_id]: {
            initialState: action.biobList,
            cancelState: action.biobList,
            states: []
          }
        };
      }
    }

    case BIOB_LISTS_SET_INITIAL_STATE: {
      const biobHistory = state[action.biob_list_id];
      return {
        ...state,
        [action.biob_list_id]: {
          ...biobHistory,
          initialState: action.biobList
        }
      };
    }

    case BIOB_LISTS_SET_CANCEL_STATE: {
      const biobHistory = state[action.biob_list_id];
      return {
        ...state,
        [action.biob_list_id]: {
          ...biobHistory,
          cancelState: action.biobList
        }
      };
    }

    default:
      return state;
  }
}

/**
 * Global reducer that handle all the biob-list actions.
 *
 * @param {BiobLists} [state={}]
 * @param {BiobListAction} action
 * @returns {BiobLists}
 */
function biobLists(state = {}, action) {
  switch (action.type) {
    case BIOB_LISTS_UNDO:
      return {
        ...state,
        [action.biob_list_id]: {
          ...action.state,
        },
      };

    case BIOBS_IN_SELECTION_ADD:
      return addRmBiobInSelection(state, action, true);

    case BIOBS_IN_SELECTION_REMOVE:
      return addRmBiobInSelection(state, action, false);

    case BIOBS_SELECT_GENOME_CART_ADD:
      return addRmBiobInSelection(state, action, true);

    case BIOBS_SELECT_SEQUENCE_CART_ADD:
      return addRmBiobInSelection(state, action, true);

    case BIOBS_IN_SELECTION_SET:
      return setBiobInSelection(state, action);

    case BIOB_FLAG_SET:
      return setBiobFlagReducer(state, action);

    case BIOB_GROUPING_SET:
      return setBiobGrouping(state, action);

    case BIOB_LIST_CREATE:
      return createBiobList(state, action);

    case BIOB_LIST_UPDATE:
      return updateBiobList(state, action);

    case BIOB_FILTER_ADD:
      return addBiobFilter(state, action);

    case BIOB_FILTER_REMOVE:
      return removeBiobFilter(state, action);

    case BIOB_FILTER_CRITERIA_CHANGE:
      return changeBiobFilterCriteria(state, action);

    case SUGGESTION_ENGINE_PROCESSING_SET:
      return setSuggestionEngineProcessing(state, action);

    case SELECTED_BIOBS_RANGE:
      return setSelectedBiobsRange(state, action);

    default:
      return state;
  }
}

/**
 *
 *
 * @param {*} state
 * @param {*} action
 * @returns
 */
function setSelectedBiobsRange(state, action) {
  const { range, biob_list_id } = action;
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      selected_biobs_range: range,
    },
  };
}

/**
 * Case reducer.
 * Set the grouping criteria (taxonomic rank) for a genome selection.
 * Function that creates a new state with the grouping criteria changed.
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns {BiobLists}
 */
function setBiobGrouping(state, action) {
  const { biob_list_id, status, status_value, rank } = action;

  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biob_status: {
        ...state[biob_list_id].biob_status,
        [status]: {
          ...state[biob_list_id].biob_status[status],
          [status_value]: {
            ...state[biob_list_id].biob_status[status][status_value],
            grouping: rank,
          },
        },
      },
    },
  };
}

/**
 * Case Reducer.
 * Create a new biob-list
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns {BiobLists}
 */
function createBiobList(state, action) {
  const { biob_list_id, biob_type, selected_biobs_range } = action;
  return {
    ...state,
    ...{
      [biob_list_id]: {
        ...getEmptyBiobList(biob_type),
        type: biob_type,
        selected_biobs_range,
      },
    },
  };
}

/**
 * Case reducer that set the flag up_to_date to true
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns
 */
function updateBiobList(state, action) {
  const { biob_list_id, biobs } = action;
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      up_to_date: true,
      biobs: {
        ...biobs,
        ...state.biobs,
      },
    },
  };
}

/**
 * Case reducer that add a filter to the biob selection.
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns
 */
function addBiobFilter(state, action) {
  const { biob_list_id, status, status_value, filter_ids } = action;
  const currentStatus = state[biob_list_id].biob_status[status];
  const filter_list = currentStatus[action.status_value].filters;
  const uniq_filters = new Set([...filter_list, ...action.filter_ids]);
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biob_status: {
        ...state[biob_list_id].biob_status,
        [status]: {
          ...currentStatus,
          [status_value]: {
            ...currentStatus[status_value],
            filters: [...uniq_filters],
          },
        },
      },
    },
  };
}

/**
 * Case reducer that remove a filter to the biob selection.
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @return {BiobLists} state
 */
function removeBiobFilter(state, action) {
  const { biob_list_id, status, status_value, filter_ids } = action;
  const to_rm_status = state[biob_list_id].biob_status[status];
  const to_rm_filter_list = to_rm_status[status_value].filters;
  const filter_ids_to_rm = new Set(filter_ids);
  const filter_to_keep = to_rm_filter_list.filter(
    filter_id => !filter_ids_to_rm.has(filter_id)
  );
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biob_status: {
        ...state[biob_list_id].biob_status,
        [status]: {
          ...to_rm_status,
          [status_value]: {
            ...to_rm_status[status_value],
            filters: [...filter_to_keep],
          },
        },
      },
    },
  };
}
/**
 * Case reducer that change the filter criteria (Taxonomy, MICGC, Strain, ...)
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @return {BiobLists} state
 */
function changeBiobFilterCriteria(state, action) {
  const { biob_list_id, status, status_value, filter_criteria } = action;
  const status_criteria_change = state[biob_list_id].biob_status[status];
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biob_status: {
        ...state[biob_list_id].biob_status,
        [status]: {
          ...status_criteria_change,
          [status_value]: {
            ...status_criteria_change[status_value],
            filter_criteria: filter_criteria,
          },
        },
      },
    },
  };
}
/**
 *
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns
 */
function setSuggestionEngineProcessing(
  state,
  action
) {
  const { in_selection, biob_list_id, is_processing } = action;
  const value_processing_status = in_selection.toString();
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biob_status: {
        ...state[biob_list_id].biob_status,
        in_selection: {
          ...state[biob_list_id].biob_status.in_selection,
          [value_processing_status]: {
            ...state[biob_list_id].biob_status.in_selection[
              value_processing_status
            ],
            is_processing_suggestion_engine: is_processing,
          },
        },
      },
    },
  };
}
/**
 *
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns
 */
function setBiobFlagReducer(state, action) {
  const {
    biob_list_id,
    biob_ids,
    flag: { name, value },
  } = action;
  const new_state_set = setStateWithBiobFlag(
    state,
    biob_list_id,
    biob_ids,
    name,
    value
  );
  const status = getRangeStatus(new_state_set[biob_list_id]);
  return setRangeStatus(new_state_set, biob_list_id, status);
}

/**
 *
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @returns
 */
function setBiobInSelection(state, action) {
  const { biob_list_id, biob_ids } = action;
  const biobIdsSet = new Set(biob_ids);
  const biob_list_to_change = state[biob_list_id];
  const biobs_to_change = biob_list_to_change.biobs;
  // set all the entries to false
  Object.values(biobs_to_change).forEach(
    biob => (biob.in_selection = biobIdsSet.has(biob.id))
  );
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biobs: {
        ...biobs_to_change,
      },
    },
  };
}




/**
 *
 *
 * @param {BiobLists} state
 * @param {BiobListAction} action
 * @param {boolean} selected
 * @returns
 */
function addRmBiobInSelection(
  state,
  action,
  selected
) {
  const { biob_list_id, biob_ids } = action;
  const biob_list_to_change = state[biob_list_id];
  const biobs_to_change = biob_list_to_change.biobs;
  const all_biobs_ids = Object.keys(biobs_to_change)
    .map(key => biobs_to_change[key])
    .map(biob => biob.id);

  // toggle biobs.
  const new_selected_state_1 = setStateWithBiobFlag(
    state,
    biob_list_id,
    biob_ids,
    "in_selection",
    selected
  );
  // handle the selected_attr flag
  const new_selected_state_2 = setStateWithBiobFlag(
    new_selected_state_1,
    biob_list_id,
    all_biobs_ids,
    "selected_attr",
    false
  );
  const new_selected_state_3 = setStateWithBiobFlag(
    new_selected_state_2,
    biob_list_id,
    biob_ids,
    "selected_attr",
    selected
  );
  // Get the range status
  const status = getRangeStatus(
    new_selected_state_3[biob_list_id]
  );
  return setRangeStatus(
    new_selected_state_3,
    action.biob_list_id,
    status
  );
}



/**
 *
 *
 * @param {BiobList} biob_list
 * @returns {?string}
 */
function getRangeStatus(biob_list) {
  const biobs = biob_list.biobs;
  const biob_length = Object.keys(biobs)
    .map(key => biobs[key])
    .filter(biob => biob.in_selection).length;
  const range = biob_list.selected_biobs_range;
  const biob_name = biob_list.biob_name + "(s)";
  const max_biobs = range[1];
  const min_biobs = range[0];

  const correct_selection = (biob_length >= min_biobs) && (biob_length <= max_biobs);
  let message;

  // Selection is OK
  if (correct_selection) {
    if (max_biobs === Infinity) {
      message = ["Select", min_biobs, "or more", biob_name];
    } else {
      message = ["Select between", min_biobs, "and", max_biobs, biob_name];
    }
  } else {
    // Too much biobs
    if (biob_length > max_biobs) {
      message = ["Please remove at least", (biob_length - max_biobs), biob_name];
    }
    // Not enough biobs
    if (biob_length < min_biobs) {
      if (max_biobs === Infinity) {
        message = ["Please select", min_biobs, "or more", biob_name];
      } else {
        message = ["Please select between", min_biobs, "and", max_biobs, biob_name];
      }
    }
  }

  return {correct_selection: correct_selection, message: message.join(" ") + "."};
}

function setStateWithBiobFlag(
  state,
  biob_list_id,
  biob_ids,
  biob_status_name,
  biob_status_value
) {
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      biobs: setBiobFlag(
        biob_ids,
        state[biob_list_id].biobs,
        biob_status_name,
        biob_status_value
      ),
    },
  };
}

function setRangeStatus(
  state,
  biob_list_id,
  status
) {
  return {
    ...state,
    [biob_list_id]: {
      ...state[biob_list_id],
      range_status: status,
    },
  };
}

function setBiobFlag(
  ids,
  biobs,
  flag,
  bool
) {
  const new_biobs = ids.reduce(function(new_biobs, id) {
    if (biobs.hasOwnProperty(id)) {
      new_biobs[id] = {
        ...biobs[id],
        [flag]: bool,
      };
    }
    return new_biobs;
  }, {});
  return { ...biobs, ...new_biobs };
}

const micFetchReducer = createNamedWrapperReducer(
  micFetch,
  name,
  initialState.mic_fetch_state
);

/**
 * Combine all the reducers define for the biob-list.
 * It will handle all the actions that concern the biob-list.
 */
const fetchBiobList = combineReducers({
  biob_lists_history: biobListsHistory,
  biob_lists: biobLists,
  mic_fetch_state: micFetchReducer,
});


export default fetchBiobList;

