//

// import
import {createNamedWrapperReducer} from "./utils";
import micFetch, * as fromMicFetch from "./mic-fetch";
import oidCache, * as fromOidCache from "./cache";
import * as fromTaxonomicRank from "./taxonomic-rank";

export function taxonomyInit(name) {
  const initialState = {
    type: name,
    cache: fromOidCache.initialState,
    taxo_rank: fromTaxonomicRank.taxo_rank,
    mic_fetch_state: fromMicFetch.initialState
  };

  const micFetchReducer = createNamedWrapperReducer(
    micFetch,
    name,
    initialState.mic_fetch_state
  );
  const oidCacheReducer = createNamedWrapperReducer(
    oidCache,
    name,
    initialState.cache
  );

  function setType(state = initialState.type) {
    return state;
  }

  return {
    type: setType,
    cache: oidCacheReducer,
    taxo_rank: fromTaxonomicRank.taxonomicRank,
    mic_fetch_state: micFetchReducer
  }
}
