//

export const FILTER_ADD = "FILTER_ADD";
export const FILTER_REMOVE = "FILTER_REMOVE";


export function addFilter(
  filter_id,
  filter_type,
  filter_subtype,
  matching_type,
  value,
  filter_name
) {
  return {
    type: FILTER_ADD,
    filter_id,
    filter_type,
    filter_subtype,
    matching_type,
    value,
    filter_name
  };
}

export function removeFilter(filter_id) {
  return {
    type: FILTER_REMOVE,
    filter_id
  };
}
