// 
import {ofType} from "redux-observable";

// import
import {Observable, of} from "rxjs";
import {filter, mergeMap, startWith, take, withLatestFrom, zip,} from "rxjs/operators";
import {
    getBiobListById,
    getListMyOrganism,
    getListOrganism,
    getListPublicGenome,
    getListRefseqOrganism,
    getListRefseqSequence,
    getListSequence,
} from "../selector";
import {ofTypeAndName} from "./mic-fetch";
// Reducer
import {name as biobListName} from "../reducer/biob-list";
import {
    speciesMetadataName,
    strainMetadataName,
    refseqTaxoName,
    pkgdbTaxoName,
    favoriteGenomesName,
    refseqSequenceName,
    pkgdbSequenceName,
    refseqOrganismName,
    pkgdbOrganismName,
    pkgdbMyOrganismName,
    publicGenomeName,
    micgcName,
    genomeCartsName,
    sequenceCartsName
} from "../reducer/reducer-declaration";
// Action
import {
    addBiobsInSelection,
    BIOB_LIST_CREATE,
    fetchBiobList,
    fetchBiobListSuccess,
    pushBiobListsStateToHistory,
    updateBiobsList,
} from "../action/biob-list";
import {SUCCESS_FETCH_TYPE, BIOB_LIST_FETCH_TYPE, fetchAction, BIOB_LIST_SUCCESS_FETCH_TYPE} from "../action/mic-fetch";

// Code start here


/**
 * React to a BiobListCreate action and 
 * Epic that will dispatch fetch actions when a new biob list is created (an action BIOB_LIST_CREATE is dispatched).
 *
 * @export
 * @param {ActionsObservable<BiobListCreate>} action$ Stream of BiobListCreate action
 * @param {Object} store The application store
 * @return {ActionsObservable<BiobListFetchAction>} Stream of BiobListFetchAction action
 */
export function createBiobListEpic(
  action$,
  store
) {
  return action$.pipe(
    ofType(BIOB_LIST_CREATE),
    mergeMap((action) => {
      return of(
        fetchBiobList(
          "list",
          action.biob_type,
          action.biob_list_id,
          action.multiple,
          action.preselected_biob_ids
        )
      );
    })
  );
}
/**
 * React to a biob list fetch, get all the biobs annotation and dispatch : 
 * - updateBiobList
 * - addBiobsInSelection
 * - fetchBiobListSuccess
 * actions.
 * Actual dispatch is done in functions:
 *   - fetchRefseqSequenceBiobList
 *   - fetchRefseqOrganismBiobList
 *   - fetchPkgdbOrganismBiobList
 *   - fetchPkgdbMyOrganismBiobList
 *   - fetchPkgdbSequenceBiobList
 *   - fetchPublicGenomeBiobList
 *
 * @export
 * @param {ActionsObservable<BiobListFetchAction>} action$
 * @param {Observable<MicState>} state$
 * @returns {*}
 */
export function fetchBiobListEpic(
  action$,
  state$
) {
  return action$.pipe(
    ofTypeAndName(BIOB_LIST_FETCH_TYPE, biobListName),
    mergeMap((action) => {
      switch (action.biob_type) {
        case "refseq_sequence":
          return fetchRefseqSequenceBiobList(action, action$, state$);
        case "refseq_organism":
          return fetchRefseqOrganismBiobList(action, action$, state$);
        case "organism":
          return fetchPkgdbOrganismBiobList(action, action$, state$);
        case "my_organism":
          return fetchPkgdbMyOrganismBiobList(action, action$, state$);
        case "sequence":
          return fetchPkgdbSequenceBiobList(action, action$, state$);
        case "public_genome":
          return fetchPublicGenomeBiobList(action, action$, state$);

        default:
          return of(null);
      }
    })
  );
}

/**
 * Dispatch events when fetching of public_genome is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchPublicGenomeBiobList(action, action$, state$) {
  const { param } = action;
  return action$.pipe(
    zip(action$.pipe(successFetchBiob(publicGenomeName, param))),
    withLatestFrom(state$),
    mergeMap(([[pg_fetch, pg_succes], state]) => {
      const biobs = getListPublicGenome(state);
      return of(
        updateBiobsList(action.biob_type, action.biob_list_id, biobs),
        addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
        fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
      );
    }),
    startWith(fetchAction(publicGenomeName,param))
  );
}

/**
 * Dispatch events when fetching of organism is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchPkgdbOrganismBiobList(action, action$, state$) {
  const orga_zip = action.multiple
    ? zip(
        action$.pipe(successFetchBiob(pkgdbOrganismName, "list")),
        action$.pipe(successFetchBiob(pkgdbTaxoName, "list")),
        action$.pipe(successFetchBiob(micgcName, "list")),
        action$.pipe(successFetchBiob(strainMetadataName, "list")),
        action$.pipe(successFetchBiob(speciesMetadataName, "list")),
        action$.pipe(successFetchBiob(genomeCartsName, "list")),
        action$.pipe(successFetchBiob(favoriteGenomesName, "list"))
      )
    : zip(
        action$.pipe(successFetchBiob(pkgdbOrganismName, "list")),
        action$.pipe(successFetchBiob(favoriteGenomesName, "list"))
      );

  const orga_start_with = action.multiple
    ? startWith(
        fetchAction(pkgdbOrganismName,"list"),
        fetchAction(pkgdbTaxoName,"list"),
        fetchAction(micgcName,"list"),
        fetchAction(strainMetadataName,"list"),
        fetchAction(speciesMetadataName,"list"),
        fetchAction(genomeCartsName,"list"),
        fetchAction(favoriteGenomesName,"list")
      )
    : startWith(
        fetchAction(pkgdbOrganismName,"list"),
        fetchAction(favoriteGenomesName,"list")
      );

  return action$.pipe(
    orga_zip,
    withLatestFrom(state$),
    mergeMap(([sucesses, state]) => {
      const biobs = getListOrganism(state);
      return of(
        updateBiobsList(action.biob_type, action.biob_list_id, biobs),
        addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
        fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
      );
    }),
    orga_start_with
  );
}

/**
 * Dispatch events when fetching of my organism is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchPkgdbMyOrganismBiobList(action, action$, state$) {
   const orga_zip = action.multiple
       ? zip(
           action$.pipe(successFetchBiob(pkgdbMyOrganismName, "list")),
           action$.pipe(successFetchBiob(pkgdbTaxoName, "list")),
           action$.pipe(successFetchBiob(micgcName, "list")),
           action$.pipe(successFetchBiob(strainMetadataName, "list")),
           action$.pipe(successFetchBiob(speciesMetadataName, "list")),
           action$.pipe(successFetchBiob(genomeCartsName, "list")),
           action$.pipe(successFetchBiob(favoriteGenomesName, "list"))
       )
       : zip(
           action$.pipe(successFetchBiob(pkgdbMyOrganismName, "list")),
           action$.pipe(successFetchBiob(favoriteGenomesName, "list"))
       );

   const orga_start_with = action.multiple
       ? startWith(
           fetchAction(pkgdbMyOrganismName,"list"),
           fetchAction(pkgdbTaxoName,"list"),
           fetchAction(micgcName,"list"),
           fetchAction(strainMetadataName,"list"),
           fetchAction(speciesMetadataName,"list"),
           fetchAction(genomeCartsName,"list"),
           fetchAction(favoriteGenomesName,"list")
       )
       : startWith(
           fetchAction(pkgdbMyOrganismName,"list"),
           fetchAction(favoriteGenomesName,"list")
       );

 return action$.pipe(
   orga_zip,
   withLatestFrom(state$),
   mergeMap(([sucesses, state]) => {
     const biobs = getListMyOrganism(state);
     return of(
       updateBiobsList(action.biob_type, action.biob_list_id, biobs),
       addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
       fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
     );
   }),
   orga_start_with
 );

}

/**
 * Dispatch events when fetching of sequence is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchPkgdbSequenceBiobList(action, action$, state$) {
  const seq_zip = action.multiple
    ? zip(
        action$.pipe(successFetchBiob(pkgdbSequenceName, "list")),
        action$.pipe(successFetchBiob(pkgdbOrganismName, "list")),
        action$.pipe(successFetchBiob(pkgdbTaxoName, "list")),
        action$.pipe(successFetchBiob(strainMetadataName, "list")),
        action$.pipe(successFetchBiob(speciesMetadataName, "list")),
        action$.pipe(successFetchBiob(micgcName, "list")),
        action$.pipe(successFetchBiob(sequenceCartsName, "list"))
      )
    : zip(
        action$.pipe(successFetchBiob(pkgdbOrganismName, "list")),
        action$.pipe(successFetchBiob(pkgdbSequenceName, "list"))
      );

  const seq_start_with = action.multiple
    ? startWith(
        fetchAction(pkgdbOrganismName,"list"),
        fetchAction(pkgdbTaxoName,"list"),
        fetchAction(micgcName,"list"),
        fetchAction(strainMetadataName,"list"),
        fetchAction(speciesMetadataName,"list"),
        fetchAction(pkgdbSequenceName,"list"),
        fetchAction(sequenceCartsName, "list")
      )
    : startWith(
        fetchAction(pkgdbOrganismName,"list"),
        fetchAction(pkgdbSequenceName,"list")
      );

  return action$.pipe(
    seq_zip,
    withLatestFrom(state$),
    mergeMap(([sucesses, state]) => {
      const biobs = getListSequence(state);
      return of(
        updateBiobsList(action.biob_type, action.biob_list_id, biobs),
        addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
        fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
      );
    }),
    seq_start_with
  );
}

/**
 * Dispatch events when fetching of refseq_sequence is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchRefseqSequenceBiobList(action, action$, state$) {
  const biob_zip = action.multiple
    ? zip(
        action$.pipe(successFetchBiob(refseqOrganismName, "list")),
        action$.pipe(successFetchBiob(refseqSequenceName, "list")),
        action$.pipe(successFetchBiob(refseqTaxoName, "list")),
        action$.pipe(successFetchBiob(strainMetadataName, "list")),
        action$.pipe(successFetchBiob(speciesMetadataName, "list")),
      )
    : zip(
        action$.pipe(successFetchBiob(refseqOrganismName, "list")),
        action$.pipe(successFetchBiob(refseqSequenceName, "list"))
      );

  const start_with = action.multiple
    ? startWith(
        fetchAction(refseqOrganismName,"list"),
        fetchAction(refseqSequenceName,"list"),
        fetchAction(refseqTaxoName,"list"),
        fetchAction(strainMetadataName,"list"),
        fetchAction(speciesMetadataName,"list")
      )
    : startWith(
        fetchAction(refseqOrganismName,"list"),
        fetchAction(refseqSequenceName,"list")
      );

  return action$.pipe(
    biob_zip,
    withLatestFrom(state$),
    mergeMap(([sucesses, state]) => {
      const biobs = getListRefseqSequence(state);
      return of(
        updateBiobsList(action.biob_type, action.biob_list_id, biobs),
        addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
        fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
      );
    }),
    start_with
  );
}

/**
 * Dispatch events when fetching of refseq_organism is done
 *
 * @param {*} action
 * @param {*} action$
 * @param {*} state$
 * @returns
 */
function fetchRefseqOrganismBiobList(action, action$, state$) {
  const biob_zip = action.multiple
    ? zip(
        action$.pipe(successFetchBiob(refseqOrganismName, "list")),
        action$.pipe(successFetchBiob(refseqTaxoName, "list")),
        action$.pipe(successFetchBiob(strainMetadataName, "list")),
        action$.pipe(successFetchBiob(speciesMetadataName, "list")),
      )
    : zip(action$.pipe(successFetchBiob(refseqOrganismName, "list")));

  const start_with = action.multiple
    ? startWith(
        fetchAction(refseqOrganismName, "list"),
        fetchAction(refseqTaxoName,"list"),
        fetchAction(strainMetadataName,"list"),
        fetchAction(speciesMetadataName,"list"),
      )
    : startWith(fetchAction(refseqOrganismName, "list"));

  return action$.pipe(
    biob_zip,
    withLatestFrom(state$),
    mergeMap(([sucesses, state]) => {
      const biobs = getListRefseqOrganism(state);
      return of(
        updateBiobsList(action.biob_type, action.biob_list_id, biobs),
        addBiobsInSelection(action.preselected_biob_ids, action.biob_list_id),
        fetchBiobListSuccess(action.biob_list_id, action.param, biobs)
      );
    }),
    start_with
  );
}

/**
 *
 *
 * @export
 * @param {ActionsObservable<BiobListFetchAction>} action$
 * @param {Observable<MicState>} state$
 * @returns
 */
export function addToBiobListHistory(
  action$,
  state$
) {
  return action$.pipe(
    ofTypeAndName(BIOB_LIST_SUCCESS_FETCH_TYPE, biobListName),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const biobList = getBiobListById(state, action.id);
      return of(pushBiobListsStateToHistory(biobList, action.id));
    })
  );
}
/**
 *
 *
 * @export
 * @param {string} name
 * @param {*} param
 * @returns
 */
export function successFetchBiob(name, param) {
  return (source) =>
    source.pipe(
      ofTypeAndName(SUCCESS_FETCH_TYPE, name),
      filter(action => action.param === param),
      take(1)
    );
}
