import {dispatch} from "d3-dispatch";
import {select} from "d3-selection";
import "d3-transition";

export default function SpeciesClusterLabel() {
  // Private
  var label_class = "node-label";
  var rank;
  var color;
  var letterWidth = 7;
  var nb_genomes_max = 200;
  // Public
  exports.dispatcher = dispatch("node_label_click");
  function exports(_selection, user_label_class, limit_nb_genomes) {
    label_class = user_label_class ? user_label_class : label_class;
    _selection.each(function(_data) {
      var container = select(this);

      var label = container.selectAll("g." + label_class).data(function(d) {
        return [d];
      });

      // ENTER
      var label_enter = label
        .enter()
        .append("g")
        .attr("transform", "translate(8,0)")
        .classed(label_class, true);

      label_enter
        .append("a")
        .classed("strain-link", true)
        .append("text")
        .attr("alignment-baseline", "middle")
        .classed("strain", true);

      label_enter
        .append("text")
        .attr("alignment-baseline", "middle")
        .classed("color-attribute", true);

      label_enter
        .append("text")
        .attr("alignment-baseline", "middle")
        .classed("species-cluster", true);

      label_enter.append("title").classed("species-cluster", true);

      // UPDATE
      var label_update = label_enter.merge(label);

      label_update
        .select("a.strain-link")
        .attr("href", function(d) {
          if (d.data && d.data.taxon && d.data.taxon.oid) {
          return "../genomic/overview.php?O_id=" + d.data.taxon.oid;
          }
          else {
            return "#";
          }
        })
        .attr("target", "_blank")
        .select("text.strain")
        .style("fill", setColor)
        .text(getStrainLabel);

      label_update
        .select("text.color-attribute")
        .style("fill", setColor)
        .text(getColorLabel);

      label_update
        .select("text.species-cluster")
        .on("click", function(d) {
          exports.dispatcher.call("node_label_click", this, d, d.data.taxon.oid);
        })
        .classed("singleton", function(d) {
          if (d.data.taxon && d.data.taxon.grouping) {
            return d.data.taxon.grouping.reduce(function(bool, grp) {
              if (
                grp.type === "MICGC" &&
                (grp.count < 3 || grp.count > nb_genomes_max)
              ) {
                return true;
              } else {
                return bool;
              }
            }, false);
          } else {
            return false;
          }
        })
        .style("fill", setColor)
        .text(getSpeciesClusterLabel);

      label_update
        .select("title.species-cluster")
        .filter(function(d) {
          return !d.children;
        })
        .text(function(d) {
          var micgc_sum = d.data.taxon.grouping
            .filter(function(it) {
              return it.type === "MICGC";
            })
            .reduce(
              function(acc, curr) {
                acc.micgc = curr.id;
                acc.count += parseInt(curr.count);
                return acc;
              },
              { count: 0, micgc: null }
            );
          const count = micgc_sum.count;
          if (count === 0) {
            return "This genome doesn't belong to a cluster. Either it is contaminated or incomplete.";
          }
          const end_title = " in the cluster " + micgc_sum.micgc;

          return count > 1
            ? count + " genomes" + end_title
            : count + " genome" + end_title;
        });

      var all_labels = label_update.selectAll("text");

      all_labels.attr("x", function(d) {
        var previous_siblings = [];
        var previous_sibling = this.previousElementSibling;
        while (previous_sibling) {
          previous_siblings.push(previous_sibling);
          previous_sibling = previous_sibling.previousElementSibling;
        }
        var left_width = previous_siblings.reduce(function(acc, curr) {
          //var curr_width = curr.getBBox().width;
          const text_length = curr.textContent.length;
          return acc += (text_length + 1) * letterWidth - 5;
          // return (acc +=
          //   curr_width == 0 ? 0 : curr.getBBox().width + letterWidth);
        }, 0);
        return left_width;
      });

      // EXIT
      label.exit().remove();
    });
  }

  exports.getWidth = function(nodes) {
    return nodes.reduce(function(prev, curr) {
      var labelL = getAllLabels(curr).length * letterWidth;
      return labelL > prev ? labelL : prev;
    }, 0);
  };
  exports.rank = function(_) {
    return arguments.length ? ((rank = _), exports) : rank;
  };
  exports.color = function(_) {
    return arguments.length ? ((color = _), exports) : color;
  };
  exports.nb_genomes_max = function(_) {
    return arguments.length ? ((nb_genomes_max = _), exports) : nb_genomes_max;
  };
  exports.toggleHighlight = function(parent) {
    parent.select("text").style("font-weight", "bold");
  };
  exports.toggleNormal = function(parent) {
    parent.select("text").style("font-weight", "normal");
  };

  function getGroup(d, rank) {
    if (d.data.taxon && d.data.taxon.grouping) {
      if (d._children || d.children) {
        var label = d.data.taxon.grouping
          .filter(function(el) {
            return el.type === rank;
          })
          .sort(function(a, b) {
            return b.children_sum - a.children_sum;
          })
          .reduce(function(acc, el) {
            acc.push(el.name + "#" + el.children_sum);
            return acc;
          }, [])
          .join("/");

        return label.length > 100 ? label.substr(0, 100) + "..." : label;
      } else {
        return d.data.taxon.grouping
          .filter(function(grp) {
            return grp.type === rank;
          })
          .reduce(function(acc, el) {
            acc.push(el.name);
            return acc;
          }, [])
          .join("-");
      }
    } else {
      return null;
    }
  }
  function getStrainLabel(d) {
    if (d.data.taxon) {
      const name = d.data.taxon.name || "";
      const strain = d.data.taxon.strain || "";
      return name + " " + strain;
    } else {
      return "";
    }
  }
  function getSpeciesClusterLabel(d) {
    var group = getGroup(d, "MICGC");
    if (group && !d.children) {
      if (d._children) {
        return group;
      } else {
        return "-- [" + group + "]";
      }
    } else {
      return "";
    }
  }
  function setColor(d) {
    var c = "";
    var group = getGroup(d, rank);
    return group ? color(group) : c;
  }
  function getColorLabel(d) {
    var group = getGroup(d, rank);
    if (group && !d.children && !d._children) {
      return "(" + group + ")";
    } else {
      return "";
    }
  }
  function getAllLabels(d) {
    return getStrainLabel(d) + getColorLabel(d) + getSpeciesClusterLabel(d);
  }

  return exports;
}
