// 
//import

// Action types
export const CACHE_TYPE = "CACHE";
export const CACHE_REMOVE = "CACHE_REMOVE";

/**
 * construct a cacheData Action
 *
 * @export
 * @template P
 * @template D
 */
export function cacheData(
  id,
  param,
  data,
  name
) {
  return {
    type: CACHE_TYPE,
    id,
    param,
    name,
    data_to_cache: data
  };
}

/**
 * Construct an action that remove the cache
 *
 * @export
 * @param {string[]} ids
 * @param {string} name
 * @returns {CacheRemove}
 */
export function removeCacheData(ids, name) {
  return {
    type: CACHE_REMOVE,
    name,
    ids
  };
}
