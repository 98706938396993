// 

import { select } from "d3-selection";
import "d3-transition";
import { nest } from "d3-collection";
import { SimpleSelect } from "../utils/bio-viz";
import BiobListMultipleSelectorEditor from "./biob-list-multiple-selector-editor";
import BiobListMultipleSelectorCart from "./biob-list-multiple-selector-cart";
import BiobListAccordion from "./biob-list-as-accordion";
/**
 * Root d3 component that draw the multiple selector.
 * @example
 * const component = biobListMultipleSelector();
 * selection.datum([data]).call(component, width, height);
 *
 * @export
 * @return {function} Function that will be executed with: `selection.call(biobListMultipleSelector)`
 */
export default function biobListMultipleSelector(
    hide_rapid_selector
) {
  const biob_list_accordion_compo = BiobListAccordion();
  const biob_list_multiple_selector = BiobListMultipleSelectorEditor();
  const biob_list_multiple_selector_cart = BiobListMultipleSelectorCart();
  const grouping_select_component = SimpleSelect();

  const color_theme = "dark";

  function exports(selection, grid_width, max_height) {
    selection.each(function(datum) {
      var container = select(this);
      var genome_selection = container
        .selectAll("div.biob-selection")
        .data(datum, d => d.id);

      genome_selection.exit().remove();

      var genome_selection_e = genome_selection
        .enter()
        .append("div")
        .classed("biob-selection", true);

      var global_card = genome_selection_e.append("div").classed("card", true);

      var navbar = global_card
        .append("div")
        .classed("card-header py-0 px-1 bg-microscope-dark", true)
        .append("nav")
        .classed(
          "navbar navbar-expand-sm navbar-" +
            color_theme +
            " bg-microscope-dark",
          true
        );

      navbar
        .append("a")
        .classed("navbar-brand mr-1", true)
        .style("cursor", "default")
        .style("font-size", "1rem")
        .attr("href", "#");

      //navbar.append("span").classed("badge brand mr-5", true);

      navbar
        .append("button")
        .classed("navbar-toggler", true)
        .attr("type", "button")
        .attr("data-toggle", "collapse")
        .attr("data-target", d => "#" + getNavbarContentId(d))
        .attr("aria-controls", "navbar")
        .append("span")
        .classed("navbar-toggler-icon", true);

      var navbar_form = navbar
        .append("div")
        .classed("collapse navbar-collapse", true)
        .attr("id", getNavbarContentId)
        .append("form")
        .classed("form-inline ml-auto", true);

      navbar_form
        .append("button")
        .classed("btn btn-light mx-2 my-2 my-sm-0", true)
        .on("click", function(d) {
          d.eventHandlers.editSelector();
          $("#" + getModalId(d)).modal("toggle");
        })
        .attr("type", "button")
        .append("i")
        .classed("fa fa-pencil fa-lg", true)
        .attr("aria-hidden", true);

//TODO: Ajouter une vérification sur le nombre de genome cart
      navbar_form
        .append("button")
        .classed("btn btn-light mx-2 my-2 my-sm-0", true)
        .attr("id", function(d){
          return d.id + "_cart_selector";
        })
        .on("click", function(d) {
          d.eventHandlers.editSelector();
          $("#" + getModalCartId(d)).modal("toggle");
        })
        .attr("type", "button")
        .property("hidden", hide_rapid_selector)
        .append("i")
        .classed("fa fa-star fa-lg", true)
        .attr("aria-hidden", true);

      navbar_form.append("div").classed("grouping-select mx-2", true);

      var global_body = global_card
        .append("div")
        .classed("card-body text-center genome-selection-body", true);

      global_body.append("div").classed("row spinner-container", true);

      global_body
        .append("div")
        .classed("row row-accordion", true)
        .style("overflow-y", "auto")
        .append("div")
        .classed("col genome-selection-accordion", true);

      global_body.append("div").classed("message", true);

      genome_selection_e.append("div").classed("genome-selection-modal", true);
      genome_selection_e.append("div").classed("genome-cart-modal", true);

      /*********
       * UPDATE *
       **********/

      var genome_selection_u = genome_selection_e.merge(genome_selection);

      var navbar_u = genome_selection_u.select(".navbar");
      navbar_u.select(".navbar-brand").html(function(d) {
        const count = d.in_selection_accordion.reduce(
          (acc, curr) => acc + curr.values.length,
          0
        );
        const class_badge = count === 0 ? "badge-danger" : "badge-light";
        return (
          d.title +
          '  <span class="badge ' +
          class_badge +
          '">' +
          count +
          "</span>"
        );
      });

      navbar_u
        .select(".grouping-select")
        .datum(function(d) {
          return d.biobs_grouping_btn_selector
            ? [d.biobs_grouping_btn_selector]
            : [];
        })
        .call(grouping_select_component);

      // add id to spinner row
      genome_selection_u
        .select(".genome-selection-body")
        .select(".spinner-container")
        .attr("id", function(d) {
          return d.id + "_spinner_container";
        });

      // update btn show modal
      genome_selection_u
        .select(".edit-genome-modal")
        .attr("id", function(d) {
          return d.id + "_show_modal";
        });

      // update card title
      genome_selection_u
        .select("div.card-header")
        .select(".genome-selection-card-title")
        .text(function(d) {
          return d.title.replace("-", " ");
        });

      /*******************
       * Display Message *
       *******************/

      const empty_msg = genome_selection_u
        .select(".message")
        .style("cursor", "pointer")
        .on("click", d => {
          d.eventHandlers.editSelector();
          $("#" + getModalId(d)).modal("toggle");
        })
        .selectAll(".empty-message")
        .data(d => {
          console.log(d);
          return d.in_selection_accordion.length === 0
            ? [
                "Click on the edit button to select " + d.biob_name + "(s)."
              ]
            : [];
        });

      const empty_msg_e = empty_msg
        .enter()
        .append("div")
        .classed("empty-message alert alert-secondary", true);

      empty_msg_e.append("p");
      empty_msg.exit().remove();

      const empty_msg_u = empty_msg.merge(empty_msg_e);

      empty_msg_u.select("p").text(d => d);

      /**
       * Genome selection accordion
       */

      var row_accordion = genome_selection_u
        .select(".row-accordion")
        .style("max-height", max_height + "px");

      var accordion = row_accordion
        .select(".genome-selection-accordion")
        .datum(function(d) {
          return d.in_selection_accordion ? d.in_selection_accordion : [];
        })
        .call(biob_list_accordion_compo);

      /**
       * Genome selection modal
       */
      genome_selection_u.select(".genome-selection-modal")
        .datum(function(d) {
          return [d];
        })
        .call(biob_list_multiple_selector, getModalId);

      genome_selection_u.select(".genome-cart-modal")
        .datum(function(d) {
          return [d];
        })
        .call(biob_list_multiple_selector_cart, getModalCartId);
    });
  }

  function getModalId(d) {
    return d.id + "_modal";
  }

  function getModalCartId(d) {
    return d.id + "Carts_modal";
  }

  function getNavbarContentId(d) {
    return d.id + "_navbar_content";
  }

  return exports;
}

