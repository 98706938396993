//

// import
import {notExistFilterException} from "../utils/exception";

// Code start here

/**
 * Get a Filter object by filter_id
 *
 * @export
 * @param {Filters} state
 * @param {string} filter_id
 * @returns {Filter}
 */
export function getFilterById(state, filter_id) {
  if (state.hasOwnProperty(filter_id)) {
    return state[filter_id];
  } else {
    throw new notExistFilterException(filter_id);
  }
}

/**
 * Get a string that describe the Filter object
 *
 * @param {Filters} state
 * @param {Filter} filter
 * @param {string} name
 * @returns {string}
 */
export const getStringifyFilterById = function(
  state,
  filter,
  name
) {
  if (filter.matching_type === "exact") {
    return filter.subtype + ' is "' + name + '"';
  } else {
    const verb = "contains";
    return filter.subtype
      ? filter.subtype + " " + verb + ' "' + name + '"'
      : filter.type + " " + verb + ' "' + name + '"';
  }
};
