// 

// import
import store, {store$} from "../../store";
import biobListSingleSelector from "../../d3-component/biob-list-single-selector";
// d3
import {select} from "d3-selection";

// Import reselect.
// Note that selectors here refers to redux selectors,
// not to biob lists selectors.
import {createSelector} from "reselect";

// Selector
import {getBiobListById, getBiobListByIdFactory, getBiobsById,} from "../../selector";
import {getSingleSelectorDataFactory} from "../../d3-data/biob-list";
import {createBiobList} from "../../action/biob-list";
import * as fromBiobList from "../../selector/biob-list";
import {getBiobList$, isFetching$, isfinishFetching$,} from "./";

export function singleNewSelector(
  biob_list_type,
  biobsToSuggestionEngine,
  html_class
) {
  singleSelector.isfinishFetching$ = isfinishFetching$;
  function singleSelector(
    biob_list_id,
    width,
    height,
    html_container
  ) {
    const component = biobListSingleSelector();
    const root_selection = select(html_container).select("#" + biob_list_id);
    const selector_container = root_selection.empty()
      ? select(html_container)
          .append("div")
          .classed("col-12 col-lg-" + width, true)
          .attr("id", biob_list_id)
      : root_selection.classed("col-12 col-lg-" + width, true);
    const getBiobListSelector = getBiobListByIdFactory(biob_list_id);
    const getListBiobs = createSelector(
      [getBiobListSelector],
      biob_list => fromBiobList.getListBiobs(biob_list)
    );
    const getSuggestionEngine = createSelector(
      [getListBiobs],
      (list_biobs) => {
        return biobsToSuggestionEngine(
          store.getState(),
          fromBiobList.listBiobsToArray(list_biobs),
          false,
          biob_list_id,
          false
        );
      }
    );

    /**
     * Function that draw the single selector
     *
     * @param {MicState} state
     * @returns
     */
    function draw(state) {
      const suggestion_engine = getSuggestionEngine(state);
      const biobs = getBiobsById(state, biob_list_id);
      const getData = getSingleSelectorDataFactory(
        suggestion_engine
      );
      const data = getData(state, biob_list_id);
      selector_container
        .datum([data])
        .call(component, width, height, "Strain_name", html_class);
      return data;
    }
    getBiobList$(biob_list_id).subscribe(function(state) {
      draw(state);
    });

    isFetching$(store$, biob_list_id).subscribe(function(data) {
      try {
        const state = store.getState();
        getBiobListById(state, biob_list_id);
        draw(store.getState());
      } catch (error) {
        console.log(error);
      }
    });

    isfinishFetching$(biob_list_id).subscribe(function(data) {
      try {
        const state = store.getState();
        getBiobListById(state, biob_list_id);
        draw(store.getState());
      } catch (error) {
        console.log(error);
      }
    });

    singleSelector.onBiobListChange = function(callback) {
      getBiobList$(biob_list_id).subscribe(callback);
    };
    store.dispatch(
      createBiobList(biob_list_type, biob_list_id, [1, 1], false, [])
    );
    draw(store.getState());
  }
  return singleSelector;
}
