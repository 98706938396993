import {symbol, symbolCircle, symbolCross} from "d3-shape";
import {select} from "d3-selection";
import "d3-transition";

export default function CircleCrossSymbol() {
    var node_symbol_class    = "node-symbol";
    var symbol_internal_size = 64;
    var symbol_leaf_size     = 20;
    var symbol_hover_size    = 120;
    
    function nodeSymbol(d) {
	return symbol()
	    .size(getSymbolSize(d))
	    .type(getSymbolType(d));
	
	// if (d.children || d._children) {
	//     symbol.size(symbol_internal_size);
	//     return (d._children) ? symbol.type(d3.symbolCross) : symbol.type(d3.symbolCircle);
	// }
	// else {
	//     return symbol.size(symbol_leaf_size).type(d3.symbolCircle);
	// }
    }

    function getSymbolType(d) {
	if (d._children) {
	    return symbolCross;
	}
	else {
	    return symbolCircle;
	}
    }

    function getSymbolSize(d) {
	if (d.children || d._children) {
	    return symbol_internal_size;
	}
	else {
	    return symbol_leaf_size;
	}
    }
    
    function nodeSymbolPath(d) {
	return nodeSymbol(d)();
    }
    
    
    exports.toggleHighlight = function(parent) {
	parent
	    .select("path." + node_symbol_class)
	    .attr("d", function(d) {
		var currentSize = getSymbolSize(d);
		return nodeSymbol(d).size(currentSize * 2.5)();
	    });
    };
    
    exports.toggleNormal = function (parent) {
	parent
	    .select("path."+node_symbol_class)
	    .attr("d", nodeSymbolPath);
    };
    
    function exports(_selection, user_node_symbol_class) {

	node_symbol_class = (user_node_symbol_class) ? user_node_symbol_class: node_symbol_class;
	
	_selection.each(function(_data) {
	    var container = select(this);
	    var node_symbol = container
		    .selectAll("path." + node_symbol_class)
		    .data(function(d){
			return [d];
		    });

	    var node_symbol_enter = node_symbol
		    .enter()
	    	    .append("path")
		    .classed(node_symbol_class, true);
	    
	    var node_symbol_update = node_symbol_enter
		    .merge(node_symbol);
	    
	    node_symbol_update
		.attr("d", nodeSymbolPath);
	    
	    node_symbol
		.exit()
		.remove();
	    
	    
	});
    }
    return exports;
}
