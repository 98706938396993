//
//import
import { select } from "d3-selection";
import "d3-transition";
import { nest } from "d3-collection";

export default function() {
  function headerId(d) {
    return d.key.toLowerCase() + "-header";
  }
  function collapseId(d) {
    return d.key.toLowerCase() + "-collapse";
  }

  function exports(selection) {
    selection.each(function(datum, i) {
      const container = select(this);

      const accordion = container.selectAll("div.card").data(datum);

      accordion.exit().remove();

      const accordion_e = accordion
        .enter()
        .append("div")
        .classed("card", true);

      accordion.order();

      const label_grp = accordion_e
        .append("div")
        .classed("card-header bg-geno-light", true)
        .style("cursor", "pointer")
        .style("padding-top", "0.1rem")
        .style("padding-bottom", "0.1rem")
        .on("click", function(d) {
          $(this.nextSibling).collapse("toggle");
        })
        .append("a")
        .classed("mb-0 text-dark", true)
        .style("font-weight", "bold")
        .attr("data-toggle", "collapse")
        .attr("aria-expanded", true);

      label_grp.append("span").classed("label", true);

      label_grp.append("span").classed("badge badge-secondary count", true);

      accordion_e
        .append("div")
        .classed("collapse show", true)
        .append("ul")
        .classed("list-group list-group-flush", true);

      const accordion_u = accordion_e.merge(accordion);

      const a_label = accordion_u
        .select(".card-header")
        .attr("id", headerId)
        .select("a");

      a_label
        .select("span.label")
        .attr("aria-controls", collapseId)
        .text((d) =>
          d.key === "" ? "Undefined" : d.key + "  "
        );

      a_label
        .select("span.count")
        .text((d) => " " + d.values.length);

      accordion_u
        .select(".collapse")
        .attr("id", collapseId)
        .attr("aria-labelledby", headerId);

      const list_group = accordion_u
        .select(".list-group")
        .selectAll(".list-group-item")
        .data((d) => d.values);

      const list_group_e = list_group
        .enter()
        .append("li")
        .style("padding-top", "0.1rem")
        .style("padding-bottom", "0.1rem")
        .style("padding-left", "3rem")
        .classed("list-group-item", true);

      list_group.exit().remove();

      const list_group_u = list_group.merge(list_group_e);

      list_group_u
        // .classed("bg-light", function(d, i){
        //     return i % 2 === 1;
        // })
        .text((d) => d.name);
    });
  }
  return exports;
}
