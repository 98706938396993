// 


// import
import {CACHE_TYPE, CACHE_REMOVE} from "../action/cache";

export const initialState = {};

export default function cache(
  state = initialState,
  action
) {
  switch (action.type) {
    case CACHE_TYPE:
      return cacheFromOid(action.data_to_cache, state);

    case CACHE_REMOVE:
      return action.ids.reduce((acc, id) => {
        const { [id]: deleted, ...rest } = acc;
        return rest;
      }, state);

    default:
      return state;
  }
}

function cacheFromOid(
  cacheToAdd,
  cache
) {
  return { ...cache, ...cacheToAdd };
}
