// 
// import
import {notExistOrganismException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

// code start here

export function getFavoriteGenomesById(state, id) {
  const cache = getFavoriteGenomesCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistOrganismException(id);
  }
}

export const getFavoriteGenomesCache = function(state) {
  return state.cache;
};

export function getMicFetchFavoriteGenomes(state) {
  return state.mic_fetch_state;
}

export function favoriteGenomesHasAll(state, id) {
  const fetchState = getMicFetchFavoriteGenomes(state);
  return fromMicFetch.hasAll(fetchState, id);
}

export function getListFavoriteGenomes(state) {
  const cache = getFavoriteGenomesCache(state);
  return Object.values(cache).reduce(function(genomeList, org) {
    const oid_str = org.O_id.toString();
    genomeList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return genomeList;
  }, {});
}

export function getStringifyFavoriteGenomesById(state, id) {
  return getFavoriteGenomesById(state, id).SorgName;
}
