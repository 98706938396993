//
//import
import {createNamedWrapperReducer} from "./utils";
import micFetch, * as fromMicFetch from "./mic-fetch";

//Code start here
export function distanceInit(name) {
  const initialState = {
    type: name,
    mic_fetch_state: fromMicFetch.initialState
  };

  const micFetchReducer = createNamedWrapperReducer(
    micFetch,
    name,
    initialState.mic_fetch_state
  );

  function setType(state = initialState.type) {
    return state;
  }

  return {
     type: setType,
     mic_fetch_state: micFetchReducer
  }
}
