// 
// import
import {of} from "rxjs";
import {distinctUntilChanged, filter, mergeMap} from "rxjs/operators";

import {store$} from "../../store";
import {getBiobListById, getMicFetchBiobList, isBiobListFetching} from "../../selector";

// D3 import
import {select} from "d3-selection";


/**
 *
 *
 * @export
 * @param {*} store$
 * @param {string} id
 * @returns
 */
export function isFetching$(store$, id) {
  return store$.pipe(
    mergeMap(state =>
      of(getMicFetchBiobList(state)).pipe(
        distinctUntilChanged(),
        filter(mic_fetch_biob_list => isBiobListFetching(state, id)),
        mergeMap(mic_fetch_biob_list =>
          of({
            message: "Loading information...",
            list: new Set()
          })
        )
      )
    )
  );
}

/**
 *
 *
 * @export
 * @param {string} id
 * @returns
 */
export function isfinishFetching$(id) {
  return store$.pipe(
    mergeMap(state => of(isBiobListFetching(state, id))),
    distinctUntilChanged(),
    filter(is_fetching => !is_fetching),
    mergeMap(is_fetching => {
      return of({
        message: "",
        list: new Set(),
        is_fetching,
        id
      });
    })
  );
}

/**
 *
 *
 * @export
 * @param {string} biob_list_id
 * @returns
 */
export function getBiobList$(biob_list_id) {
  return store$.pipe(
    // Filter empty biob list
    filter(state => {
      try {
        const biob_list = getBiobListById(state, biob_list_id);
        // Only record bioblist that contain biobs
        const is_biob_list_empty = Object.keys(biob_list.biobs).length === 0;
        return !is_biob_list_empty;
      } catch (error) {
        return false;
      }
    }),
    // The first state emit will always pass this distincUntilChanged.
    distinctUntilChanged((prev_state, state) => {
      const biob_list = getBiobListById(state, biob_list_id);
      try {
        const prev_biob_list = getBiobListById(prev_state, biob_list_id);
        return biob_list === prev_biob_list;
      } catch (error) {
        return false;
      }
    })
  );
}


/**
 *
 *
 * @export
 * @param {*} data
 * @param {*} html
 * @returns
 */
export function updateAlertMessage(data, html) {
  let alert_div = select(html).select("div.biob-list-fetching");

  if (alert_div.empty()) {
    alert_div = select(html)
      .append("div")
      .classed("justify-content-md-center alert-messages", true)
      .append("div")
      .classed("col", true)
      .append("div")
      .classed("alert alert-secondary biob-list-fetching", true)
      //.style("background-color", "#EAF6FF")
      .attr("role", "alert");
    alert_div
      .append("i")
      .classed("fa fa-spinner fa-spin fa-3x fa-fw align-middle", true);
    alert_div.append("span");
  }
  const alert_span = alert_div.select("span");
  return alert_span.text(data.message + [...data.list].join(", "));
}
