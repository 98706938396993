// 


// import
import { select } from "d3-selection";
import "d3-transition";
import { dispatch } from "d3-dispatch";
import resultContainer from "../d3-component/result-container.js";

const res_container_component = resultContainer();
const not_mapped_events = new Set(["remove-species-tree"]);
const events = [
  "show-cluster-tree",
  "remove-species-tree"
];
const getNavTabId = (d) => d.data.id + "_tab";
const getIdTab = (d) => d.data.id + "_header";
const getIdTabContent = (d) =>
  d.data.id + "_tab_content";
export default function() {
  exports.dispatcher = dispatch.apply(null, events);


  function exports(selection, width, container_id) {
    selection.each(function(_data, i) {
      const container = select(this);
      const close_tab_handler = function() {
        let a_item = this.nextElementSibling;
        let id_array = a_item.id.split("-");
        id_array.pop();
        let id = id_array.join("-");
        select(a_item.parentElement).remove();
        select("#nav-tabContent")
          .select("#" + id)
          .remove();
        $("#nav-species-tree-tab").tab("show");
      };
      /**
       * tab_header
       */
      //create the ul container (tab header)
      let tab_header_container = container.select("ul.nav");

      if (tab_header_container.empty()) {
        tab_header_container = container
          .append("ul")
          .classed("nav nav-tabs", true)
          .attr("role", "tablist")
          .attr("id", container_id);
      }
      const tab_header = tab_header_container
        .selectAll("li.nav-item")
        .data(_data, getIdTab);

      const tab_header_e = tab_header
        .enter()
        .append("li")
        .attr("id", getIdTab)
        .classed("nav-item", true);

      tab_header_e
        .filter(d => d.data.id !== "species_tree")
        .append("button")
        .attr("type", "button")
        .attr("class", "close")
        .attr("aria-label", "Close")
        .on("click", d => {
          d.eventHandlers.removeSpeciesTree(d.data.id);
          //exports.dispatcher.call("remove-species-tree", this, d, d.data.id);
        })
        .append("span")
        .attr("aria-hidden", "true")
        .style("margin-right", "3px")
        .style("font-size", "1.1rem")
        .html("&times;");

      tab_header_e
        .append("a")
        .classed("nav-link", true)
        .attr("data-toggle", "tab")
        .attr("role", "tab");

      tab_header.exit().remove();
      const tab_header_u = tab_header_e.merge(tab_header);
      tab_header_u
        .select("a")
        .classed("active", d => d.selected)
        .attr("aria-controls", getIdTabContent)
        .attr("aria-selected", d => d.selected)
        .attr("id", getNavTabId)
        .attr(
          "href",
          (d) => "#" + getIdTabContent(d)
        )
        .html(
          (d) =>
            d.data.title +
            '  <span class="badge badge-dark" style="vertical-align: text-bottom">' +
            d.data.oids.length +
            "</span>"
        );

      /**
       * TAB CONTENT
       */
      // create div container (tab content)
      let tab_content_container = container.select("div.tab-content");
      if (tab_content_container.empty()) {
        tab_content_container = container
          .append("div")
          .classed("tab-content", true)
          .attr("id", container_id + "-content");
      }
      const tab_content = tab_content_container
        .selectAll("div.tab-pane")
        .data(_data);

      const tab_content_e = tab_content
        .enter()
        .append("div")
        .attr("role", "tabpanel")
        .attr("aria-labelledby", getNavTabId)
        .classed("tab-pane fade", true);

      tab_content.exit().remove();

      const tab_content_u = tab_content
        .merge(tab_content_e)
        .classed("show active", d => d.selected)
        .attr("id", getIdTabContent);

      tab_content_u
        .datum(d => [d.data])
        .call(res_container_component, width, "genus", 500);

      // Map the events and forward them
      events
        .filter(ev_name => !not_mapped_events.has(ev_name))
        .forEach(ev_name =>
          res_container_component.dispatcher.on(ev_name, function() {
            exports.dispatcher.apply(ev_name, this, arguments);
          })
        );

      // res_container_component.dispatcher.on("show-cluster-tree", function(
      //   group,
      //   type
      // ) {
      //   console.log("");
      //   exports.dispatcher.call("show-cluster-tree", this, group, type);
      // });
    });
  }
  return exports;
}
