//


// import
// Selector
import * as fromOrganism from "./organism";
import * as fromFavoriteGenomes from "./favorite-genomes";
import * as fromPublicGenome from "./public-genome";
import * as fromTaxonomy from "./taxonomy";
import * as fromMicgc from "./micgc";
import * as fromMigcByOcid from "./micgc-by-ocid";
import * as fromMetadata from "./metadata";
import * as fromGenomeCarts from "./genome-carts";
import * as fromSequenceCarts from "./sequence-carts";
import * as fromSequence from "./sequence";
import * as fromBiobList from "./biob-list";
import * as fromSuggestionEngine from "./suggestion-engine";
import * as fromFilter from "./filter";
import * as fromNjSpeciesTree from "./nj-species-tree";

// Action
import {utils} from "phylotree-utils";

// Import reselect.
// Note that selectors here refers to redux selectors,
// not to biob lists selectors.
import {createSelector} from "reselect";

// Code start here

/**
 * ORGANISM SELECTOR
 */
const PhylotreeUtils = utils();
export function getOrganism(state) {
  return state.pkgdb_organism;
}

export function getOrganismById(state, id) {
  return fromOrganism.getOrganismById(getOrganism(state), id);
}

export const isFetchingOrganism = (state, id) =>
  fromOrganism.isFetchingOrganism(getOrganism(state), id);

export const getOrganismCache = function(state) {
  return fromOrganism.getOrganismCache(getOrganism(state));
};

export const organismHasAll = function(state, id) {
  return fromOrganism.organismHasAll(getOrganism(state), id);
};

export const getListOrganism = function(state) {
  return fromOrganism.getListOrganism(getOrganism(state));
};

export const getStringifyOrganismById = function(
  state,
  id
) {
  return fromOrganism.getStringifyOrganismById(getOrganism(state), id);
};

/************************
 * MY ORGANISM SELECTOR *
 ************************/

export function getMyOrganism(state) {
  return state.pkgdb_my_organism;
}

export function getMyOrganismById(state, id) {
  return fromOrganism.getOrganismById(getMyOrganism(state), id);
}

export const myOrganismHasAll = function(state, id) {
  return fromOrganism.organismHasAll(getMyOrganism(state), id);
};

export const getListMyOrganism = function(state) {
  return fromOrganism.getListOrganism(getMyOrganism(state));
};

export const getStringifyMyOrganismById = function(
    state,
    id
) {
  return fromOrganism.getStringifyMyOrganismById(getMyOrganism(state), id);
};

/*******************
 * REFSEQ ORGANISM *
 *******************/

export function getRefseqOrganism(state) {
  return state.refseq_organism;
}

export function getRefseqOrganismById(
  state,
  id
) {
  return fromOrganism.getOrganismById(getRefseqOrganism(state), id);
  //return fromRefseqOrganism.getRefseqOrganismById(getRefseqOrganism(state), id);
}
export function getRefseqOrganismCache(state) {
  return fromOrganism.getOrganismCache(getRefseqOrganism(state));
  //return fromRefseqOrganism.getRefseqOrganismCache(getRefseqOrganism(state));
}

export function refseqOrganismHasAll(state, id) {
  return fromOrganism.organismHasAll(getRefseqOrganism(state), id);
  //return fromRefseqOrganism.refseqOrganismHasAll(getRefseqOrganism(state), id);
}

export const getListRefseqOrganism = function(state) {
  return fromOrganism.getListOrganism(getRefseqOrganism(state));
  //return fromRefseqOrganism.getListRefseqOrganism(getRefseqOrganism(state));
};

export function getStringifyRefseqOrganismById(state, id) {
  return fromOrganism.getStringifyOrganismById(getRefseqOrganism(state), id);

  // return fromRefseqOrganism.getStringifyRefseqOrganismById(
  //   getRefseqOrganism(state),
  //   id
  // );
}

/*******************
 * REFSEQ SEQUENCE *
 *******************/

export function getRefseqSequence(state) {
  return state.refseq_sequence;
}
export function getRefseqSequenceById(state, id) {
  return fromSequence.getSequenceById(getRefseqSequence(state), id);
}
export function getRefseqSequenceCache(state) {
  return fromSequence.getSequenceCache(getRefseqSequence(state));
}

export function refseqSequenceHasAll(state, id) {
  return fromSequence.sequenceHasAll(getRefseqSequence(state), id);
}

export function getListRefseqSequence(state) {
  return fromSequence.getListSequence(getRefseqSequence(state));
}
export const getOidFromSidRefseq = function(state, sid) {
  return fromSequence.getOidFromSid(getRefseqSequence(state), sid);
};
export function getStringifyRefseqSequenceById(state, id) {
  return fromSequence.getStringifySequenceById(
    getRefseqSequence(state),
    id,
    getStringifyRefseqOrganismById(state, getOidFromSidRefseq(state, id))
  );
}

/**
 * PUBLIC GENOME
 */
/**
 *
 * @export
 * @param {MicState} state
 * @returns
 */
export function getPublicGenome(state) {
  return state.public_genome;
}

export function getListPublicGenome(state) {
  return fromPublicGenome.getListPublicGenome(getPublicGenome(state));
}

export function getPublicGenomeCache(state) {
  return fromPublicGenome.getPublicGenomeCache(getPublicGenome(state));
}

export function getPublicGenomeById(state, id) {
  return fromPublicGenome.getPublicGenomeById(getPublicGenome(state), id);
}

export function hasAllPublicGenome(state, id) {
  return fromPublicGenome.hasAllPublicGenome(getPublicGenome(state), id);
}

export function getStringifyPublicGenomeById(state, id) {
  return fromPublicGenome.getStringifyPublicGenomeById(
    getPublicGenome(state),
    id
  );
}

/*******************
 * FAVORITE GENOME *
 ********************/

export function getFavoriteGenomes(state) {
  return state.favorite_genomes;
}

export function getFavoriteGenomesById(state, id) {
  return fromFavoriteGenomes.getFavoriteGenomesById(
    getFavoriteGenomes(state),
    id
  );
}
export function getFavoriteGenomesCache(state) {
  return fromFavoriteGenomes.getFavoriteGenomesCache(
    getFavoriteGenomes(state)
  );
}

export function favoriteGenomesHasAll(state, id) {
  return fromFavoriteGenomes.favoriteGenomesHasAll(
    getFavoriteGenomes(state),
    id
  );
}

export function getListFavoriteGenomes(state) {
  return fromFavoriteGenomes.getListFavoriteGenomes(
    getFavoriteGenomes(state)
  );
}

export function getStringifyFavoriteGenomesById(state, id) {
  return fromFavoriteGenomes.getStringifyFavoriteGenomesById(
    getFavoriteGenomes(state),
    id
  );
}

/**
 * TAXONOMY SELECTOR
 */

export const getTaxonomy = function(state) {
  return state.pkgdb_taxonomy;
};
export const isFetchingTaxonomy = (state, id) =>
  fromTaxonomy.isFetchingTaxonomy(getTaxonomy(state), id);

export const getTaxonomyCache = function(state) {
  return fromTaxonomy.getTaxonomyCache(getTaxonomy(state));
};

export const getListTaxonomy = function(state) {
  return fromTaxonomy.getListTaxonomy(getTaxonomy(state));
};

export const taxonomyHasAll = function(state, id) {
  return fromTaxonomy.taxonomyHasAll(getTaxonomy(state), id);
};

export const getRankValue = function(
  state,
  id,
  rank
) {
  return fromTaxonomy.getRankValue(getTaxonomy(state), id, rank);
};

export const getTaxonomyById = function(state, oid) {
  return fromTaxonomy.getTaxonomyById(getTaxonomy(state), oid);
};

export const getStringifyTaxonomyById = function(state, oid) {
  return fromTaxonomy.getStringifyTaxonomyById(
    getTaxonomy(state),
    oid,
    getStringifyOrganismById(state, oid)
  );
};

/*************************
REFSEQ TAXONOMY SELECTOR *
**************************/

export const getRefseqTaxonomy = function(state) {
  return state.refseq_taxonomy;
};
export const isFetchingRefseqTaxonomy = (state, id) =>
  fromTaxonomy.isFetchingTaxonomy(getRefseqTaxonomy(state), id);

export const getRefseqTaxonomyCache = function(state) {
  return fromTaxonomy.getTaxonomyCache(getRefseqTaxonomy(state));
};

export const getRefseqListTaxonomy = function(state) {
  return fromTaxonomy.getListTaxonomy(getRefseqTaxonomy(state));
};

export const refseqTaxonomyHasAll = function(
  state,
  id
) {
  return fromTaxonomy.taxonomyHasAll(getRefseqTaxonomy(state), id);
};

export const getRefseqRankValue = function(
  state,
  id,
  rank
) {
  return fromTaxonomy.getRankValue(getRefseqTaxonomy(state), id, rank);
};

export const getRefseqTaxonomyById = function(state, oid) {
  return fromTaxonomy.getTaxonomyById(getRefseqTaxonomy(state), oid);
};

export const getRefseqStringifyTaxonomyById = function(
  state,
  oid
) {
  return fromTaxonomy.getStringifyTaxonomyById(
    getRefseqTaxonomy(state),
    oid,
    getStringifyRefseqOrganismById(state, oid)
  );
};

// Micgc Selector
export const getMicgc = function(state) {
  return state.micgc;
};

export const getMicgcCache = function(state) {
  return fromMicgc.getMicgcCache(getMicgc(state));
};

export const getListMicgc = function(state) {
  return fromMicgc.getListMicgc(getMicgc(state));
};
export const getMicgcById = function(state, id) {
  return fromMicgc.getMicgcById(getMicgc(state), id);
};

export const micgcHasAll = function(state, id) {
  return fromMicgc.micgcHasAll(getMicgc(state), id);
};
export const getStringifyMicgcById = function(
  state,
  oid
) {
  return fromMicgc.getStringifyMicgcById(
    getMicgc(state),
    oid,
    getStringifyOrganismById(state, oid)
  );
};

export const getOcidsfromOids = function(state, oids) {
  return fromMicgc.getOcidsfromOids(getMicgc(state), oids);
};

/******************
 * Micgc by ocid
 ****************/
export const getMicgcByOcid = function(state) {
  return state.micgc_by_ocid;
};
export const getMicgcByOcidCache = function(state) {
  return fromMigcByOcid.getMicgcByOcidCache(getMicgcByOcid(state));
};
export const getMicgcByOcidById = function(state, id) {
  return fromMigcByOcid.getMicgcByOcidById(getMicgcByOcid(state), id);
};

export const micgcByOcidHasAll = (state, id) => {
  return fromMigcByOcid.micgcByOcidHasAll(getMicgc(state), id);
}
/*********************
 * Metadata SELECTOR *
 *********************/

export const getStrainMetadata = function(state) {
  return state.strain_metadata;
};

export const getStrainMetadataCache = function(state) {
  return fromMetadata.getMetadataCache(getStrainMetadata(state));
};

export const getStrainMetadataById = function(state, id) {
  return fromMetadata.getMetadataById(getStrainMetadata(state), id);
};

export const strainMetadataHasAll = function(state, id) {
  return fromMetadata.metadataHasAll(getStrainMetadata(state), id);
};

export const getSpeciesMetadata = function(state) {
  return state.species_metadata;
};

export const getSpeciesMetadataCache = function(state) {
  return fromMetadata.getMetadataCache(getSpeciesMetadata(state));
};

export const getSpeciesMetadataById = function(state, id) {
  return fromMetadata.getMetadataById(getSpeciesMetadata(state), id);
};

export const speciesMetadataHasAll = function(state, id) {
  return fromMetadata.metadataHasAll(getSpeciesMetadata(state), id);
};

/*********************
 * Genome Carts
 *********************/

export const getGenomeCarts = function(state) {
  return state.genome_carts;
};

export const getGenomeCartsCache = function(state) {
  return fromGenomeCarts.getGenomeCartsCache(getGenomeCarts(state));
};

export const getGenomeCartsList = function(state) {
  return fromGenomeCarts.getGenomeCartsList(getGenomeCarts(state));
};

export const getBiobFromGenomeCartById = function(state, cartName) {
  return fromGenomeCarts.getBiobFromGenomeCartById(getGenomeCarts(state), cartName);
};

export const getGenomeCartsById = function(state, id) {
  return fromGenomeCarts.getGenomeCartsById(getGenomeCarts(state), id);
};

export const genomeCartsHasAll = function(state, id) {
  return fromGenomeCarts.genomeCartsHasAll(getGenomeCarts(state), id);
};

/*********************
 * Sequence Carts
 *********************/

export const getSequenceCarts = function(state) {
  return state.sequence_carts;
};

export const getSequenceCartsCache = function(state) {
  return fromSequenceCarts.getSequenceCartsCache(getSequenceCarts(state));
};

export const getSequenceCartsList = function(state) {
  return fromSequenceCarts.getSequenceCartsList(getSequenceCarts(state));
};

export const getBiobFromSequenceCartById = function(state, cartName) {
  return fromSequenceCarts.getBiobFromSequenceCartById(getSequenceCarts(state), cartName);
};

export const getSequenceCartsById = function(state, id) {
  return fromSequenceCarts.getSequenceCartsById(getSequenceCarts(state), id);
};

export const sequenceCartsHasAll = function(state, id) {
  return fromSequenceCarts.sequenceCartsHasAll(getSequenceCarts(state), id);
};

/*********************
 * Sequence Selector
 *********************/

export const getSequence = function(state) {
  return state.pkgdb_sequence;
};

export const getSequenceById = function(state, id) {
  return fromSequence.getSequenceById(getSequence(state), id);
};

export const getSequenceCache = function(state) {
  return fromSequence.getSequenceCache(getSequence(state));
};
export const sequenceHasAll = function(state, id) {
  return fromSequence.sequenceHasAll(getSequence(state), id);
};
export const getListSequence = function(state) {
  return fromSequence.getListSequence(getSequence(state));
};

export const getOidFromSid = function(state, sid) {
  return fromSequence.getOidFromSid(getSequence(state), sid);
};

export const getStringifySequenceById = function(state, id) {
  let orgString = "";
  try {
    orgString = getStringifyOrganismById(state, getOidFromSid(state, id));
  } catch (e) {
    orgString = "Unset Organism";
  }

  return fromSequence.getStringifySequenceById(
    getSequence(state),
    id,
    orgString
  );
};

/******************
 * Nj Species Trees
 *******************/
export const getNjSpeciesTree = function(state) {
  return state.nj_species_tree;
};

export const getNjSpeciesTreeCache = function(
  state
) {
  return fromNjSpeciesTree.getNjSpeciesTreeCache(getNjSpeciesTree(state));
};

export const getNjSpeciesTreeById = function(state, id) {
  return fromNjSpeciesTree.getNjSpeciesTreeById(getNjSpeciesTree(state), id);
};

export const getNjTreeDistancesById = (state, id) =>
  fromNjSpeciesTree.getNjTreeDistancesById(getNjSpeciesTree(state), id);

export const getMicFetchSpeciesTree = (state) => {
  const nj_species_tree = getNjSpeciesTree(state);
  return fromNjSpeciesTree.getMicFetchSpeciesTree(nj_species_tree);
};

// export function isSpeciesTreeFetching(state: MicState, id: string) {
//   return fromNjSpeciesTree.isSpeciesTreeFetching(
//     getMicFetchSpeciesTree(state),
//     id
//   );
// }

/*************
 * Biob lists *
 **************/

export function getFetchBiobList(state) {
  return state.fetch_biob_lists;
}

export const getBiobLists = function(state) {
  return fromBiobList.getBiobLists(getFetchBiobList(state));
};

export function getListBiobs(state, biob_list_id) {
  const biobList = getBiobListById(state, biob_list_id);
  return fromBiobList.getListBiobs(biobList);
}

export const getMicFetchBiobList = function(state) {
  return fromBiobList.getMicFetchBiobList(getFetchBiobList(state));
};

export const getBiobListsHistory = (state) =>
  fromBiobList.getBiobListsHistory(getFetchBiobList(state));

export const isBiobListFetching = (state, id) =>
  fromBiobList.isBiobListFetching(getFetchBiobList(state), id);

/**
 * Getter for a biob list
 *
 * @export
 * @param {MicState} state The application state
 * @param {string} biob_list_id Biob list identifier
 * @returns {BiobList} Biob list state
 */
export function getBiobListById(state, biob_list_id) {
  return fromBiobList.getBiobListById(getBiobLists(state), biob_list_id);
}

export const getBiobsById = function(
  state,
  biob_list_id
) {
  return fromBiobList.getBiobsById(getBiobLists(state), biob_list_id);
};

export function getBiobListTypeById(state, id) {
  return fromBiobList.getBiobListTypeById(getBiobLists(state), id);
}

export function getInSelectionBiobs(state, biob_list_id) {
  return fromBiobList.getInSelectionBiobs(getBiobLists(state), biob_list_id);
}

export function getInSelectionFilterIds(state, biob_list_id) {
  return fromBiobList.getInSelectionFilterIds(
    getBiobLists(state),
    biob_list_id
  );
}

export function getNotInSelectionBiobs(state, biob_list_id) {
  return fromBiobList.getNotInSelectionBiobs(getBiobLists(state), biob_list_id);
}

export function getNotInSelectionFilterIds(
  state,
  biob_list_id
) {
  return fromBiobList.getNotInSelectionFilterIds(
    getBiobLists(state),
    biob_list_id
  );
}

export function getInSelectionGroupingById(
  state,
  biob_list_id
) {
  return fromBiobList.getInSelectionGroupingById(
    getBiobLists(state),
    biob_list_id
  );
}

export function getNotInSelectionGroupingById(
  state,
  biob_list_id
) {
  return fromBiobList.getNotInSelectionGroupingById(
    getBiobLists(state),
    biob_list_id
  );
}
export function getIsSelectedAttrById(
  state,
  id_list,
  id_biob
) {
  return fromBiobList.getIsSelectedAttrById(
    getBiobLists(state),
    id_list,
    id_biob
  );
}

export function getGroupingByIdByStatus(
  state,
  biob,
  biob_list_id,
  status,
  bool
) {
  return fromBiobList.getGroupingByIdByStatus(
    getBiobLists(state),
    biob,
    biob_list_id,
    status,
    bool
  );
}

export function getBiobListUpToDateById(state, biob_list_id) {
  return fromBiobList.getBiobListUpToDateById(
    getBiobLists(state),
    biob_list_id
  );
}

export function hasBiobListById(state, biob_list_id) {
  return fromBiobList.hasBiobListById(getBiobLists(state), biob_list_id);
}

export function getFiltredBiobsByIdHideAll(
  state,
  suggestion_engine,
  biob_list_id,
  list_biob,
  filter_ids
) {
  // Get corresponding filters
  const filters = filter_ids.map(filter_id => getFilterById(state, filter_id));
  if (filters.length === 0) {
    return [
      {
        filter_id: "none",
        size: 0,
        biobs: [],
      },
    ];
  } else {
    return fromSuggestionEngine.filterListBiob(
      state,
      suggestion_engine,
      biob_list_id,
      list_biob,
      filters
    );
  }
}

export function getFiltredBiobsById(
  state,
  suggestion_engine,
  biob_list_id,
  list_biob,
  filter_ids
) {
  // Get corresponding filters
  const filters = filter_ids.map(filter_id => getFilterById(state, filter_id));
  if (filters.length === 0) {
    return [
      {
        filter_id: "none",
        size: list_biob.length,
        biobs: list_biob,
      },
    ];
  } else {
    return fromSuggestionEngine.filterListBiob(
      state,
      suggestion_engine,
      biob_list_id,
      list_biob,
      filters
    );
  }
  // return fromSuggestionEngine.filterListBiob(
  //   state,
  //   suggestion_engine,
  //   biob_list_id,
  //   list_biob,
  //   filters
  // );
}
export function searchMatchingBiobsById(
  state,
  suggestion_engine,
  biob_list_id,
  list_biob,
  filter_ids
) {
  const filters = filter_ids.map(filter_id => getFilterById(state, filter_id));
  return fromSuggestionEngine.searchListBiob(
    state,
    suggestion_engine,
    biob_list_id,
    list_biob,
    filters
  );
}

export function getBiobByBiobId(
  state,
  biob_list_id,
  biob_id
) {
  return fromBiobList.getBiobByBiobId(
    getBiobLists(state),
    biob_list_id,
    biob_id
  );
}

export const getBiobName = (
  state,
  biob_list_id
) =>
  fromBiobList.getBiobName(getBiobLists(state), biob_list_id);

export const getBiobListRange = (
  state,
  biob_list_id
) =>
  fromBiobList.getBiobListRange(getBiobLists(state), biob_list_id);

export function getRangeStatusById(
  state,
  biob_list_id
) {
  return fromBiobList.getRangeStatusById(getBiobLists(state), biob_list_id);
}

export const getFilterCriteriaById = (
  state,
  biob_list_id,
  biob_status,
  biob_status_value
) =>
  fromBiobList.getFilterCriteriaById(
    getBiobLists(state),
    biob_list_id,
    biob_status,
    biob_status_value
  );


// export function biobsToSuggestionEngine(state: MicState, biobs: ListBiob[]) {
//   return fromSuggestionEngine.getGenomeRessourceSuggestionEngine(state, biobs);
// }

/******************
 * FIlters selectors
 ***********************/
export function getFilters(state) {
  return state.filter;
}

export function getFilterById(state, filter_id) {
  return fromFilter.getFilterById(getFilters(state), filter_id);
}

export function getStringifyFilterId(state, filter_id) {
  const filter = getFilterById(state, filter_id);
  return fromFilter.getStringifyFilterById(
    getFilters(state),
    filter,
    filter.name
  );
}

export function getFilterDataById(
  state,
  filter_id,
  biob_select_id,
  i,
  size
) {
  const name = getStringifyFilterId(state, filter_id);
  const filter = getFilterById(state, filter_id);
  return {
    id: biob_select_id + "_" + filter.id,
    filter_id: filter.id,
    index: i,
    name,
    size,
  };
}

/*************************
 * bio-viz-data selector
 * This is the dirty part !!!
 *************************/

// memoized selector factory
export function getBiobListByIdFactory(biob_list_id) {
  return createSelector(
    getBiobLists,
    (biob_lists) => {
      return fromBiobList.getBiobListById(biob_lists, biob_list_id);
    }
  );
}

export function getFilterIdsMemoizedSelectorFactory(
  biob_list_id,
  status_type,
  status_type_key,
  getListBiobs
) {
  return createSelector(
    [getListBiobs],
    list_biobs =>
      fromBiobList.getBiobStatusValueByType(
        list_biobs,
        status_type,
        status_type_key
      ).filters
  );
}

