// 
//import
import {combineReducers} from "redux";
import {
    species_metadata,
    strain_metadata,
    public_genome,
    refseq_taxonomy,
    refseq_sequence,
    refseq_organism,
    pkgdb_taxonomy,
    pkgdb_sequence,
    pkgdb_organism,
    pkgdb_my_organism,
    favorite_genomes,
    distance,
    micgc_by_ocid,
    micgc,
    genome_carts,
    sequence_carts
} from "./reducer-declaration";
import fetchBiobList from "./biob-list";
import filter from "./filter";
import njSpeciesTree from "./nj-species-tree";

const rootReducer = combineReducers({
  pkgdb_organism,
  pkgdb_my_organism,
  favorite_genomes,
  refseq_organism,
  refseq_sequence,
  refseq_taxonomy,
  public_genome,
  pkgdb_taxonomy,
  micgc,
  species_metadata,
  strain_metadata,
  micgc_by_ocid,
  pkgdb_sequence,
  fetch_biob_lists: fetchBiobList,
  filter,
  nj_species_tree: njSpeciesTree,
  distance,
  genome_carts,
  sequence_carts
});
export default rootReducer;
