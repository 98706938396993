// 


// import
import {CACHE_TYPE} from "../action/cache";

import {genome_carts_name_sort} from "../reducer/genome-carts-name";

export const initialState = {};

export default function cache(
    state = initialState,
    action
) {
    switch (action.type) {
        case CACHE_TYPE:
            if (Object.keys(action.data_to_cache).length > 0) {
                let sequence_carts_prop = [];
                for (let O_id in action.data_to_cache) {
                    for (let gene_cart_name of action.data_to_cache[O_id]) {
                        let index = sequence_carts_prop.findIndex((item) => item.name === gene_cart_name)
                        if (index === -1) {
                            sequence_carts_prop.push({
                                name: gene_cart_name,
                                harmonized_name: gene_cart_name,
                                content: [O_id],
                            })
                        } else {
                            sequence_carts_prop[index].content.push(O_id);
                        }
                    }
                }
                //reorders the array
                sequence_carts_prop.sort((a, b) => genome_carts_name_sort(a.name, b.name));
                return sequence_carts_prop;
            } else {
                return state;
            }

        default:
            return state;
    }
}
