//
//import
import {notExistRankException, notExistTaxonomyException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";
import {getMicFetch} from "./fetch-cache";

// Selectors
export function getTaxonomyById(
  state,
  id
) {
  const cache = getTaxonomyCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    throw new notExistTaxonomyException(id);
  }
}

export function getRankValue(
  state,
  id,
  rank
) {
  const cache = getTaxonomyCache(state);
  if (cache.hasOwnProperty(id)) {
    if (cache[id].hasOwnProperty(rank)) {
      return cache[id][rank].name_txt;
    } else {
      throw new notExistRankException(id, rank);
    }
  } else {
    throw new notExistTaxonomyException(id);
  }
}
export function getTaxonomyCache(state) {
  return state.cache;
}

export function getListTaxonomy(state) {
  const cache = getTaxonomyCache(state);
  return Object.keys(cache).reduce(function(taxonomyList, oid) {
    taxonomyList[oid] = {
      id: oid,
      oid: oid,
      selected_attr: false,
      in_selection: false,
      is_filtered: false
    };
    return taxonomyList;
  }, {});
}

export function taxonomyHasAll(state, id) {
  return fromMicFetch.hasAll(getMicFetch(state), id);
}

export function getStringifyTaxonomyById(
  state,
  id,
  orga_str
) {
  return stringifyTaxonomyGroup(state, id) + "; " + orga_str;
}

function stringifyTaxonomyGroup(state, id) {
  const group = getTaxonomyById(state, id);
  return Object.values(group)
    .sort((a, b) => a.depth - b.depth)
    .map((taxo) => taxo.name_txt)
    .join("; ");
}
export const getMicFetchTaxonomy = (state) =>
  state.mic_fetch_state;

export const isFetchingTaxonomy = (state, id) =>
  fromMicFetch.isFetching(getMicFetchTaxonomy(state), id);
