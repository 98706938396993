//
import {notExistSequenceCartException} from "../utils/exception";
import * as fromMicFetch from "./mic-fetch";

// code start here
export function getSequenceCartsCache(state) {
  return state.cache;
}

export function getSequenceCartsList(state) {
  return state.carts_list;
}

export function getBiobFromSequenceCartById(state, cartName) {
  const item = state.carts_list.find(item => item.name === cartName);
  return item.content;
}
/*
export function getListSequenceCarts(state: SequenceCartsState): ListBiobs {
  const cache = getSequenceCartsCache(state);
  return Object.values(cache).reduce(function(
    sequenceCartsList: ListBiobs,
    sequenceCarts: any
  ) {
    const oid_str: string = sequenceCarts.O_id.toString();
    sequenceCartsList[oid_str] = {
      id: oid_str,
      oid: oid_str,
      is_filtered: false,
      selected_attr: false,
      in_selection: false
    };
    return sequenceCartsList;
  },
  {});
}
*/
export function getSequenceCartsById(state, id) {
  const cache = getSequenceCartsCache(state);
  if (cache.hasOwnProperty(id)) {
    return cache[id];
  } else {
    // throw new notExistSequenceCartException(id);
  }
}

export function getStringifySequenceCartsById(
  state,
  id,
  org_string
) {
  return stringifySequenceCarts(state, id) + "; " + org_string;
}

export function stringifySequenceCarts(sequenceCarts, org_string) {
  const group = getSequenceCartsById(sequenceCarts, org_string);
  return Object.values(group)
      .sort((a, b) => a.depth - b.depth)
      .map((meta) => meta.name_txt)
      .join("; ");
}

export function sequenceCartsHasAll(state, id) {
  const fetchState = state.mic_fetch_state;
  return fromMicFetch.hasAll(fetchState, id);
}
